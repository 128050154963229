import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";
import { Typography, Spin, Divider, Layout, Row, Col, Button } from "antd";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const BlogDetail = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        // Prepare the request body with the slug parameter
        const body = JSON.stringify({ slug: slug });

        // Make the API request with the body containing the slug
        const response = await fetch(
          "https://qwwxaqw89a.execute-api.us-east-1.amazonaws.com/Test2",
          {
            method: "POST", // Use POST method to pass the data in the body
            headers: {
              "Content-Type": "application/json",
            },
            body: body, // Send the body
          }
        );

        // Parse the response data directly
        const data = await response.json();
        const blogData = JSON.parse(data.body); // Parse the stringified JSON response

        if (blogData && blogData.length > 0) {
          setBlog(blogData[0]); // Set the first blog from the array
        }
      } catch (error) {
        console.error("Error fetching blog details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetail();
  }, [slug]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!blog) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Title level={2} style={{ color: "#888" }}>
          Blog not found
        </Title>
        <Link to="/blogs" style={{ fontSize: "16px", color: "#1890ff" }}>
          ← Back to Blogs
        </Link>
      </div>
    );
  }

  // Concatenate the tags for SEO description
  const metaDescription =
    blog.tags.join(", ") ||
    blog.excerpt.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 200) + "...";

  return (
    <div
      style={{
        padding: "5%",
        backgroundColor: "#fff",
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        borderRadius: "10px",
      }}
    >
      <Helmet>
        <title>{blog.title.replace(/&nbsp;/g, " ")}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={blog.tags.join(", ")} />
        <meta
          property="og:title"
          content={blog.title.replace(/&nbsp;/g, " ")}
        />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content={
            blog.jetpack_featured_media_url ||
            "https://www.stockbuzz.ai/Stockbuzz.png"
          }
        />
        <meta property="og:url" content={blog.link} />
        <meta property="og:type" content="article" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: blog.title.replace(/&nbsp;/g, " "),
            description: metaDescription,
            image:
              blog.jetpack_featured_media_url ||
              "https://www.stockbuzz.ai/Stockbuzz.png",
            url: blog.link,
            author: {
              "@type": "Person",
              name: "Stockbuzz.ai",
            },
            publisher: {
              "@type": "Organization",
              name: "Stockbuzz.ai",
              logo: {
                "@type": "ImageObject",
                url: "https://www.stockbuzz.ai/Stockbuzz.png",
              },
            },
            datePublished: blog.date,
          })}
        </script>
      </Helmet>

      <Title level={1} style={{ textAlign: "center", color: "#333" }}>
        <span dangerouslySetInnerHTML={{ __html: blog?.title }} />
      </Title>

      <Divider />

      <Paragraph
        style={{
          fontSize: "18px",
          lineHeight: "1.8",
          color: "#444",
          textAlign: "justify",
          marginBottom: "30px",
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: blog.content }} />
        <style>
          {`
                  .wp-block-image img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    margin: 20px auto;
                    border-radius: 10px;
                  }
                `}
        </style>
      </Paragraph>

      <Divider />

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Link
          to="/blogs"
          style={{
            fontSize: "16px",
            color: "#fff",
            backgroundColor: "#1890ff",
            padding: "10px 20px",
            borderRadius: "5px",
            textDecoration: "none",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          ← Back to Blogs
        </Link>
      </div>
    </div>
  );
};

export default BlogDetail;
