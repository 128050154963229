import React, { useState, useEffect } from "react";
import {
  InputNumber,
  Button,
  Spin,
  Collapse,
  Slider,
  Row,
  Col,
  Tooltip,
  List,
  Select,
} from "antd";
import { BlockMath } from "react-katex";

import { InfoCircleOutlined } from "@ant-design/icons";
import RcResizeObserver from "rc-resize-observer";
import { ProCard, StatisticCard } from "@ant-design/pro-components";
import axios from "axios";
import { Divider, Typography } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Option } = Select;

const { Panel } = Collapse;
const { Statistic } = StatisticCard;

const formulas = [
  {
    title: "Risk-Free Rate",
    description:
      "The return on an investment with no risk of financial loss, often represented by government bonds.",
    formula:
      "riskFreeRate = \\text{User Inputs} \\text{ if provided, else API Data}",
  },
  {
    title: "Market Risk Premium",
    description:
      "The additional return expected from holding a risky market portfolio instead of risk-free assets.",
    formula:
      "marketRiskPremium = \\text{User Inputs} \\text{ if provided, else API Data}",
  },
  {
    title: "Cost of Equity",
    description:
      "The return that equity investors expect on their investment in the firm.",
    formula:
      "costOfEquity = riskFreeRate + \\text{beta} \\times marketRiskPremium",
  },
  {
    title: "Weighted Average Cost of Capital (WACC)",
    description:
      "The average rate of return a company is expected to pay its security holders to finance its assets.",
    formula:
      "WACC = \\left(\\frac{totalEquity}{totalEquity + totalDebt}\\right) \\times costOfEquity + \\left(\\frac{totalDebt}{totalEquity + totalDebt}\\right) \\times afterTaxCostOfDebt",
  },
  {
    title: "Free Cash Flow (FCF)",
    description:
      "Cash generated by the company that is available for distribution to the company's security holders.",
    formula:
      "FCF_{t} = \\text{FCF}_{t-1} \\times (1 + \\text{revenueGrowthRate})",
  },
  {
    title: "Terminal Value (TV)",
    description:
      "The value of a company's expected free cash flow beyond the explicit forecast period.",
    formula:
      "TV = \\frac{FCF_{n} \\times (1 + \\text{longTermGrowthRate})}{WACC - \\text{longTermGrowthRate}}",
  },
  {
    title: "Present Value of Free Cash Flows (PVFCF)",
    description:
      "The value of expected future free cash flows discounted back to their value today.",
    formula: "PVFCF = \\sum_{t=1}^{n} \\frac{FCF_{t}}{(1 + WACC)^t}",
  },
  {
    title: "Present Value of Terminal Value (PVTV)",
    description: "The present value of the terminal value.",
    formula: "PVTV = \\frac{TV}{(1 + WACC)^n}",
  },
  {
    title: "Enterprise Value (EV)",
    description: "The total value of the firm, including debt and equity.",
    formula: "EV = PVFCF + PVTV",
  },
  {
    title: "Equity Value",
    description: "The value of the company available to shareholders.",
    formula: "EquityValue = EV - \\text{netDebt}",
  },
  {
    title: "Equity Value per Share",
    description: "The value of the company per outstanding share.",
    formula:
      "EquityValuePerShare = \\frac{EquityValue}{\\text{dilutedSharesOutstanding}}",
  },
  {
    title: "Cost of Debt",
    description: "The interest rate a company pays on its debt.",
    formula:
      "costofDebt = \\text{User Inputs} \\text{ if provided, else API Data}",
  },
];

export const DCFFCompany = ({ symbol }) => {
  const [loading, setLoading] = useState(true);
  const [taxRate, setTaxRate] = useState(null);
  const [costofDebt, setCostofDebt] = useState(null);
  const [revenueGrowthRate, setrevenueGrowthRate] = useState(null);

  const [afterTaxCostOfDebt, setAfterTaxCostOfDebt] = useState(null);
  const [riskFreeRate, setRiskFreeRate] = useState(null);
  const [marketRiskPremium, setMarketRiskPremium] = useState(null);
  const [longTermGrowthRate, setLongTermGrowthRate] = useState(null);
  const [beta, setBeta] = useState(null);
  const [dcfResult, setDcfResult] = useState({});
  const [isRecalculateDisabled, setIsRecalculateDisabled] = useState(true);
  const [responsive, setResponsive] = useState(false);

  const fetchData = async () => {
    const apiUrl =
      "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";

    try {
      // Fetch the advanced discounted cash flow data via API Gateway
      const response = await axios.post(apiUrl, {
        queryStringParameters: {
          endpoint: "v4/advanced_discounted_cash_flow",
          symbols: symbol,
        },
      });

      // Parse the response body and extract the result
      const data = JSON.parse(response.data.body);
      const result = data[0]; // Get the first item in the result array

      if (result) {
        setAPIData(result); // Update state with API data
        calculateDCF(result); // Perform DCF calculations
      } else {
        console.error("No data received for DCF calculation.");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching DCF data:", error);
      setLoading(false);
    }
  };

  const setAPIData = (apiData) => {
    setCostofDebt(apiData.costofDebt);
    setrevenueGrowthRate(apiData.revenuePercentage);

    setAfterTaxCostOfDebt(apiData.afterTaxCostOfDebt);
    setRiskFreeRate(apiData.riskFreeRate);
    setMarketRiskPremium(apiData.marketRiskPremium);
    setLongTermGrowthRate(apiData.longTermGrowthRate);
    setTaxRate(apiData.taxRate);
    setBeta(apiData.beta);
  };

  useEffect(() => {
    fetchData();
  }, [symbol]);

  const calculateDCF = async (apiData) => {
    try {
      const inputs = {
        costofDebt: costofDebt ?? apiData?.costofDebt,
        revenueGrowthRate: revenueGrowthRate ?? apiData?.revenuePercentage,
        beta: beta ?? apiData.beta,
        taxRate: taxRate ?? apiData?.taxRate,
        afterTaxCostOfDebt: afterTaxCostOfDebt ?? apiData?.afterTaxCostOfDebt,
        riskFreeRate: riskFreeRate ?? apiData?.riskFreeRate,
        marketRiskPremium: marketRiskPremium ?? apiData?.marketRiskPremium,
        longTermGrowthRate: longTermGrowthRate ?? apiData?.longTermGrowthRate,
      };

      // console.log(inputs);

      const response = await fetch(
        "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            text: `${symbol}@@@${JSON.stringify(inputs)}`,
            model_name: "DCFCalculator",
          }),
        }
      );

      const dcfResult_fetch = await response.json();
      setDcfResult(dcfResult_fetch?.summary);
    } catch (error) {
      console.error("Error calculating DCF:", error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(dcfResult);

  const handleRecalculate = () => {
    setLoading(true);
    calculateDCF();
    setIsRecalculateDisabled(true);
  };

  const handleSliderChange = () => {
    setIsRecalculateDisabled(false);
  };

  const DecimalStep = ({ min, max, value, onChange }) => (
    <Row>
      <Col span={14}>
        <Slider
          min={min}
          max={max}
          onChange={onChange}
          value={typeof value === "number" ? value : 0}
          step={0.2}
        />
      </Col>
      <Col span={2}>
        <InputNumber
          min={min}
          max={max}
          style={{ margin: "0 16px" }}
          step={0.2}
          value={value}
          onChange={onChange}
        />
      </Col>
    </Row>
  );

  // Function to format today's date
  const getCurrentDate = () => {
    const today = new Date();
    return today.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  // This effect updates the 'responsive' state based on the component width.
  useEffect(() => {
    const handleResize = () => {
      setResponsive(window.innerWidth < 768); // Adjust to 768px as the breakpoint for mobile devices
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Inline styles for smaller screens
  const cardStyle = {
    margin: responsive ? "10px 0" : "10px",
    padding: responsive ? "10px" : "20px",
    backgroundColor: "#e6f7ff",
  };

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <div>
        <ProCard
          title="Valuation: DCF Calculator"
          extra={`As on ${getCurrentDate()}`}
          bordered
          headerBordered
          split={responsive ? "horizontal" : "vertical"}
          // style={{ backgroundColor: "#e6f7ff" }}
          style={cardStyle}
        >
          <ProCard split="horizontal">
            <Row gutter={[16, 16]}>
              {/* <Col span={8}> */}
              <Col span={responsive ? 24 : 8}>
                <ProCard
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title level={5} style={{ margin: 0 }}>
                        Revenue Growth %
                      </Title>
                      <Tooltip title="The rate at which a company's revenues are expected to grow">
                        <InfoCircleOutlined
                          style={{
                            marginLeft: 8,
                            color: "#1890ff",
                            fontSize: "12px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                  bordered
                >
                  <DecimalStep
                    min={-20}
                    max={80}
                    step={0.2}
                    value={revenueGrowthRate}
                    onChange={(value) => {
                      setrevenueGrowthRate(value);
                      handleSliderChange();
                    }}
                  />
                </ProCard>
              </Col>

              {/* <Col span={8}> */}
              <Col span={responsive ? 24 : 8}>
                <ProCard
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title level={5} style={{ margin: 0 }}>
                        Terminal Rate %
                      </Title>
                      <Tooltip title="The rate at which a company's earnings are expected to grow in perpetuity">
                        <InfoCircleOutlined
                          style={{
                            marginLeft: 8,
                            color: "#1890ff",
                            fontSize: "12px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                  bordered
                >
                  <DecimalStep
                    min={-10}
                    max={20}
                    step={0.2}
                    value={longTermGrowthRate}
                    onChange={(value) => {
                      setLongTermGrowthRate(value);
                      handleSliderChange();
                    }}
                  />
                </ProCard>
              </Col>

              {/* <Col span={8}> */}
              <Col span={responsive ? 24 : 8}>
                <ProCard
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title level={5} style={{ margin: 0 }}>
                        Cost of Debt %
                      </Title>
                      <Tooltip title="The effective rate that a company pays on its borrowed funds.">
                        <InfoCircleOutlined
                          style={{
                            marginLeft: 8,
                            color: "#1890ff",
                            fontSize: "12px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                  bordered
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Slider
                      min={0}
                      max={20}
                      step={0.2}
                      value={costofDebt}
                      onChange={(value) => {
                        setCostofDebt(value);
                        handleSliderChange();
                      }}
                      style={{ flexGrow: 1 }}
                    />
                    <Text type="secondary" style={{ marginLeft: 8 }}>
                      {costofDebt}
                    </Text>
                  </div>
                </ProCard>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              {/* <Col span={8}> */}
              <Col span={responsive ? 24 : 8}>
                <ProCard
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title level={5} style={{ margin: 0 }}>
                        Risk-Free Rate %
                      </Title>
                      <Tooltip title="The theoretical rate of return of an investment with zero risk.">
                        <InfoCircleOutlined
                          style={{
                            marginLeft: 8,
                            color: "#1890ff",
                            fontSize: "12px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                  bordered
                >
                  <InputNumber
                    min={0}
                    max={10}
                    step={0.2}
                    value={riskFreeRate}
                    onChange={(value) => {
                      setRiskFreeRate(value);
                      handleSliderChange();
                    }}
                    // style={{ width: "100%" }}
                    style={{
                      width: "100%",
                      height: responsive ? "25%" : "15%%",
                    }}
                  />
                </ProCard>
              </Col>
              {/* <Col span={8}> */}
              <Col span={responsive ? 24 : 8}>
                <ProCard
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title level={5} style={{ margin: 0 }}>
                        Market Risk Premium %
                      </Title>
                      <Tooltip title="The return over the risk-free rate required by investors to compensate for the risk of holding a market portfolio.">
                        <InfoCircleOutlined
                          style={{
                            marginLeft: 8,
                            color: "#1890ff",
                            fontSize: "12px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                  bordered
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Slider
                      min={0}
                      max={20}
                      step={0.2}
                      value={marketRiskPremium}
                      onChange={(value) => {
                        setMarketRiskPremium(value);
                        handleSliderChange();
                      }}
                      // style={{ flexGrow: 1 }}
                      style={{
                        flexGrow: 1,
                        height: responsive ? "15%" : "10%",
                      }}
                    />
                    <Text type="secondary" style={{ marginLeft: 8 }}>
                      {marketRiskPremium}
                    </Text>
                  </div>
                </ProCard>
              </Col>

              {/* <Col span={8}> */}
              <Col span={responsive ? 24 : 8}>
                <ProCard
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title level={5} style={{ margin: 0 }}>
                        Beta
                      </Title>
                      <Tooltip title="Volatility of Stock">
                        <InfoCircleOutlined
                          style={{
                            marginLeft: 8,
                            color: "#1890ff",
                            fontSize: "12px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                  bordered
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Slider
                      min={0}
                      max={4}
                      step={0.2}
                      value={beta}
                      onChange={(value) => {
                        setBeta(value);
                        handleSliderChange();
                      }}
                      // style={{ flexGrow: 1 }}
                      style={{
                        flexGrow: 1,
                        height: responsive ? "15%" : "10%",
                      }}
                    />
                    <Text type="secondary" style={{ marginLeft: 8 }}>
                      {beta}
                    </Text>
                  </div>
                </ProCard>
              </Col>
            </Row>

            <ProCard
              title="DCF Results"
              bordered
              headerBordered
              split={responsive ? "horizontal" : "vertical"}
            >
              {loading ? (
                <Spin />
              ) : (
                <Row gutter={[16, 16]} justify="center">
                  {/* <Col span={6}> */}
                  <Col span={responsive ? 24 : 6}>
                    <StatisticCard
                      statistic={{
                        title: "Current Stock Price",
                        value: `$${dcfResult?.current_stock_price?.toFixed(2)}`,
                      }}
                      bodyStyle={{ textAlign: "center" }} // Center the content of the card
                    />
                  </Col>
                  {/* <Col span={6}> */}
                  <Col span={responsive ? 24 : 6}>
                    <StatisticCard
                      statistic={{
                        title: "Intrinsic Value",
                        value: `$${dcfResult?.equity_value_per_share?.toFixed(
                          1
                        )}`,

                        description: (
                          <Statistic
                            title="Implied Change"
                            value={`${(
                              ((dcfResult?.equity_value_per_share -
                                dcfResult?.current_stock_price) /
                                dcfResult?.current_stock_price) *
                              100
                            ).toFixed(2)}%`}
                            bodyStyle={{ textAlign: "center" }} // Center the content of the card
                          />
                        ),
                      }}
                      bodyStyle={{ textAlign: "center" }} // Center the content
                    />
                  </Col>
                  {/* <Col span={6}> */}
                  <Col span={responsive ? 24 : 6}>
                    <StatisticCard
                      statistic={{
                        title: "WACC",
                        value: `${(100 * dcfResult?.wacc)?.toFixed(2)}%`,
                      }}
                      bodyStyle={{ textAlign: "center" }} // Center the content of the card
                    />
                  </Col>

                  <Col
                    span={responsive ? 24 : 6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={handleRecalculate}
                      disabled={isRecalculateDisabled}
                      style={{
                        margin: "5%",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      Calculate DCF
                    </Button>
                  </Col>
                </Row>
              )}
            </ProCard>
          </ProCard>
        </ProCard>

        <ProCard>
          <Collapse>
            <Panel header="Formulas Used" key="1">
              <List
                itemLayout="horizontal"
                dataSource={formulas}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<strong>{item.title}</strong>}
                      description={
                        <div>
                          <p>{item.description}</p>
                          <BlockMath math={item.formula} />
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </Panel>
          </Collapse>
        </ProCard>
      </div>
    </RcResizeObserver>
  );
};
