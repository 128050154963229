import React, { useState, useEffect, useRef, useCallback } from "react";
import Latex from "react-latex";
import "katex/dist/katex.min.css";
import RcResizeObserver from "rc-resize-observer";
import { DCFFCompany } from "./Valuation";
import { DemoScatter } from "./CompanyPage";
import {
  Layout,
  Menu,
  Card,
  Input,
  Button,
  message,
  Skeleton,
  Dropdown,
  Divider,
  List,
  Table,
  Tag,
  Spin,
  Collapse,
  Slider,
  Select,
} from "antd";
import { Link } from "react-router-dom";

import Spreadsheet from "react-spreadsheet";
import { DataGrid } from "react-data-grid";
import "react-data-grid/lib/styles.css";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  LeftOutlined,
  RightOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import "./App.css";
import MarkdownPreview from "@uiw/react-markdown-preview";
import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { Row, Col, Typography } from "antd";
import {
  ProCard,
  ProTable,
  StatisticCard,
  EditableProTable,
  ProFormField,
} from "@ant-design/pro-components";
import { Scatter } from "@ant-design/plots";
import "./App.css";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import {
  Column as BarChartPlot,
  Line as LineChartPlot,
} from "@ant-design/plots";

import { InputNumber } from "antd";
import * as XLSX from "xlsx";

import "./App.css";

const { Header, Content, Sider } = Layout;
const { Title, Text } = Typography;
const { Statistic } = StatisticCard;
const { Column } = Table;
const { Option } = Select;

const Container = styled.div`
  padding: 20px;
  background: #f9f9f9;
`;

const SlidersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const TableContainer = styled.div`
  margin-top: 20px;

  .custom-row {
    height: 12px; /* Adjust this value to change the row height */
  }

  .custom-cell {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const DCFResultsContainer = styled.div`
  margin-left: 20px;
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #1890ff;
  border-color: #1890ff;
  transition: all 0.3s;

  &:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }

  &:disabled {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
  }

  &:active {
    background-color: #096dd9;
    border-color: #096dd9;
    transform: scale(0.95);
  }
`;

export const columnDefinitionsArray = [
  {
    "**Column Name**": "**symbol**",
    "**Description**": "Stock ticker symbol",
    "**Sample Value**": "AMZN",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**price_latest**",
    "**Description**": "Latest stock price",
    "**Sample Value**": "201.45",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**beta**",
    "**Description**": "Beta value indicating stock volatility",
    "**Sample Value**": "1.15",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**last_dividend**",
    "**Description**": "Most recent dividend paid",
    "**Sample Value**": "0",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**price_change_today_percent**",
    "**Description**": "Percentage change in stock price today",
    "**Sample Value**": "4.33",
    "**Measurement Unit**": "Percent",
  },
  {
    "**Column Name**": "**companyname**",
    "**Description**": "Full name of the company",
    "**Sample Value**": "Amazon.com, Inc.",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**industry**",
    "**Description**": "Industry category",
    "**Sample Value**": "Specialty Retail",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**sector**",
    "**Description**": "Sector the company belongs to",
    "**Sample Value**": "Consumer Cyclical",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**country**",
    "**Description**": "Country where the company is based",
    "**Sample Value**": "US",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**fulltimeemployees**",
    "**Description**": "Number of full-time employees",
    "**Sample Value**": "1551000",
    "**Measurement Unit**": "Number",
  },
  {
    "**Column Name**": "**dcf_difference_from_price**",
    "**Description**":
      "Difference between DCF (Discounted Cash Flow) and price",
    "**Sample Value**": "150.09",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**dcf**",
    "**Description**": "Discounted Cash Flow value",
    "**Sample Value**": "50.78",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**ipodate**",
    "**Description**": "Date of the company's initial public offering",
    "**Sample Value**": "1997-05-15",
    "**Measurement Unit**": "Date",
  },
  {
    "**Column Name**": "**image**",
    "**Description**": "URL of the company logo",
    "**Sample Value**":
      "![Logo](https://images.financialmodelingprep.com/symbol/AMZN.png)",
    "**Measurement Unit**": "URL",
  },
  {
    "**Column Name**": "**currency**",
    "**Description**": "Currency used for transactions",
    "**Sample Value**": "USD",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**city**",
    "**Description**": "City where the company is headquartered",
    "**Sample Value**": "Seattle",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**state**",
    "**Description**": "State where the company is headquartered",
    "**Sample Value**": "WA",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**grossprofitratio_annual**",
    "**Description**": "Annual gross profit ratio",
    "**Sample Value**": "0.47",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**ebitdaratio_annual**",
    "**Description**": "Annual EBITDA ratio",
    "**Sample Value**": "0.15",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**operatingincomeratio_annual**",
    "**Description**": "Annual operating income ratio",
    "**Sample Value**": "0.06",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**incomebeforetaxratio_annual**",
    "**Description**": "Annual income before tax ratio",
    "**Sample Value**": "0.07",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincomeratio_annual**",
    "**Description**": "Annual net income ratio",
    "**Sample Value**": "0.05",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**eps_annual**",
    "**Description**": "Annual earnings per share",
    "**Sample Value**": "2.95",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**eps_preceding_year_annual**",
    "**Description**": "EPS for the preceding year",
    "**Sample Value**": "-0.27",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**eps_2_years_back_annual**",
    "**Description**": "EPS two years back",
    "**Sample Value**": "3.30",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**eps_3_years_back_annual**",
    "**Description**": "EPS three years back",
    "**Sample Value**": "2.13",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**netincomeratio_preceding_year_annual**",
    "**Description**": "Net income ratio for the preceding year",
    "**Sample Value**": "-0.01",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincomeratio_2_years_back_annual**",
    "**Description**": "Net income ratio two years back",
    "**Sample Value**": "0.07",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincomeratio_3_years_back_annual**",
    "**Description**": "Net income ratio three years back",
    "**Sample Value**": "0.06",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**grossprofitratio_quarterly**",
    "**Description**": "Quarterly gross profit ratio",
    "**Sample Value**": "0.49",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**ebitdaratio_quarterly**",
    "**Description**": "Quarterly EBITDA ratio",
    "**Sample Value**": "0.19",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**operatingincomeratio_quarterly**",
    "**Description**": "Quarterly operating income ratio",
    "**Sample Value**": "0.11",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**incomebeforetaxratio_quarterly**",
    "**Description**": "Quarterly income before tax ratio",
    "**Sample Value**": "0.11",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincomeratio_quarterly**",
    "**Description**": "Quarterly net income ratio",
    "**Sample Value**": "0.10",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**eps_quarterly**",
    "**Description**": "Quarterly earnings per share",
    "**Sample Value**": "1.46",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**eps_preceding_quarter_quarterly**",
    "**Description**": "EPS for the preceding quarter",
    "**Sample Value**": "1.29",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**eps_2_quarters_back_quarterly**",
    "**Description**": "EPS two quarters back",
    "**Sample Value**": "1.00",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**eps_3_quarters_back_quarterly**",
    "**Description**": "EPS three quarters back",
    "**Sample Value**": "1.03",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**eps_4_quarters_back_quarterly**",
    "**Description**": "EPS four quarters back",
    "**Sample Value**": "0.96",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**netincomeratio_preceding_quarter_quarterly**",
    "**Description**": "Net income ratio for the preceding quarter",
    "**Sample Value**": "0.09",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincomeratio_2_quarters_back_quarterly**",
    "**Description**": "Net income ratio two quarters back",
    "**Sample Value**": "0.07",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincomeratio_3_quarters_back_quarterly**",
    "**Description**": "Net income ratio three quarters back",
    "**Sample Value**": "0.06",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincomeratio_4_quarters_back_quarterly**",
    "**Description**": "Net income ratio four quarters back",
    "**Sample Value**": "0.07",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**revenue_change_qoq**",
    "**Description**": "Quarter-over-quarter revenue change",
    "**Sample Value**": "0.07",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**revenue_change_annual**",
    "**Description**": "Year-over-year revenue change",
    "**Sample Value**": "0.18",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**eps_change_qoq**",
    "**Description**": "Quarter-over-quarter EPS change",
    "**Sample Value**": "0.13",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**eps_change_annual**",
    "**Description**": "Year-over-year EPS change",
    "**Sample Value**": "1.21",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincome_change_qoq**",
    "**Description**": "Quarter-over-quarter net income change",
    "**Sample Value**": "0.14",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincome_change_annual**",
    "**Description**": "Year-over-year net income change",
    "**Sample Value**": "1.27",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**operatingincome_change_qoq**",
    "**Description**": "Quarter-over-quarter operating income change",
    "**Sample Value**": "0.19",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**operatingincome_change_annual**",
    "**Description**": "Year-over-year operating income change",
    "**Sample Value**": "1.27",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**grossprofit_change_qoq**",
    "**Description**": "Quarter-over-quarter gross profit change",
    "**Sample Value**": "0.05",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**grossprofit_change_annual**",
    "**Description**": "Year-over-year gross profit change",
    "**Sample Value**": "0.20",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**analyst_estimated_eps**",
    "**Description**":
      "Analyst estimated earnings per share for the current quarter",
    "**Sample Value**": "1.14",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**analyst_estimated_eps_preceding_quarter**",
    "**Description**": "Analyst estimated EPS for the preceding quarter",
    "**Sample Value**": "1.03",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**analyst_estimated_eps_2_quarters_back**",
    "**Description**": "Analyst estimated EPS for two quarters back",
    "**Sample Value**": "0.84",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**analyst_estimated_eps_3_quarters_back**",
    "**Description**": "Analyst estimated EPS for three quarters back",
    "**Sample Value**": "0.79",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**analyst_estimated_eps_next_quarter**",
    "**Description**": "Analyst estimated EPS for the next quarter",
    "**Sample Value**": "1.47",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**last_stock_split_date**",
    "**Description**": "Date of the company's most recent stock split",
    "**Sample Value**": "2022-06-06",
    "**Measurement Unit**": "Date",
  },
  {
    "**Column Name**": "**split_ratio**",
    "**Description**": "Ratio of the most recent stock split",
    "**Sample Value**": "20",
    "**Measurement Unit**": "Number",
  },
  {
    "**Column Name**": "**last_dividend_date**",
    "**Description**": "Date of the last dividend payment",
    "**Sample Value**": "None",
    "**Measurement Unit**": "Date",
  },
  {
    "**Column Name**": "**dividend**",
    "**Description**": "Most recent dividend paid",
    "**Sample Value**": "None",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**rating**",
    "**Description**": "Stock rating by analysts",
    "**Sample Value**": "B+",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**ratingscore**",
    "**Description**": "Numerical representation of the stock rating",
    "**Sample Value**": "3",
    "**Measurement Unit**": "Number",
  },
  {
    "**Column Name**": "**ratingrecommendation**",
    "**Description**": "Analyst recommendation for the stock",
    "**Sample Value**": "Neutral",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**revenuepershare**",
    "**Description**": "Revenue generated per share",
    "**Sample Value**": "15.13",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**netincomepershare**",
    "**Description**": "Net income generated per share",
    "**Sample Value**": "1.46",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**bookvaluepershare**",
    "**Description**": "Book value per share",
    "**Sample Value**": "24.68",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**tangiblebookvaluepershare**",
    "**Description**": "Tangible book value per share",
    "**Sample Value**": "22.48",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**shareholdersequitypershare**",
    "**Description**": "Shareholders' equity per share",
    "**Sample Value**": "24.68",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**interestdebtpershare**",
    "**Description**": "Interest-bearing debt per share",
    "**Sample Value**": "5.28",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**peratio**",
    "**Description**": "Price-to-earnings ratio",
    "**Sample Value**": "31.91",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pocfratio**",
    "**Description**": "Price-to-operating cash flow ratio",
    "**Sample Value**": "75.34",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pfcfratio**",
    "**Description**": "Price-to-free cash flow ratio",
    "**Sample Value**": "583.90",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pbratio**",
    "**Description**": "Price-to-book ratio",
    "**Sample Value**": "7.55",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**evtosales**",
    "**Description**": "Enterprise value-to-sales ratio",
    "**Sample Value**": "12.69",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**enterprisevalueoverebitda**",
    "**Description**": "Enterprise value-to-EBITDA ratio",
    "**Sample Value**": "65.35",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**evtooperatingcashflow**",
    "**Description**": "Enterprise value-to-operating cash flow ratio",
    "**Sample Value**": "77.63",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**evtofreecashflow**",
    "**Description**": "Enterprise value-to-free cash flow ratio",
    "**Sample Value**": "601.69",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**earningsyield**",
    "**Description**": "Earnings yield",
    "**Sample Value**": "0.01",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**freecashflowyield**",
    "**Description**": "Free cash flow yield",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**debttoequity**",
    "**Description**": "Debt-to-equity ratio",
    "**Sample Value**": "0.21",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**debttoassets**",
    "**Description**": "Debt-to-assets ratio",
    "**Sample Value**": "0.09",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netdebttoebitda**",
    "**Description**": "Net debt-to-EBITDA ratio",
    "**Sample Value**": "1.93",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**incomequality**",
    "**Description**": "Quality of income",
    "**Sample Value**": "1.69",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**salesgeneralandadministrativetorevenue**",
    "**Description**": "Ratio of SG&A expenses to revenue",
    "**Sample Value**": "0.02",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**researchandddevelopementtorevenue**",
    "**Description**": "Ratio of R&D expenses to revenue",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**intangiblestototalassets**",
    "**Description**": "Ratio of intangible assets to total assets",
    "**Sample Value**": "0.04",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**capextooperatingcashflow**",
    "**Description**": "Ratio of capital expenditure to operating cash flow",
    "**Sample Value**": "0.87",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**capextorevenue**",
    "**Description**": "Ratio of capital expenditure to revenue",
    "**Sample Value**": "0.14",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**capextodepreciation**",
    "**Description**": "Ratio of capital expenditure to depreciation",
    "**Sample Value**": "1.68",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**stockbasedcompensationtorevenue**",
    "**Description**": "Ratio of stock-based compensation to revenue",
    "**Sample Value**": "0.03",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**grahamnumber**",
    "**Description**": "Graham number, indicating stock intrinsic value",
    "**Sample Value**": "28.47",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**roic**",
    "**Description**": "Return on invested capital",
    "**Sample Value**": "0.05",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**returnontangibleassets**",
    "**Description**": "Return on tangible assets",
    "**Sample Value**": "0.03",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**roe**",
    "**Description**": "Return on equity",
    "**Sample Value**": "0.06",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**capexpershare**",
    "**Description**": "Capital expenditure per share",
    "**Sample Value**": "2.15",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**currentratio**",
    "**Description**": "Current ratio",
    "**Sample Value**": "1.09",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**quickratio**",
    "**Description**": "Quick ratio",
    "**Sample Value**": "0.87",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**cashratio**",
    "**Description**": "Cash ratio",
    "**Sample Value**": "0.47",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**daysofsalesoutstanding**",
    "**Description**": "Average number of days it takes to collect receivables",
    "**Sample Value**": "29.25",
    "**Measurement Unit**": "Days",
  },
  {
    "**Column Name**": "**daysofinventoryoutstanding**",
    "**Description**": "Average number of days inventory remains in stock",
    "**Sample Value**": "40.13",
    "**Measurement Unit**": "Days",
  },
  {
    "**Column Name**": "**operatingcycle**",
    "**Description**":
      "Total time taken for inventory turnover and receivables",
    "**Sample Value**": "69.38",
    "**Measurement Unit**": "Days",
  },
  {
    "**Column Name**": "**daysofpayablesoutstanding**",
    "**Description**": "Average number of days to pay payables",
    "**Sample Value**": "93.99",
    "**Measurement Unit**": "Days",
  },
  {
    "**Column Name**": "**cashconversioncycle**",
    "**Description**": "Time taken to convert resources into cash flows",
    "**Sample Value**": "-24.62",
    "**Measurement Unit**": "Days",
  },
  {
    "**Column Name**": "**grossprofitmargin**",
    "**Description**": "Ratio of gross profit to revenue",
    "**Sample Value**": "0.49",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**operatingprofitmargin**",
    "**Description**": "Ratio of operating profit to revenue",
    "**Sample Value**": "0.11",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pretaxprofitmargin**",
    "**Description**": "Ratio of pretax profit to revenue",
    "**Sample Value**": "0.11",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netprofitmargin**",
    "**Description**": "Ratio of net profit to revenue",
    "**Sample Value**": "0.10",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**effectivetaxrate**",
    "**Description**": "Effective tax rate on earnings",
    "**Sample Value**": "0.15",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**returnonassets**",
    "**Description**": "Return on assets",
    "**Sample Value**": "0.03",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**returnonequity**",
    "**Description**": "Return on equity",
    "**Sample Value**": "0.06",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**returnoncapitalemployed**",
    "**Description**": "Return on capital employed",
    "**Sample Value**": "0.04",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**netincomeperebt**",
    "**Description**": "Net income per earnings before tax",
    "**Sample Value**": "0.85",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**ebtperebit**",
    "**Description**": "Earnings before tax per EBIT",
    "**Sample Value**": "1.04",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**ebitperrevenue**",
    "**Description**": "EBIT per revenue",
    "**Sample Value**": "0.11",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**debtratio**",
    "**Description**": "Ratio of total debt to total assets",
    "**Sample Value**": "0.09",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**debtequityratio**",
    "**Description**": "Ratio of total debt to equity",
    "**Sample Value**": "0.21",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**longtermdebttocapitalization**",
    "**Description**": "Ratio of long-term debt to total capitalization",
    "**Sample Value**": "0.17",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**totaldebttocapitalization**",
    "**Description**": "Ratio of total debt to total capitalization",
    "**Sample Value**": "0.17",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**interestcoverage**",
    "**Description**":
      "Ability to cover interest expenses with operating income",
    "**Sample Value**": "28.87",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**cashflowtodebtratio**",
    "**Description**": "Ratio of cash flow to total debt",
    "**Sample Value**": "0.47",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**companyequitymultiplier**",
    "**Description**": "Measure of financial leverage",
    "**Sample Value**": "2.26",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**receivablesturnover**",
    "**Description**": "How efficiently receivables are collected",
    "**Sample Value**": "3.08",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**payablesturnover**",
    "**Description**": "How efficiently payables are paid",
    "**Sample Value**": "0.96",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**inventoryturnover**",
    "**Description**": "How efficiently inventory is managed",
    "**Sample Value**": "2.24",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**fixedassetturnover**",
    "**Description**": "Revenue generated per unit of fixed asset",
    "**Sample Value**": "0.51",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**assetturnover**",
    "**Description**": "Revenue generated per unit of total asset",
    "**Sample Value**": "0.27",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**operatingcashflowpershare**",
    "**Description**": "Operating cash flow per share",
    "**Sample Value**": "2.47",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**freecashflowpershare**",
    "**Description**": "Free cash flow per share",
    "**Sample Value**": "0.32",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**cashpershare**",
    "**Description**": "Cash available per share",
    "**Sample Value**": "8.39",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**payoutratio**",
    "**Description**": "Proportion of earnings paid out as dividends",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**operatingcashflowsalesratio**",
    "**Description**": "Operating cash flow to sales ratio",
    "**Sample Value**": "0.16",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**freecashflowoperatingcashflowratio**",
    "**Description**": "Free cash flow to operating cash flow ratio",
    "**Sample Value**": "0.13",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**cashflowcoverageratios**",
    "**Description**": "Coverage ratio of cash flow",
    "**Sample Value**": "0.47",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**shorttermcoverageratios**",
    "**Description**": "Coverage ratio for short-term liabilities",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**capitalexpenditurecoverageratio**",
    "**Description**": "Ability to cover capital expenditures with cash flow",
    "**Sample Value**": "1.15",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**dividendpaidandcapexcoverageratio**",
    "**Description**":
      "Coverage of dividends and capital expenditure with cash flow",
    "**Sample Value**": "1.15",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**dividendpayoutratio**",
    "**Description**": "Proportion of dividends paid out to earnings",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pricebookvalueratio**",
    "**Description**": "Market price to book value ratio",
    "**Sample Value**": "7.55",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pricetobookratio**",
    "**Description**": "Alternate name for price-to-book ratio",
    "**Sample Value**": "7.55",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pricetosalesratio**",
    "**Description**": "Market price to sales ratio",
    "**Sample Value**": "12.32",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pricetofreecashflowsratio**",
    "**Description**": "Market price to free cash flow ratio",
    "**Sample Value**": "583.90",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pricetooperatingcashflowsratio**",
    "**Description**": "Market price to operating cash flow ratio",
    "**Sample Value**": "75.34",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pricecashflowratio**",
    "**Description**": "Market price to cash flow ratio",
    "**Sample Value**": "75.34",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**priceearningstogrowthratio**",
    "**Description**": "Market price to growth in earnings",
    "**Sample Value**": "2.42",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pricesalesratio**",
    "**Description**": "Alternate name for price-to-sales ratio",
    "**Sample Value**": "12.32",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**dividendyield**",
    "**Description**": "Dividends per share as a percentage of stock price",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**enterprisevaluemultiple**",
    "**Description**": "Ratio of enterprise value to EBITDA",
    "**Sample Value**": "65.35",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**pricefairvalue**",
    "**Description**": "Fair value of the stock",
    "**Sample Value**": "7.55",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**threeyrevenuegrowthpershare**",
    "**Description**": "Revenue growth per share over the last three years",
    "**Sample Value**": "0.38",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**fiveyrevenuegrowthpershare**",
    "**Description**": "Revenue growth per share over the last five years",
    "**Sample Value**": "1.14",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**tenyrevenuegrowthpershare**",
    "**Description**": "Revenue growth per share over the last ten years",
    "**Sample Value**": "5.76",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**threeynetincomegrowthpershare**",
    "**Description**": "Net income growth per share over the last three years",
    "**Sample Value**": "3.69",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**fiveynetincomegrowthpershare**",
    "**Description**": "Net income growth per share over the last five years",
    "**Sample Value**": "5.77",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**tenynetincomegrowthpershare**",
    "**Description**": "Net income growth per share over the last ten years",
    "**Sample Value**": "31.73",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**close_price_today**",
    "**Description**": "Stock closing price today",
    "**Sample Value**": "201.45",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**price_change_1day**",
    "**Description**": "Change in stock price over 1 day",
    "**Sample Value**": "0.02",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**price_change_1week**",
    "**Description**": "Change in stock price over 1 week",
    "**Sample Value**": "-0.00",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**price_change_1month**",
    "**Description**": "Change in stock price over 1 month",
    "**Sample Value**": "0.07",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**price_change_3months**",
    "**Description**": "Change in stock price over 3 months",
    "**Sample Value**": "0.13",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**price_change_6months**",
    "**Description**": "Change in stock price over 6 months",
    "**Sample Value**": "0.11",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**price_change_1year**",
    "**Description**": "Change in stock price over 1 year",
    "**Sample Value**": "0.31",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**price_change_3years**",
    "**Description**": "Change in stock price over 3 years",
    "**Sample Value**": "0.31",
    "**Measurement Unit**": "Raw Number",
  },
  {
    "**Column Name**": "**daylow**",
    "**Description**": "Lowest stock price during the day",
    "**Sample Value**": "199.00",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**dayhigh**",
    "**Description**": "Highest stock price during the day",
    "**Sample Value**": "201.95",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**yearhigh**",
    "**Description**": "Highest stock price during the year",
    "**Sample Value**": "215.90",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**yearlow**",
    "**Description**": "Lowest stock price during the year",
    "**Sample Value**": "142.81",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**priceavg50**",
    "**Description**": "Average stock price over the last 50 days",
    "**Sample Value**": "193.00",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**priceavg200**",
    "**Description**": "Average stock price over the last 200 days",
    "**Sample Value**": "183.80",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**exchange**",
    "**Description**": "Stock exchange where the company is listed",
    "**Sample Value**": "NASDAQ",
    "**Measurement Unit**": "Text",
  },
  {
    "**Column Name**": "**volume**",
    "**Description**": "Number of shares traded today",
    "**Sample Value**": "39,715,029",
    "**Measurement Unit**": "Number",
  },
  {
    "**Column Name**": "**avgvolume**",
    "**Description**": "Average number of shares traded daily",
    "**Sample Value**": "37,821,298",
    "**Measurement Unit**": "Number",
  },
  {
    "**Column Name**": "**open_price_today**",
    "**Description**": "Opening stock price today",
    "**Sample Value**": "199.28",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**price_preceding_day**",
    "**Description**": "Closing stock price on the previous day",
    "**Sample Value**": "197.12",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**eps**",
    "**Description**": "Earnings per share",
    "**Sample Value**": "4.67",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**pe**",
    "**Description**": "Price-to-earnings ratio",
    "**Sample Value**": "43.14",
    "**Measurement Unit**": "Ratio",
  },
  {
    "**Column Name**": "**next_result_date**",
    "**Description**": "Date of the next earnings announcement",
    "**Sample Value**": "2025-01-30",
    "**Measurement Unit**": "Date/Time",
  },
  {
    "**Column Name**": "**altmanzscore**",
    "**Description**": "Altman Z-score for financial risk",
    "**Sample Value**": "5.62",
    "**Measurement Unit**": "Score",
  },
  {
    "**Column Name**": "**piotroskiscore**",
    "**Description**": "Piotroski F-score for financial health",
    "**Sample Value**": "7",
    "**Measurement Unit**": "Score",
  },
  {
    "**Column Name**": "**analyst_price_target_high**",
    "**Description**": "Highest price target set by analysts",
    "**Sample Value**": "270.00",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**analyst_price_target_low**",
    "**Description**": "Lowest price target set by analysts",
    "**Sample Value**": "103.00",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**analyst_price_target_consensus**",
    "**Description**": "Consensus price target set by analysts",
    "**Sample Value**": "208.49",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**analyst_price_target_median**",
    "**Description**": "Median price target set by analysts",
    "**Sample Value**": "217.50",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**actual_eps_quarter**",
    "**Description**": "Actual earnings per share for the latest quarter",
    "**Sample Value**": "1.43",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**estimated_eps_quarter**",
    "**Description**": "Estimated earnings per share for the latest quarter",
    "**Sample Value**": "1.14",
    "**Measurement Unit**": "$",
  },
  {
    "**Column Name**": "**market_cap_billion**",
    "**Description**": "Market capitalization",
    "**Sample Value**": "2118.25",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_annual_billion**",
    "**Description**": "Total annual revenue",
    "**Sample Value**": "574.79",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**costofrevenue_annual_billion**",
    "**Description**": "Total annual cost of revenue",
    "**Sample Value**": "304.74",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_annual_billion**",
    "**Description**": "Total annual gross profit",
    "**Sample Value**": "270.05",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**researchanddevelopmentexpenses_annual_billion**",
    "**Description**": "Total annual R&D expenses",
    "**Sample Value**": "85.62",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**generalandadministrativeexpenses_annual_billion**",
    "**Description**": "Total annual general and administrative expenses",
    "**Sample Value**": "11.82",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**sellingandmarketingexpenses_annual_billion**",
    "**Description**": "Total annual selling and marketing expenses",
    "**Sample Value**": "44.37",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**sellinggeneralandadministrativeexpenses_annual_billion**",
    "**Description**": "Total annual SG&A expenses",
    "**Sample Value**": "146.81",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**incometaxexpense_annual_billion**",
    "**Description**": "Total annual income tax expense",
    "**Sample Value**": "7.12",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_annual_billion**",
    "**Description**": "Total annual net income",
    "**Sample Value**": "30.43",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**netcashprovidedbyoperatingactivities_annual_billion**",
    "**Description**": "Total annual net cash provided by operating activities",
    "**Sample Value**": "84.95",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netcashusedforinvestingactivites_annual_billion**",
    "**Description**": "Total annual net cash used for investing activities",
    "**Sample Value**": "-49.83",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**netcashusedprovidedbyfinancingactivities_annual_billion**",
    "**Description**":
      "Total annual net cash used/provided by financing activities",
    "**Sample Value**": "-15.88",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**freecashflow_annual_billion**",
    "**Description**": "Total annual free cash flow",
    "**Sample Value**": "32.22",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**stockbasedcompensation_annual_billion**",
    "**Description**": "Total annual stock-based compensation",
    "**Sample Value**": "24.02",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**capitalexpenditure_annual_billion**",
    "**Description**": "Total annual capital expenditure",
    "**Sample Value**": "-52.73",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalassets_annual_billion**",
    "**Description**": "Total annual assets",
    "**Sample Value**": "527.85",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalliabilities_annual_billion**",
    "**Description**": "Total annual liabilities",
    "**Sample Value**": "325.98",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**shorttermdebt_annual_billion**",
    "**Description**": "Total annual short-term debt",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**longtermdebt_annual_billion**",
    "**Description**": "Total annual long-term debt",
    "**Sample Value**": "135.61",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**retainedearnings_annual_billion**",
    "**Description**": "Total annual retained earnings",
    "**Sample Value**": "113.62",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalstockholdersequity_annual_billion**",
    "**Description**": "Total annual stockholders' equity",
    "**Sample Value**": "201.88",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalequity_annual_billion**",
    "**Description**": "Total annual equity",
    "**Sample Value**": "201.88",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totaldebt_annual_billion**",
    "**Description**": "Total annual debt",
    "**Sample Value**": "135.61",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_preceding_year_annual_billion**",
    "**Description**": "Revenue from the preceding year",
    "**Sample Value**": "513.98",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_2_years_back_annual_billion**",
    "**Description**": "Revenue from two years ago",
    "**Sample Value**": "469.82",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_3_years_back_annual_billion**",
    "**Description**": "Revenue from three years ago",
    "**Sample Value**": "386.06",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_preceding_year_annual_billion**",
    "**Description**": "Gross profit from the preceding year",
    "**Sample Value**": "225.15",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_2_years_back_annual_billion**",
    "**Description**": "Gross profit from two years ago",
    "**Sample Value**": "197.48",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_3_years_back_annual_billion**",
    "**Description**": "Gross profit from three years ago",
    "**Sample Value**": "152.76",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_preceding_year_annual_billion**",
    "**Description**": "EBITDA from the preceding year",
    "**Sample Value**": "55.27",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_2_years_back_annual_billion**",
    "**Description**": "EBITDA from two years ago",
    "**Sample Value**": "59.31",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_3_years_back_annual_billion**",
    "**Description**": "EBITDA from three years ago",
    "**Sample Value**": "48.08",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingincome_preceding_year_annual_billion**",
    "**Description**": "Operating income from the preceding year",
    "**Sample Value**": "12.25",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingincome_2_years_back_annual_billion**",
    "**Description**": "Operating income from two years ago",
    "**Sample Value**": "24.88",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingincome_3_years_back_annual_billion**",
    "**Description**": "Operating income from three years ago",
    "**Sample Value**": "22.90",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_preceding_year_annual_billion**",
    "**Description**": "Net income from the preceding year",
    "**Sample Value**": "-2.72",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_2_years_back_annual_billion**",
    "**Description**": "Net income from two years ago",
    "**Sample Value**": "33.36",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_3_years_back_annual_billion**",
    "**Description**": "Net income from three years ago",
    "**Sample Value**": "21.33",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**otherexpenses_annual_billion**",
    "**Description**": "Other annual expenses",
    "**Sample Value**": "0.77",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingexpenses_annual_billion**",
    "**Description**": "Total annual operating expenses",
    "**Sample Value**": "233.19",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**costandexpenses_annual_billion**",
    "**Description**": "Total annual cost and expenses",
    "**Sample Value**": "537.93",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**interestincome_annual_billion**",
    "**Description**": "Total annual interest income",
    "**Sample Value**": "2.95",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**interestexpense_annual_billion**",
    "**Description**": "Total annual interest expense",
    "**Sample Value**": "3.18",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**depreciationandamortization_annual_billion**",
    "**Description**": "Total annual depreciation and amortization",
    "**Sample Value**": "48.66",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_annual_billion**",
    "**Description**": "Total annual EBITDA",
    "**Sample Value**": "85.52",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingincome_annual_billion**",
    "**Description**": "Total annual operating income",
    "**Sample Value**": "36.85",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalotherincomeexpensesnet_annual_billion**",
    "**Description**": "Total annual other income/expenses net",
    "**Sample Value**": "0.71",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**incomebeforetax_annual_billion**",
    "**Description**": "Total annual income before tax",
    "**Sample Value**": "37.56",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_quarterly_billion**",
    "**Description**": "Revenue for the latest quarter",
    "**Sample Value**": "158.88",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**costofrevenue_quarterly_billion**",
    "**Description**": "Cost of revenue for the latest quarter",
    "**Sample Value**": "80.98",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_quarterly_billion**",
    "**Description**": "Gross profit for the latest quarter",
    "**Sample Value**": "77.90",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**incometaxexpense_quarterly_billion**",
    "**Description**": "Income tax expense for the latest quarter",
    "**Sample Value**": "2.71",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**netcashprovidedbyoperatingactivities_quarterly_billion**",
    "**Description**":
      "Net cash provided by operating activities (latest quarter)",
    "**Sample Value**": "25.97",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netcashusedforinvestingactivites_quarterly_billion**",
    "**Description**":
      "Net cash used for investing activities (latest quarter)",
    "**Sample Value**": "-16.90",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**netcashusedprovidedbyfinancingactivities_quarterly_billion**",
    "**Description**":
      "Net cash used/provided by financing activities (latest quarter)",
    "**Sample Value**": "-2.76",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**freecashflow_quarterly_billion**",
    "**Description**": "Free cash flow for the latest quarter",
    "**Sample Value**": "3.35",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**stockbasedcompensation_quarterly_billion**",
    "**Description**": "Stock-based compensation for the latest quarter",
    "**Sample Value**": "5.33",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**capitalexpenditure_quarterly_billion**",
    "**Description**": "Capital expenditure for the latest quarter",
    "**Sample Value**": "-22.62",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalassets_quarterly_billion**",
    "**Description**": "Total assets for the latest quarter",
    "**Sample Value**": "584.63",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalliabilities_quarterly_billion**",
    "**Description**": "Total liabilities for the latest quarter",
    "**Sample Value**": "325.48",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**shorttermdebt_quarterly_billion**",
    "**Description**": "Short-term debt for the latest quarter",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**longtermdebt_quarterly_billion**",
    "**Description**": "Long-term debt for the latest quarter",
    "**Sample Value**": "134.69",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**retainedearnings_quarterly_billion**",
    "**Description**": "Retained earnings for the latest quarter",
    "**Sample Value**": "152.86",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalstockholdersequity_quarterly_billion**",
    "**Description**": "Total stockholders' equity for the latest quarter",
    "**Sample Value**": "259.15",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalequity_quarterly_billion**",
    "**Description**": "Total equity for the latest quarter",
    "**Sample Value**": "259.15",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totaldebt_quarterly_billion**",
    "**Description**": "Total debt for the latest quarter",
    "**Sample Value**": "134.69",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_preceding_quarter_quarterly_billion**",
    "**Description**": "Revenue from the preceding quarter",
    "**Sample Value**": "147.98",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_2_quarters_back_quarterly_billion**",
    "**Description**": "Revenue from two quarters back",
    "**Sample Value**": "143.31",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_3_quarters_back_quarterly_billion**",
    "**Description**": "Revenue from three quarters back",
    "**Sample Value**": "169.96",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**revenue_4_quarters_back_quarterly_billion**",
    "**Description**": "Revenue from four quarters back",
    "**Sample Value**": "143.08",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_preceding_quarter_quarterly_billion**",
    "**Description**": "Gross profit from the preceding quarter",
    "**Sample Value**": "74.19",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_2_quarters_back_quarterly_billion**",
    "**Description**": "Gross profit from two quarters back",
    "**Sample Value**": "70.68",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_3_quarters_back_quarterly_billion**",
    "**Description**": "Gross profit from three quarters back",
    "**Sample Value**": "77.41",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**grossprofit_4_quarters_back_quarterly_billion**",
    "**Description**": "Gross profit from four quarters back",
    "**Sample Value**": "68.06",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_preceding_quarter_quarterly_billion**",
    "**Description**": "EBITDA from the preceding quarter",
    "**Sample Value**": "26.71",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_2_quarters_back_quarterly_billion**",
    "**Description**": "EBITDA from two quarters back",
    "**Sample Value**": "26.99",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_3_quarters_back_quarterly_billion**",
    "**Description**": "EBITDA from three quarters back",
    "**Sample Value**": "26.61",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_4_quarters_back_quarterly_billion**",
    "**Description**": "EBITDA from four quarters back",
    "**Sample Value**": "23.32",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**operatingincome_preceding_quarter_quarterly_billion**",
    "**Description**": "Operating income from the preceding quarter",
    "**Sample Value**": "14.67",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingincome_2_quarters_back_quarterly_billion**",
    "**Description**": "Operating income from two quarters back",
    "**Sample Value**": "15.31",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingincome_3_quarters_back_quarterly_billion**",
    "**Description**": "Operating income from three quarters back",
    "**Sample Value**": "13.21",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingincome_4_quarters_back_quarterly_billion**",
    "**Description**": "Operating income from four quarters back",
    "**Sample Value**": "11.19",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_preceding_quarter_quarterly_billion**",
    "**Description**": "Net income from the preceding quarter",
    "**Sample Value**": "13.49",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_2_quarters_back_quarterly_billion**",
    "**Description**": "Net income from two quarters back",
    "**Sample Value**": "10.43",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_3_quarters_back_quarterly_billion**",
    "**Description**": "Net income from three quarters back",
    "**Sample Value**": "10.62",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_4_quarters_back_quarterly_billion**",
    "**Description**": "Net income from four quarters back",
    "**Sample Value**": "9.88",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**analyst_estimated_revenue_billion**",
    "**Description**": "Analyst's estimated revenue",
    "**Sample Value**": "157.28",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**analyst_estimated_net_income_billion**",
    "**Description**": "Analyst's estimated net income",
    "**Sample Value**": "12.24",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**analyst_estimated_revenue_preceding_quarter_billion**",
    "**Description**": "Analyst's estimated revenue for the preceding quarter",
    "**Sample Value**": "148.67",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**analyst_estimated_revenue_2_quarters_back_billion**",
    "**Description**": "Analyst's estimated revenue for two quarters back",
    "**Sample Value**": "142.65",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**analyst_estimated_revenue_3_quarters_back_billion**",
    "**Description**": "Analyst's estimated revenue for three quarters back",
    "**Sample Value**": "165.93",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**analyst_estimated_revenue_next_quarter_billion**",
    "**Description**": "Analyst's estimated revenue for the next quarter",
    "**Sample Value**": "187.34",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**analyst_estimated_net_income_preceding_quarter_billion**",
    "**Description**":
      "Analyst's estimated net income for the preceding quarter",
    "**Sample Value**": "11.10",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**analyst_estimated_net_income_2_quarters_back_billion**",
    "**Description**": "Analyst's estimated net income for two quarters back",
    "**Sample Value**": "-8.78",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**analyst_estimated_net_income_3_quarters_back_billion**",
    "**Description**": "Analyst's estimated net income for three quarters back",
    "**Sample Value**": "8.53",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**analyst_estimated_net_income_next_quarter_billion**",
    "**Description**": "Analyst's estimated net income for the next quarter",
    "**Sample Value**": "15.82",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**researchanddevelopmentexpenses_quarterly_billion**",
    "**Description**":
      "Research and development expenses for the latest quarter",
    "**Sample Value**": "0.00",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**generalandadministrativeexpenses_quarterly_billion**",
    "**Description**":
      "General and administrative expenses for the latest quarter",
    "**Sample Value**": "2.71",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**sellingandmarketingexpenses_quarterly_billion**",
    "**Description**": "Selling and marketing expenses for the latest quarter",
    "**Sample Value**": "10.61",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**":
      "**sellinggeneralandadministrativeexpenses_quarterly_billion**",
    "**Description**": "SG&A expenses for the latest quarter",
    "**Sample Value**": "37.98",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**otherexpenses_quarterly_billion**",
    "**Description**": "Other expenses for the latest quarter",
    "**Sample Value**": "22.51",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingexpenses_quarterly_billion**",
    "**Description**": "Operating expenses for the latest quarter",
    "**Sample Value**": "60.49",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**costandexpenses_quarterly_billion**",
    "**Description**": "Cost and expenses for the latest quarter",
    "**Sample Value**": "141.47",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**interestincome_quarterly_billion**",
    "**Description**": "Interest income for the latest quarter",
    "**Sample Value**": "1.26",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**interestexpense_quarterly_billion**",
    "**Description**": "Interest expense for the latest quarter",
    "**Sample Value**": "0.60",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**netincome_quarterly_billion**",
    "**Description**": "Net income for the latest quarter",
    "**Sample Value**": "15.33",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**depreciationandamortization_quarterly_billion**",
    "**Description**": "Depreciation and amortization for the latest quarter",
    "**Sample Value**": "13.44",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebitda_quarterly_billion**",
    "**Description**": "EBITDA for the latest quarter",
    "**Sample Value**": "30.85",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**operatingincome_quarterly_billion**",
    "**Description**": "Operating income for the latest quarter",
    "**Sample Value**": "17.41",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalotherincomeexpensesnet_quarterly_billion**",
    "**Description**": "Total other income/expenses net for the latest quarter",
    "**Sample Value**": "0.63",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**incomebeforetax_quarterly_billion**",
    "**Description**": "Income before tax for the latest quarter",
    "**Sample Value**": "18.04",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**workingcapital_billion**",
    "**Description**": "Working capital for the latest quarter",
    "**Sample Value**": "14.31",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalassets_billion**",
    "**Description**": "Total assets for the latest quarter",
    "**Sample Value**": "584.63",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**retainedearnings_billion**",
    "**Description**": "Retained earnings for the latest quarter",
    "**Sample Value**": "152.86",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**ebit_billion**",
    "**Description**": "Earnings Before Interest and Taxes (EBIT)",
    "**Sample Value**": "60.18",
    "**Measurement Unit**": "Billion $",
  },
  {
    "**Column Name**": "**totalliabilities_billion**",
    "**Description**": "Total liabilities for the latest quarter",
    "**Sample Value**": "325.48",
    "**Measurement Unit**": "Billion $",
  },
];

const defaultData = new Array(20).fill(1).map((_, index) => ({
  id: (Date.now() + index).toString(),
  year: `202${index}`,
  revenue: "1000",
  ebit: "100",
  taxRate: 10,
  capex: "50",
  nwc: "20",
  depreciation: "30",
}));

export const NewsCarousel = ({ width, height, symbol }) => {
  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading] = useState(true);

  function truncateTitle(title, numWords) {
    const words = title.split(" ");
    if (words.length > numWords) {
      const truncatedWords = words.slice(0, numWords);
      return truncatedWords.join(" ") + "...";
    }
    return title;
  }

  useEffect(() => {
    const fetchNews = async () => {
      const apiUrl =
        "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";
      const inputSymbols = Array.isArray(symbol) ? symbol.join(",") : symbol;

      try {
        const response = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "stock_news",
            symbols: inputSymbols,
            params: "page=0",
          },
        });

        const data = JSON.parse(response.data.body);

        // Deduplicate news based on URL
        const uniqueNews = [];
        const seenURLs = new Set();
        data.forEach((news) => {
          if (!seenURLs.has(news.url)) {
            uniqueNews.push(news);
            seenURLs.add(news.url);
          }
        });

        setCombinedData(uniqueNews.slice(0, 20));
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchNews();
  }, [symbol]);

  const swiperParams = {
    spaceBetween: 20,
    navigation: true,
    breakpoints: {
      576: {
        slidesPerView: 1.2, // Show 1 card per slide for screens with width >= 576px (small mobile devices)
      },
      992: {
        slidesPerView: 2.6, // Show 3 cards per slide for screens with width >= 992px (tablets and larger mobile devices)
      },
    },
  };

  return (
    <div style={{ width, height, marginTop: "2%", marginBottom: "2%" }}>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div style={{ marginBottom: "2%", height: 350 }}>
          <ProCard title="Latest News" bordered>
            <Swiper {...swiperParams} navigation={true} modules={[Navigation]}>
              {combinedData.map((card, index) => {
                const truncatedTitle = truncateTitle(card.title, 18); // Function to truncate the title

                return (
                  <SwiperSlide key={index}>
                    <ProCard
                      bordered
                      hoverable
                      style={{
                        height: "300px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          height: "150px",
                          overflow: "hidden",
                          borderRadius: "8px 8px 0 0",
                        }}
                      >
                        <img
                          src={card.image}
                          alt={card.title}
                          style={{
                            height: "100%",
                            objectFit: "cover",
                            width: "100%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: "8px 16px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          flexGrow: 1,
                        }}
                      >
                        <a
                          href={card.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {truncatedTitle}
                          </p>
                        </a>
                      </div>
                    </ProCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </ProCard>
        </div>
      )}
    </div>
  );
};

const CompanyChartsSingle = ({ symbols }) => {
  const [chartDataQuarterly, setChartDataQuarterly] = useState([]);
  const [chartDataAnnual, setChartDataAnnual] = useState([]);
  const [chartDataPrice, setChartDataPrice] = useState([]);
  const [filteredPriceData, setFilteredPriceData] = useState([]);
  const [priceFilter, setPriceFilter] = useState("1 year");
  const [metric, setMetric] = useState("revenue");
  const [viewMode, setViewMode] = useState("Quarter");
  const [chartData, setChartData] = useState([]);
  const [loadingPrice, setLoadingPrice] = useState(true); // Loading state for price data
  const [loading, setLoading] = useState(true); // General loading state

  const priceFilterOptions = {
    "5 days": 5,
    "1 month": 20,
    "6 months": 130,
    "1 year": 275,
    "5 years": 1500,
  };

  const calculatePercentageChange = (filteredData) => {
    if (!filteredData || filteredData.length < 2) return "0%"; // Handle edge cases

    // Ensure the data is sorted in reverse chronological order (most recent to oldest)
    const sortedData = [...filteredData].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    const startPrice = sortedData[sortedData.length - 1]?.price; // Oldest date's price (last in sorted array)
    const endPrice = sortedData[0]?.price; // Most recent date's price (first in sorted array)

    if (!startPrice || !endPrice) return "0%"; // Handle cases with missing data

    const percentageChange = ((endPrice - startPrice) / startPrice) * 100;

    return `${percentageChange > 0 ? "+" : ""}${percentageChange.toFixed(2)}%`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl =
        "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";

      try {
        // Fetch quarterly results
        const responseQuarterly = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "income-statement",
            symbols: symbols[0],
            params: "period=quarter&limit=12",
          },
        });

        const resultQuarterly = JSON.parse(responseQuarterly.data.body);
        const formattedQuarterlyData = resultQuarterly
          .map((entry) => ({
            symbol: symbols[0],
            date: entry.fillingDate,
            calendarYear: entry.calendarYear + " " + entry.period,
            revenue: parseFloat((entry.revenue / 1e9).toFixed(1)),
            netIncome: parseFloat((entry.netIncome / 1e9).toFixed(1)),
            eps: parseFloat(entry.eps.toFixed(1)),
            grossProfit: parseFloat((entry.grossProfit / 1e9).toFixed(1)),
          }))
          ?.sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort in ascending order by date;

        setChartDataQuarterly(formattedQuarterlyData);

        // Fetch annual results
        const responseAnnual = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "income-statement",
            symbols: symbols[0],
            params: "period=annual&limit=8",
          },
        });

        const resultAnnual = JSON.parse(responseAnnual.data.body);
        const formattedAnnualData = resultAnnual
          .map((entry) => ({
            symbol: symbols[0],
            date: entry.fillingDate,
            calendarYear: entry.calendarYear,
            revenue: parseFloat((entry.revenue / 1e9).toFixed(1)),
            netIncome: parseFloat((entry.netIncome / 1e9).toFixed(1)),
            eps: parseFloat(entry.eps.toFixed(1)),
            grossProfit: parseFloat((entry.grossProfit / 1e9).toFixed(1)),
          }))
          ?.sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort in ascending order by date;
        setChartDataAnnual(formattedAnnualData);

        // Fetch price data
        const responsePrice = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "historical-chart/1day",
            symbols: symbols[0],
          },
        });

        const resultPrice = JSON.parse(responsePrice.data.body);
        const formattedPriceData = resultPrice
          .map((entry) => ({
            symbol: symbols[0],
            date: new Date(entry.date),
            price: entry.close,
          }))
          ?.sort((a, b) => b.date - a.date); // Sort in reverse chronological order;
        setChartDataPrice(formattedPriceData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setLoadingPrice(false);
      }
    };

    if (symbols) {
      fetchData();
    }
  }, [symbols]);

  useEffect(() => {
    // Switch data based on toggle
    if (viewMode === "Quarter") {
      setChartData(chartDataQuarterly);
    } else {
      setChartData(chartDataAnnual);
    }
  }, [viewMode, chartDataQuarterly, chartDataAnnual]);

  useEffect(() => {
    if (priceFilter && chartDataPrice.length > 0) {
      const filterDays = priceFilterOptions[priceFilter];
      const filteredData = chartDataPrice.slice(0, filterDays); // Filter the most recent N days
      setFilteredPriceData(filteredData);
    }
  }, [priceFilter, chartDataPrice]);

  const barConfig = {
    data: chartData,
    xField: "calendarYear",
    yField: metric,
    height: 300,
    columnStyle: {
      stroke: "#69c0ff",
      lineWidth: 2,
    },
    label: {
      style: {
        fill: "#000",
        fontSize: 12,
      },
      textBaseline: "bottom",
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      label: {
        formatter: (v) => `${v.toFixed(1)} B`,
      },
    },
    style: {
      radiusTopLeft: 10,
      radiusTopRight: 10,
    },
  };

  const lineConfig = {
    data: filteredPriceData,
    xField: "date",
    yField: "price",
    height: 300,
    color: "#69c0ff",
    smooth: true, // Makes the line smoother
    style: {
      stroke: "green",
      lineWidth: 2,
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      title: {
        text: "Price ($)", // Adds title to the y-axis
        style: {
          fontSize: 14,
          fontWeight: "bold",
          fill: "#333",
        },
      },
      label: {
        formatter: (v) => `$${v.toFixed(2)}`,
      },
    },
  };

  const buttonStyle = {
    padding: "1% 1%",
    margin: "0 1%",
    fontSize: "small",
    cursor: "pointer",
    backgroundColor: "#f0f0f0",
    border: "1px solid #ccc",
    borderRadius: "5%",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#B5EAD7", // Soft pastel green
    color: "#4A4A4A", // Neutral dark gray for good contrast
    border: "2px solid #9ADBB7", // Slightly darker pastel green for the border
    boxShadow: "0px 4px 10px rgba(154, 219, 183, 0.4)", // Subtle green shadow for depth
    padding: "1% 1%",
    borderRadius: "5%", // Rounded corners for a modern feel
    margin: "0 1%",
  };

  const priceButtonStyle = (isActive) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    margin: "0 3px",
    borderRadius: "12px",
    border: isActive ? "2px solid #4CAF50" : "1px solid #ddd",
    backgroundColor: isActive ? "#E8F8E8" : "#f9f9f9",
    color: isActive ? "#4CAF50" : "#555",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "all 0.3s ease",
    boxShadow: isActive ? "0 4px 10px rgba(76, 175, 80, 0.2)" : "none",
    minWidth: "90px",
    textAlign: "center",
  });

  const percentChangeStyle = (isPositive) => ({
    marginTop: "5px",
    fontSize: "12px",
    fontWeight: "bold",
    color: isPositive ? "green" : "red",
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth < 768; // Define mobile width breakpoint

  // Define mobile-specific filters if needed, or choose which to display
  const mobilePriceFilters = ["1 month", "1 year", "5 years"]; // Assuming these are the keys you want to show on mobile

  const displayedFilters = isMobile
    ? mobilePriceFilters
    : Object.keys(priceFilterOptions);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>
          Key Metrics for{" "}
          <a
            href={`/${symbols[0]}`}
            style={{
              color: "#0073e6",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {symbols[0]}
          </a>
        </h2>
        <div
          style={{
            display: "flex",
            gap: "2%",
            marginTop: "2%",
            marginRight: "5%",
            padding: "1%",
          }}
        >
          <button
            onClick={() => setViewMode("Quarter")}
            style={viewMode === "Quarter" ? activeButtonStyle : buttonStyle}
          >
            Quarter
          </button>
          <button
            onClick={() => setViewMode("Annual")}
            style={viewMode === "Annual" ? activeButtonStyle : buttonStyle}
          >
            Annual
          </button>
        </div>
      </div>
      <div>
        <button
          onClick={() => setMetric("revenue")}
          style={metric === "revenue" ? activeButtonStyle : buttonStyle}
        >
          Revenues
        </button>
        <button
          onClick={() => setMetric("netIncome")}
          style={metric === "netIncome" ? activeButtonStyle : buttonStyle}
        >
          Net Income
        </button>
        <button
          onClick={() => setMetric("grossProfit")}
          style={metric === "grossProfit" ? activeButtonStyle : buttonStyle}
        >
          Gross Income
        </button>
        <button
          onClick={() => setMetric("eps")}
          style={metric === "eps" ? activeButtonStyle : buttonStyle}
        >
          EPS
        </button>
      </div>
      <BarChartPlot {...barConfig} />
      <h2>Stock Price of {symbols[0]}</h2>
      <div style={{ display: "flex", gap: "2%", marginTop: "2%" }}>
        {/* {Object.keys(priceFilterOptions).map((filter) => {
          const filteredData = chartDataPrice.slice(
            -priceFilterOptions[filter]
          ); */}
        {displayedFilters.map((filter) => {
          const filteredData = chartDataPrice.slice(
            -priceFilterOptions[filter]
          );
          const percentageChange = calculatePercentageChange(
            filteredData?.sort((a, b) => new Date(a.date) - new Date(b.date))
          );
          const isPositive = !percentageChange.includes("-");
          return (
            <div
              key={filter}
              onClick={() => setPriceFilter(filter)}
              style={priceButtonStyle(priceFilter === filter)}
            >
              <div>{filter}</div>
              <div style={percentChangeStyle(isPositive)}>
                {percentageChange}
              </div>
            </div>
          );
        })}
      </div>
      <div>
        {loadingPrice ? (
          <Spin size="large" tip="Loading price data..." />
        ) : (
          <LineChartPlot {...lineConfig} />
        )}
      </div>{" "}
      {isMobile ? null : <DemoScatter symbol={symbols[0]} />}
    </div>
  );
};

export const CompanyChartsMultiple = ({ symbols }) => {
  const [chartDataQuarterly, setChartDataQuarterly] = useState([]);
  const [chartDataAnnual, setChartDataAnnual] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartDataPrice, setChartDataPrice] = useState([]);
  const [metric, setMetric] = useState("revenue");
  const [xAxisPeriods, setXAxisPeriods] = useState([]);
  const [viewMode, setViewMode] = useState("absolute"); // "absolute" or "% change"
  const [periodMode, setPeriodMode] = useState("quarter");

  // console.log(symbols);

  const sortFinancialData = (data) => {
    return data.sort((a, b) => {
      const [aPeriod, aYear] = a.calendarYear.split(" ");
      const [bPeriod, bYear] = b.calendarYear.split(" ");

      const quarterMap = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };

      const getSortValue = (period, year) => {
        if (quarterMap[period]) {
          // For quarterly data, calculate value with quarter weighting
          return parseInt(year) * 10 + quarterMap[period];
        } else {
          // For annual data, prioritize the year only
          return parseInt(year) * 10;
        }
      };

      const aValue = getSortValue(aPeriod, aYear);
      const bValue = getSortValue(bPeriod, bYear);

      return aValue - bValue;
    });
  };

  // Sort the processed data

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl =
        "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";

      try {
        // Fetch quarterly and annual financial data for each symbol
        const quarterlyPromises = symbols.map((symbol) =>
          axios.post(apiUrl, {
            queryStringParameters: {
              endpoint: "income-statement",
              symbols: symbol,
              params: "period=quarter&limit=8",
            },
          })
        );

        const annualPromises = symbols.map((symbol) =>
          axios.post(apiUrl, {
            queryStringParameters: {
              endpoint: "income-statement",
              symbols: symbol,
              params: "period=annual&limit=8",
            },
          })
        );

        const [quarterlyResults, annualResults] = await Promise.all([
          Promise.all(quarterlyPromises),
          Promise.all(annualPromises),
        ]);

        const processData = (results) => {
          return results.flatMap((response, index) => {
            const data = JSON.parse(response.data.body);
            const symbol = symbols[index];

            return data.map((entry, i, arr) => {
              const year = parseInt(entry.fillingDate.slice(0, 4), 10);
              const period = entry.period || "annual";

              // Get previous entry to calculate % change
              const previousEntry = arr[i + 1];

              const calculateChange = (current, previous) => {
                return previous
                  ? parseFloat(
                      (((current - previous) / previous) * 100).toFixed(2)
                    )
                  : 0;
              };

              return {
                symbol,
                calendarYear: `${period} ${year}`,
                revenue: parseFloat((entry.revenue / 1e9).toFixed(2)),
                revenueChange: previousEntry
                  ? calculateChange(entry.revenue, previousEntry.revenue)
                  : 0,
                netIncome: parseFloat((entry.netIncome / 1e9).toFixed(2)),
                netIncomeChange: previousEntry
                  ? calculateChange(entry.netIncome, previousEntry.netIncome)
                  : 0,
                grossProfit: parseFloat((entry.grossProfit / 1e9).toFixed(2)),
                grossProfitChange: previousEntry
                  ? calculateChange(
                      entry.grossProfit,
                      previousEntry.grossProfit
                    )
                  : 0,
                eps: parseFloat(entry.eps.toFixed(2)),
                epsChange: previousEntry
                  ? calculateChange(entry.eps, previousEntry.eps)
                  : 0,
              };
            });
          });
        };

        const formattedQuarterlyData = sortFinancialData(
          processData(quarterlyResults)
        );
        const formattedAnnualData = sortFinancialData(
          processData(annualResults)
        );

        setChartDataQuarterly(formattedQuarterlyData);
        setChartDataAnnual(formattedAnnualData);
        setChartData(formattedQuarterlyData); // Default view set to quarterly
        setXAxisPeriods(formattedQuarterlyData.map((d) => d.calendarYear));

        // Fetch price data for each symbol
        const pricePromises = symbols.map((symbol) =>
          axios.post(apiUrl, {
            queryStringParameters: {
              endpoint: "historical-chart/1day",
              symbols: symbol,
            },
          })
        );

        const priceResults = await Promise.all(pricePromises);
        const formattedPriceData = priceResults.flatMap((response, index) => {
          const data = JSON.parse(response.data.body);
          const symbol = symbols[index];

          return data
            .map((entry) => ({
              symbol,
              date: new Date(entry.date).toISOString().split("T")[0],
              price: parseFloat(entry.close.toFixed(2)),
            }))
            .sort((a, b) => new Date(a.date) - new Date(b.date));
        });

        setChartDataPrice(formattedPriceData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [symbols]);

  const handlePeriodChange = (period) => {
    if (period === "quarter") {
      setChartData(chartDataQuarterly);
      setXAxisPeriods(chartDataQuarterly.map((d) => d.calendarYear));
    } else {
      setChartData(chartDataAnnual);
      setXAxisPeriods(chartDataAnnual.map((d) => d.calendarYear));
    }
    setPeriodMode(period);
  };

  // console.log(chartData);

  const barConfig = {
    data: chartData.filter((d) => xAxisPeriods.includes(d.calendarYear)),
    xField: "calendarYear",
    yField: viewMode === "absolute" ? metric : `${metric}Change`,
    height: 300,
    columnStyle: {
      stroke: "#B3E5FC",
      lineWidth: 2,
      fillOpacity: 0.3,
    },
    label: {
      textBaseline: "bottom",
      style: {
        fill: "#000",
        opacity: 0.5,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      label: {
        formatter: (v) =>
          viewMode === "absolute" ? `${v.toFixed(2)} B` : `${v.toFixed(2)}%`,
      },
    },
    tooltip: viewMode === "absolute" ? metric : `${metric}Change`,
    group: true,
    colorField: "symbol",
  };

  let latestDate = "2024-02-05";

  const lineConfig = {
    data: chartDataPrice,
    xField: "date",
    yField: "price",
    height: 300,
    style: {
      lineWidth: 1.5,
    },
    normalize: { basis: "first", groupBy: "color" },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      min: 0, // Start y-axis from 0
      label: {
        formatter: (v) => `$${v.toFixed(2)}`,
      },
    },
    tooltip: "price",
    colorField: "symbol",
  };

  const buttonStyle = {
    padding: "5px 5px",
    margin: "0 3px",
    fontSize: "13px",
    cursor: "pointer",
    backgroundColor: "#f0f0f0",
    border: "1px solid #ccc",
    borderRadius: "5px",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#69c0ff",
    color: "#fff",
    border: "1px solid #69c0ff",
  };

  // Generate table data
  const tableData = symbols
    .map((symbol) => {
      const symbolData = chartDataPrice.filter(
        (entry) => entry.symbol === symbol
      );
      if (symbolData.length === 0) return null; // Skip symbols with no data
      const startPrice = symbolData[0]?.price || 0;
      const endPrice = symbolData[symbolData.length - 1]?.price || 0;
      const percentChange = (
        ((endPrice - startPrice) / startPrice) *
        100
      ).toFixed(0);

      return {
        key: symbol,
        symbol,
        startPrice: `$${startPrice.toFixed(0)}`,
        endPrice: `$${endPrice.toFixed(0)}`,
        change: `${percentChange > 0 ? "+" : ""}${percentChange}%`,
      };
    })
    .filter(Boolean); // Remove null values

  // Table columns
  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (text) => (
        <a
          href={`/${text}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#0073e6",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Start Price",
      dataIndex: "startPrice",
      key: "startPrice",
    },
    {
      title: "End Price",
      dataIndex: "endPrice",
      key: "endPrice",
    },
    {
      title: "Change %",
      dataIndex: "change",
      key: "change",
      render: (text) => (
        <span style={{ color: text.includes("-") ? "red" : "green" }}>
          {text}
        </span>
      ),
    },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth < 768; // Define mobile width breakpoint

  return (
    <div>
      <div style={{ marginBottom: "1%" }}>
        <h2 style={{ margin: 0 }}>Key Metric</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "flex-start" : "space-between", // Align items to the ends on desktop
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1%",
            marginBottom: "1%",
            marginTop: "1%",
            alignItems: "center",
            flexWrap: "nowrap", // Prevent wrapping of content
          }}
        >
          <button
            onClick={() => setMetric("revenue")}
            style={metric === "revenue" ? activeButtonStyle : buttonStyle}
          >
            Revenues
          </button>
          <button
            onClick={() => setMetric("netIncome")}
            style={metric === "netIncome" ? activeButtonStyle : buttonStyle}
          >
            Income
          </button>
          <button
            onClick={() => setMetric("grossProfit")}
            style={{
              ...(metric === "grossProfit" ? activeButtonStyle : buttonStyle),
              minWidth: "110px", // Enough to keep "Gross Income" in one line
            }}
          >
            Gross Income
          </button>
          <button
            onClick={() => setMetric("eps")}
            style={metric === "eps" ? activeButtonStyle : buttonStyle}
          >
            EPS
          </button>
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginTop: "1%",
            flexWrap: "nowrap", // Ensure these buttons also do not wrap
          }}
        >
          <button
            onClick={() => setViewMode("absolute")}
            style={viewMode === "absolute" ? activeButtonStyle : buttonStyle}
          >
            Absolute
          </button>
          <button
            onClick={() => setViewMode("% change")}
            style={viewMode === "% change" ? activeButtonStyle : buttonStyle}
          >
            % Change
          </button>
        </div>
      </div>

      {/* Quarter/Annual Toggle Buttons */}
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginTop: "1%",
          flexWrap: "nowrap", // Ensure these buttons also do not wrap
        }}
      >
        <button
          onClick={() => handlePeriodChange("quarter")}
          style={periodMode === "quarter" ? activeButtonStyle : buttonStyle}
        >
          Quarter
        </button>
        <button
          onClick={() => handlePeriodChange("annual")}
          style={periodMode === "annual" ? activeButtonStyle : buttonStyle}
        >
          Annual
        </button>
      </div>

      {/* Bar Chart */}
      <div style={{ marginTop: "20px", position: "relative" }}>
        <BarChartPlot {...barConfig} />
      </div>

      <Row
        gutter={[16, 16]}
        style={{
          marginTop: "20px",
          flexDirection: isMobile ? "column" : "row", // Stack items vertically on mobile
        }}
      >
        {/* Line Chart (Stock Price) */}
        <Col
          span={isMobile ? 24 : 16}
          style={{
            marginBottom: isMobile ? "20px" : "0", // Add spacing between components on mobile
          }}
        >
          <h2>Stock Price</h2>
          <LineChartPlot {...lineConfig} />
        </Col>

        {/* Table (Price Summary) */}
        <Col
          span={isMobile ? 24 : 8}
          style={{
            backgroundColor: "#f9f9f9",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "15px",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "600",
              color: "#333",
              marginBottom: "15px",
            }}
          >
            Price Summary
          </h3>
          <Table
            dataSource={tableData}
            columns={columns}
            pagination={false}
            bordered
            size="small"
            style={{
              borderRadius: "8px",
              overflow: "hidden",
            }}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
          />
        </Col>
      </Row>

      {/* Line Chart */}
      {/* <div>
        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
         
          <Col span={16}>
            <h2>Stock Price</h2>
            <LineChartPlot {...lineConfig} />
          </Col>

         
          <Col
            span={8}
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              padding: "15px",
            }}
          >
            <h3
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "600",
                color: "#333",
                marginBottom: "15px",
              }}
            >
              Price Summary
            </h3>
            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
              bordered
              size="small"
              style={{
                borderRadius: "8px",
                overflow: "hidden",
              }}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
            />
          </Col>
        </Row>
      </div> */}
    </div>
  );
};

export const CompanyCharts = ({ symbols, subrouter }) => {
  // console.log(subrouter)
  if (subrouter === "single company analysis" && symbols.length === 1) {
    return <CompanyChartsSingle symbols={symbols} />;
  } else if (
    subrouter === "multiple companies analysis" &&
    symbols.length > 1
  ) {
    return <CompanyChartsMultiple symbols={symbols} />;
  } else if (subrouter === "fair valuation") {
    return <DCFFCompany symbol={symbols[0]} />;
    // } else if (subrouter === "industry analysis") {
    //   return (
    //     <div style={{ color: "brown" }}>
    //       CHARTS-Work In progress for Industry analysis, Please check back in few
    //       weeks
    //     </div>
    //   );
    // } else {
    // return <div>No data available for the given query.</div>;
  }
};

export default CompanyCharts;

export const ChartsAndTables = ({ input_symbols, sql_columns }) => {
  // console.log(input_symbols);
  const [chartData, setChartData] = useState([]);
  const [chartDataPrice, setChartDataPrice] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  // const [symbols, setSymbols] = useState('TSLA,MSFT,AMZN,INTC,A,AMD,NVDA,GOOG,NFLX');
  const [symbols, setSymbols] = useState(input_symbols?.split(","));

  // console.log(symbols)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log(sql_columns);

  const requiredColumns = [
    "market_cap_billion",
    "price_latest",
    "revenue_change_annual",
    "revenue_quarterly_billion",
    "netincome_quarterly_billion",
    "operatingprofitmargin",
    "eps",
    "pe",
    "dividendyield",
    "companyname",
    "analyst_price_target_consensus",
  ];

  const columnDisplayNames = {
    price_latest: "Price",
    companyname: "Company",
    revenue_change_annual: "Rev Change-A",
    revenue_quarter_billion: "Revenues-Q",
    netincome_quarterly_billion: "Net Income-Q",
    operatingprofitmargin: "Op. Profit Margin-Q",
    eps: "EPS",
    dividendyielttm: "Dividend Yield*",
    dcf: "DCF",
    market_cap_billion: "Market Cap(Bn.)",
    pe: "PE",
    targetconsensus: "Analyst Targets",
  };

  const columnDefinitions = columnDefinitionsArray.reduce((acc, item) => {
    const columnName = item["**Column Name**"]?.replace(/\*\*/g, "").trim();
    const description = item["**Description**"]?.trim();
    const sampleValue = item["**Sample Value**"]?.trim();
    const measurementUnit = item["**Measurement Unit**"]?.trim();

    if (columnName) {
      // Combine Description, Sample Value, and Measurement Unit into a single formatted string
      acc[columnName] = `${description || "N/A"} e.g ${
        sampleValue || "N/A"
      }, Measured as "${measurementUnit || "N/A"}"`;
    }
    return acc;
  }, {});

  const completeSqlColumns = () => {
    let columns = sql_columns
      ?.split(",")
      ?.filter((col) => col !== "targetconsensus");
    requiredColumns?.forEach((col) => {
      if (columns?.length < 50 && !columns?.includes(col)) {
        columns?.push(col);
      }
    });
    columns = columns?.slice(0, 50);

    return columns;
  };

  const finalColumns = completeSqlColumns();
  // console.log(finalColumns);

  const capitalize = (s) => {
    return s.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
  };

  const fetchData = async (symbols) => {
    try {
      const sql_data = await fetch(
        "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            text: symbols,
            columns: finalColumns,
            model_name: "SQLDataTable",
          }),
        }
      );

      if (!sql_data.ok) {
        throw new Error("Failed to fetch SQL data");
      }

      let result_sql = await sql_data.json();
      if (result_sql) {
        result_sql = result_sql?.summary;
        let tableDataSQL = result_sql?.map((entry) => {
          let rowData = { image: entry.image };
          completeSqlColumns()?.forEach((col) => {
            rowData[col] =
              typeof entry[col] === "number"
                ? entry[col]?.toFixed(2)
                : entry[col];
          });
          return rowData;
        });

        setTableData2(tableDataSQL);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTableLoading(false); // Set loading state to false regardless of success or failure
    }
  };

  useEffect(() => {
    fetchData(symbols);
  }, [symbols]);

  const renderColumns = () => {
    const columns = [
      ...(!isMobile
        ? [
            {
              title: "",
              dataIndex: "image",
              key: "image",
              fixed: "left",
              width: 50,
              render: (image) => (
                <img
                  src={image}
                  alt="company-logo"
                  style={{ width: 30, height: 30 }}
                />
              ),
            },
          ]
        : []),
      {
        title: "Symbol",
        dataIndex: "symbol",
        key: "symbol",
        fixed: "left",
        width: 90,

        render: (symbol) => (
          <Link
            to={`/${symbol}`}
            style={{
              display: "inline-block", // Block for containing relative elements
              color: "#0073e6", // Blue color for the link
              textDecoration: "underline", // Standard underline for links
              fontWeight: "bold", // Bold text for emphasis
              fontSize: "14px", // Slightly larger font size for visibility
              position: "relative", // Enable absolute positioning for children
              paddingRight: "20px", // Add space for the arrow
              transition: "all 0.3s ease", // Smooth transition for hover effects
            }}
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // For security
            onMouseEnter={(e) => {
              e.target.style.color = "#005bb5"; // Darker blue on hover
              e.target.style.textDecoration = "none"; // Remove underline on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "#0073e6"; // Revert to original blue color
              e.target.style.textDecoration = "underline"; // Restore underline
            }}
          >
            {symbol}
            <i
              style={{
                position: "absolute",
                right: "0", // Fix to the right edge of the container
                top: "50%", // Vertically center the arrow
                transform: "translateY(-50%)", // Adjust for proper centering
                fontSize: "14px", // Match font size
                color: "#0073e6", // Matches the link color
                transition: "transform 0.3s ease", // Smooth transition
              }}
              className="arrow-icon"
            >
              ➡️
            </i>
          </Link>
        ),
      },
    ];

    completeSqlColumns()
      ?.filter((column) => column !== "symbol")
      ?.forEach((column, index) => {
        columns.push({
          title: (
            <Tooltip
              title={columnDefinitions[column] || "No description available"}
            >
              <div
                style={{
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                  fontWeight: "bold",
                  fontSize: "small",
                  color: "black",
                }}
              >
                {columnDisplayNames[column] || capitalize(column)}
              </div>
            </Tooltip>
          ),
          dataIndex: column,
          key: column,
          fixed: index === 0 && !isMobile ? "left" : false,

          width: isMobile ? "25vw" : "12vw", // Conditional width based on isMobile

          sorter: (a, b) => (a[column] > b[column] ? 1 : -1),
          showSorterTooltip: false,
        });
      });

    return columns;
  };

  const top3Symbols = input_symbols?.split(",")?.slice(0, 3); // Top 3 symbols for filters

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData2);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "TableData.xlsx");
  };

  if (isMobile) {
    return (
      <ProCard
        bordered
        title={
          <div>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Table Based on SQL Query
            </Typography.Title>
            <Typography.Text type="secondary">
              Click on Symbol for detailed company analysis
            </Typography.Text>
          </div>
        }
        extra={
          <Button onClick={downloadExcel}>
            <DownloadOutlined />
          </Button>
        }
      >
        {" "}
        <Table
          dataSource={tableData2}
          loading={tableLoading}
          pagination={{ pageSize: 6 }}
          size="small"
          scroll={{ x: 800 }}
          bordered
          columns={renderColumns()}
        ></Table>
        <Divider />
        <div>
          <CompanyChartsMultiple symbols={top3Symbols} />
        </div>
      </ProCard>
    );
  } else {
    // Desktop layout
    return (
      <ProCard
        bordered
        title={
          <div>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Table Based on SQL Query
            </Typography.Title>
            <Typography.Text type="secondary">
              Click on Symbol for detailed company analysis
            </Typography.Text>
          </div>
        }
        extra={
          <Tooltip title="Download Table">
            <Button onClick={downloadExcel}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
        }
      >
        <Table
          dataSource={tableData2}
          loading={tableLoading}
          pagination={{ pageSize: 6 }}
          size="small"
          scroll={{ x: 1200 }}
          bordered={false}
          columns={renderColumns()}
          style={{ margin: "0px", padding: "0px" }} // Minimal margins and padding
        ></Table>
        <div>
          <CompanyChartsMultiple symbols={top3Symbols} />
        </div>
      </ProCard>
    );
  }
};
