import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";
import { Typography, Spin, Divider } from "antd";

const { Title, Paragraph } = Typography;

const BlogDetail = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await fetch(
          `https://public-api.wordpress.com/wp/v2/sites/19aditiyadav.wordpress.com/posts?slug=${slug}`
        );
        const data = await response.json();
        setBlog(data[0]);
      } catch (error) {
        console.error("Error fetching blog details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetail();
  }, [slug]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!blog) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Title level={2} style={{ color: "#888" }}>
          Blog not found
        </Title>
        <Link to="/blogs" style={{ fontSize: "16px", color: "#1890ff" }}>
          ← Back to Blogs
        </Link>
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "50px auto",
        padding: "40px",
        backgroundColor: "#fff",
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        borderRadius: "10px",
      }}
    >
      <Helmet>
        <title>
          {blog.title.rendered.replace(/&nbsp;/g, " ")} | Stockbuzz.ai
        </title>
        <meta
          name="description"
          content={blog.excerpt.rendered
            .replace(/<[^>]+>/g, "")
            .replace(/&nbsp;/g, " ")}
        />
        <meta
          name="keywords"
          content="AI stock analysis, stock valuation, investment insights, finance, investing, stock market"
        />
        <meta
          property="og:title"
          content={blog.title.rendered.replace(/&nbsp;/g, " ")}
        />
        <meta
          property="og:description"
          content={blog.excerpt.rendered
            .replace(/<[^>]+>/g, "")
            .replace(/&nbsp;/g, " ")}
        />
        <meta
          property="og:image"
          content={
            blog.jetpack_featured_media_url ||
            "https://www.stockbuzz.ai/Stockbuzz.png"
          }
        />
        <meta property="og:url" content={blog.link} />
        <meta property="og:type" content="article" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: blog.title.rendered.replace(/&nbsp;/g, " "),
            description: blog.excerpt.rendered
              .replace(/<[^>]+>/g, "")
              .replace(/&nbsp;/g, " "),
            image:
              blog.jetpack_featured_media_url ||
              "https://www.stockbuzz.ai/Stockbuzz.png",
            url: blog.link,
            author: {
              "@type": "Person",
              name: "Stockbuzz.ai",
            },
            publisher: {
              "@type": "Organization",
              name: "Stockbuzz.ai",
              logo: {
                "@type": "ImageObject",
                url: "https://www.stockbuzz.ai/Stockbuzz.png",
              },
            },
            datePublished: blog.date,
          })}
        </script>
      </Helmet>

      <Title level={1} style={{ textAlign: "center", color: "#333" }}>
        <span dangerouslySetInnerHTML={{ __html: blog.title.rendered }} />
      </Title>

      <Divider />

      <Paragraph
        style={{
          fontSize: "18px",
          lineHeight: "1.8",
          color: "#444",
          textAlign: "justify",
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: blog.content.rendered }} />
        <style>
          {`
            .wp-block-image img {
              max-width: 100%;
              height: auto;
              display: block;
              margin: 20px auto;
              border-radius: 10px;
            }
          `}
        </style>
      </Paragraph>

      <Divider />

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Link
          to="/blogs"
          style={{
            fontSize: "16px",
            color: "#fff",
            backgroundColor: "#1890ff",
            padding: "10px 20px",
            borderRadius: "5px",
            textDecoration: "none",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          ← Back to Blogs
        </Link>
      </div>
    </div>
  );
};

export default BlogDetail;
