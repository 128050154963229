import React, { useState, useEffect, useRef } from "react";
import { Layout, Input, Button, Alert, Menu, Dropdown, Tooltip } from "antd";
import {
  ArrowUpOutlined,
  DownCircleTwoTone,
  StopOutlined,
} from "@ant-design/icons";
import { readApiThreads, writeApi } from "./UserDataDynamoDB";
import { SamplePrompts, generateRandomId, manageCookie } from "./SamplePrompts";
import MainChat from "./MainChat";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import { SideBarComponent, HeaderComponent, BottomLine } from "./SideBar";
import { AboutUs, Contact } from "./Footer";
import BlogList from "./Blog/BlogList";
import BlogDetail from "./Blog/BlogDetail";
import Login from "./components/Login";
import Signup from "./components/SignUp";
import Metrics from "./Metrics";
import { useAuth } from "./AuthContext";
import { auth } from "./firebase"; // Import the auth object
import ResetPassword from "./components/ResetPassword"; // Import the ResetPassword component
import { useWelcome } from "./components/WelcomeContext"; // Import the welcome context
import CompanyPage from "./CompanyPage";
import { PoweroffOutlined } from "@ant-design/icons";
import DiscoverPage from "./DiscoverPage";
import Discover1 from "./Discover1";

const App = () => {
  const [loading, setLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [skeletonLLMLoading, setSkeletonLLMLoading] = useState(false);
  const [threads, setThreads] = useState([]);
  const [activeThread, setActiveThread] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [newChatName, setNewChatName] = useState("");
  const [newChat, setNewChat] = useState(1);
  const [responseTime, setResponseTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [siderVisible, setSiderVisible] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [currentStep, setCurrentStep] = useState(-1);

  const messagesEndRef = useRef(null); // Define messagesEndRef
  const { currentUser } = useAuth();
  const { welcomeMessage, setWelcomeMessage } = useWelcome(); // Use the welcome context

  const [showSamplePrompts, setShowSamplePrompts] = useState(true);
  const [InterruptWrite, setInterruptWrite] = useState(false);

  const [regenerate, setRegenerate] = useState(false);
  const [regenerateMessage, setRegenerateMessage] = useState("");

  const [userIdentifier, setUserIdentifier] = useState("");
  const [user_value_cookie, setuser_value_cookie] = useState("");

  // console.log("currentUser:", currentUser?.email);

  const getUserLabel = (email) => {
    if (!email) return "";
    // Check if the first character is a digit, if so, return the first two digits
    if (!isNaN(email[0])) {
      return email?.slice(0, 2).replace(/[^0-9]+/, "");
    }
    // Otherwise, return the first letter
    return email[0].toUpperCase();
  };

  useEffect(() => {
    const storedEmail = currentUser?.email;
    const cookieID = manageCookie();

    if (storedEmail) {
      // console.log(
      //   "Using stored email concatenated with cookie ID:",
      //   storedEmail + "@@@" + cookieID
      // );
      setUserIdentifier(cookieID + "@@@" + storedEmail); // Concatenate and use the stored email with cookie ID
      setuser_value_cookie(storedEmail); // Concatenate and use the stored email with cookie ID
    } else {
      // console.log(
      //   "No email found in local storage. Using cookie ID:",
      //   cookieID
      // );
      setUserIdentifier(cookieID); // Concatenate and use the stored email with cookie ID
      setuser_value_cookie(cookieID); // Use just the cookie ID if no email is stored
    }
  }, [currentUser]);

  useEffect(() => {
    if (userIdentifier) {
      // console.log(

      //   "Calling readApiThreads with userIdentifier:",

      //   userIdentifier

      // );

      readApiThreads(
        userIdentifier,

        setLoading,

        setThreads,

        setActiveThread,

        setNewChat,

        activeThread
      );
    }
  }, [userIdentifier]); // Dependency on userIdentifier

  // Define the dropdown menu
  const menu = (
    <Menu style={{ width: 200 }}>
      <Menu.Item
        key="logout"
        onClick={() => auth.signOut()}
        icon={
          <PoweroffOutlined style={{ color: "grey", fontWeight: "bold" }} />
        }
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (welcomeMessage) {
      const timer = setTimeout(() => {
        setWelcomeMessage("");
      }, 3000); // 10 seconds

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or welcomeMessage changes
    }
  }, [welcomeMessage, setWelcomeMessage]);

  useEffect(() => {
    const handleResize = () => {
      const isMobileScreen = window.innerWidth <= 768;
      setIsMobile(isMobileScreen);
      setSiderVisible(!isMobileScreen); // Ensure Sider is visible on larger screens
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially to set the correct state based on the initial screen size
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();

  const toggleSider = () => {
    setSiderVisible(!siderVisible);
  };

  const handleStreamingStop = async () => {
    // console.log("newMessage", newMessage);
    setInterruptWrite(!InterruptWrite);
    setStreamActive(false);
  };
  const handleSendMessage = async () => {
    setStreamActive(true);
    // console.log("newMessage", newMessage);
    // Capture the message before sending

    if (newMessage.trim() !== "") {
      setRegenerateMessage(newMessage);

      setStartTime(Date.now());
      writeApi(
        user_value_cookie,
        setLoading,
        setSkeletonLoading,
        setSkeletonLLMLoading,
        setNewChat,
        newMessage || regenerateMessage,
        activeThread,
        setActiveThread,
        newMessage
      )
        .then(() => {
          return readApiThreads(
            userIdentifier,
            setLoading,
            setThreads,
            setActiveThread,
            setNewChat,
            activeThread
          );
        })
        .then(() => {
          setNewMessage("");
          setShowSamplePrompts(false);
          setRegenerate(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleChange = (e) => {
    const message = e.target.value;
    setNewMessage(message);
    setIsActive(message.length > 0);
  };

  // const user_value_cookie = manageCookie();

  useEffect(() => {
    const defaultThread = {
      id: generateRandomId(),
      name: "",
      messages: [],
    };
    setActiveThread(defaultThread);
    setThreads([defaultThread]);
    setIsFirstMessage(defaultThread.messages.length === 0);
  }, [userIdentifier]);

  useEffect(() => {
    if (activeThread) {
      setIsFirstMessage(activeThread.messages.length === 0);
    }
  }, [activeThread]);
  // console.log(threads);

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
  };

  const handleInputChange = (e) => {
    setNewChatName(e.target.value);
  };

  useEffect(() => {
    if (activeThread && activeThread.messages.length > 0) {
      readApiThreads(
        userIdentifier,
        setLoading,
        setThreads,
        setActiveThread,
        setNewChat,
        activeThread
      );
      setShowSamplePrompts(false);
    }
  }, [activeThread]);

  // console.log('activeThread:',activeThread)
  // console.log('threads:',threads)

  useEffect(() => {
    scrollToBottom();
    checkScrollPosition();

    window.addEventListener("scroll", checkScrollPosition);
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, [activeThread]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const checkScrollPosition = () => {
    const distanceFromBottom =
      document.body.offsetHeight - (window.innerHeight + window.scrollY);
    const isAboveThreshold = distanceFromBottom > window.innerHeight * 0.6;
    const isAtBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    setShowArrow(!isAtBottom && isAboveThreshold && messagesEndRef.current);
  };

  const handleArrowClick = () => {
    scrollToBottom();
  };

  const [streamActive, setStreamActive] = useState(false);

  const handleNewChatClick = () => {
    const newChatId = generateRandomId();
    const newChatName = `Chat-${
      threads.filter((thread) => thread.id !== newChatId).length + 1
    }`;
    const newChat = {
      id: newChatId,
      name: newChatName,
      messages: [],
    };
    setActiveThread(newChat);
    setThreads([...threads, newChat]);
    setActiveButton("newChat");
    setShowSamplePrompts(true);

    setNewChat(1);
    if (isMobile) {
      setSiderVisible(false);
    }
    navigate("/");
  };

  // console.log('setRegenerate:',regenerate)

  useEffect(() => {
    // console.log('regenerateMessage:',regenerateMessage)

    if (regenerate) {
      setNewMessage(regenerateMessage);
      handleSendMessage(regenerateMessage);
      setStreamActive(false);
    }
  }, [regenerate]); // Add regenerate to the dependency array to re-run the effect when it changes

  useEffect(() => {
    if (!skeletonLoading && startTime) {
      const endTime = Date.now();
      const timeTaken = (endTime - startTime) / 1000;
      setResponseTime(timeTaken.toFixed(2));
      setStartTime(null);
    }
  }, [skeletonLoading, startTime]);

  const handleThreadClick = (thread) => {
    // console.log("Thread clicked, is mobile:", isMobile); // Debug: check mobile status on click

    setActiveThread(thread);
    setActiveButton(null);
    navigate("/");
    if (isMobile) {
      // console.log("Closing sidebar"); // Confirm this runs
      setSiderVisible(false);
    }
  };

  useEffect(() => {
    setIsActive(newMessage.trim() !== "");
    setCurrentStep(-1);
  }, [newMessage]);

  const [isEditing, setIsEditing] = useState(false);
  const [editedSQL, setEditedSQL] = useState("");

  // useEffect(() => {
  //   if (activeThread?.messages?.length > 0) {
  //     const lastSQL = activeThread.messages[0]?.response?.sql_code || "";
  //     setEditedSQL(lastSQL);
  //   }
  // }, [activeThread]);

  const handleToggleEdit = async () => {
    if (isEditing) {
      try {
        const prompt = newMessage + "@@@" + editedSQL;
        // console.log("Generated Prompt:", prompt);
        // Add your SQL execution logic here
      } catch (error) {
        console.error("Error executing the SQL and saving data:", error);
      }
    }
    setIsEditing(!isEditing);
  };

  const handleSQLChange = (event) => {
    setEditedSQL(event.target.value);
    // console.log("Updated SQL:", event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        maxWidth: "100vw",
        overflowX: "auto",
      }}
    >
      <HeaderComponent
        isMobile={isMobile}
        toggleSider={toggleSider}
        navigate={navigate}
        handleNewChatClick={handleNewChatClick}
      />
      <SideBarComponent
        isMobile={isMobile}
        siderVisible={siderVisible} // Pass this if needed inside SideBarComponent for direct manipulation
        setSiderVisible={setSiderVisible}
        navigate={navigate}
        activeButton={activeButton}
        handleNewChatClick={handleNewChatClick}
        handleThreadClick={handleThreadClick}
        handleMenuClick={handleMenuClick}
        handleInputChange={handleInputChange}
        user_value_cookie={user_value_cookie}
        activeThread={activeThread}
        newChatName={newChatName}
        setLoading={setLoading}
        setActiveThread={setActiveThread}
        threads={threads}
        currentUser={currentUser} // Pass currentUser to SideBarComponent
      />
      <Layout
        style={{
          marginLeft: isMobile ? 0 : "20%",
          background: "white",
          maxWidth: "100vw",
          overflowX: "hidden",
        }}
      >
        <nav
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            padding: "10px",
            display: "flex",
            justifyContent: "flex-end",
            background: "transparent",
            width: "80%",
            height: "6%",
            zIndex: 990,
          }}
        >
          {!currentUser && (
            <Button style={{ marginRight: "1%" }}>
              <Link to="/login" style={{ margin: "0 2%" }}>
                Log in
              </Link>
            </Button>
          )}
          {!currentUser && (
            <Button style={{ backgroundColor: "#559e83", color: "white" }}>
              {" "}
              <Link to="/signup" style={{ margin: "0 2%" }}>
                Sign up
              </Link>
            </Button>
          )}
          {/* {currentUser && (
            <Button onClick={() => auth.signOut()} style={{ margin: "0 2%" }}>
              Logout
            </Button>
          )} */}
          {currentUser && !isMobile && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                style={{
                  margin: "0 2%",
                  backgroundColor: "#559e83",
                  color: "white",
                }}
              >
                {getUserLabel(currentUser.email)}
              </Button>
            </Dropdown>
          )}
        </nav>
        {welcomeMessage && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Alert
              message={welcomeMessage}
              type="success"
              showIcon
              closable
              style={{
                marginBottom: 20,
                width: "auto",
              }}
              onClose={() => setWelcomeMessage("")}
            />{" "}
          </div>
        )}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <MainChat
                  activeThread={activeThread}
                  skeletonLoading={skeletonLoading}
                  skeletonLLMLoading={skeletonLLMLoading}
                  newMessage={newMessage}
                  setNewMessage={setNewMessage}
                  messagesEndRef={messagesEndRef}
                  streamActive={streamActive}
                  setStreamActive={setStreamActive}
                  isMobile={isMobile}
                  user_value_cookie={user_value_cookie}
                  InterruptWrite={InterruptWrite}
                  setInterruptWrite={setInterruptWrite}
                  regenerate={regenerate}
                  setRegenerate={setRegenerate}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setSkeletonLoading={setSkeletonLoading}
                  setSkeletonLLMLoading={setSkeletonLLMLoading}
                  setActiveThread={setActiveThread}
                  setLoading={setLoading}
                  setNewChat={setNewChat}
                />
                {showArrow && (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "12vh",
                      left: isMobile ? "50%" : "initial",
                      right: isMobile ? "initial" : "40%",
                      transform: isMobile
                        ? "translateX(-50%)"
                        : "translateX(60%)",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "50%",
                      cursor: "pointer",
                      zIndex: 100,
                      fontSize: "x-large",
                    }}
                    onClick={handleArrowClick}
                  >
                    <DownCircleTwoTone twoToneColor="#bfbfbf" />
                  </div>
                )}

                {showSamplePrompts && (
                  <div>
                    <SamplePrompts
                      threads={threads}
                      newChat={newChat}
                      setNewMessage={setNewMessage}
                      isMobile={isMobile}
                      isVisible={true} // Pass this based on your conditions
                    />
                  </div>
                )}
                <div
                  style={{
                    left: 300,
                    width: "calc(80% - 300px)",
                    padding: "20px 20px 1%",
                    background: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",
                      bottom: isFirstMessage ? "65%" : "5%",
                      width: isMobile ? "92%" : "79%",
                      left: isMobile ? "5%" : "20%",
                      padding: "1% 1% 1%",
                      background: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transform: isFirstMessage ? "translateY(50%)" : "none",
                      zIndex: 100,
                      overflowX: "hidden",
                    }}
                  >
                    <Input.TextArea
                      value={newMessage}
                      onChange={handleChange}
                      onPressEnter={(e) => {
                        e.preventDefault();
                        handleSendMessage();
                      }}
                      placeholder="Ask me anything"
                      autoSize={{ minRows: 1.8, maxRows: isMobile ? 2 : 12 }}
                      style={{
                        marginRight: "10px",
                        fontSize: "small",
                        flex: isMobile ? 1 : "none",
                        width: isMobile ? "80%" : "80%",
                      }}
                      disabled={skeletonLoading}
                    />
                    {streamActive ? (
                      <Tooltip title="Stop Generating">
                        <Button
                          style={{
                            backgroundColor: "black",
                            borderColor: "black",
                            color: "white",
                            marginLeft: "8px",
                          }}
                          icon={
                            <StopOutlined
                              style={{
                                color: "white",
                                fontSize: "medium",
                                fontWeight: "bolder",
                              }}
                            />
                          }
                          onClick={handleStreamingStop}
                          disabled={!streamActive}
                        />
                      </Tooltip>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: isActive ? "green" : "lightgrey",
                          borderColor: isActive ? "green" : "lightgrey",
                          color: "white",
                        }}
                        icon={
                          <ArrowUpOutlined
                            style={{
                              color: isActive ? "white" : "grey",
                              fontSize: "medium",
                              fontWeight: "bolder",
                            }}
                          />
                        }
                        onClick={handleSendMessage}
                        disabled={!isActive || skeletonLoading}
                      />
                    )}
                  </div>
                </div>
              </>
            }
          />
          <Route path="/Metrics" element={<Metrics />} />
          <Route path="/:symbol" element={<CompanyPage />} />
          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blogs/:slug" element={<BlogDetail />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/discover1"
            element={<Discover1 setNewMessage={setNewMessage} />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />{" "}
          <Route
            path="/discover"
            element={
              <DiscoverPage
                newMessage={newMessage}
                setNewMessage={setNewMessage}
                setActiveThread={setActiveThread}
                threads={threads}
                setThreads={setThreads}
                navigate={navigate}
                setStreamActive={setStreamActive}
              />
            }
          />
        </Routes>
      </Layout>{" "}
      <BottomLine isMobile={isMobile} />
    </div>
  );
};

export default App;
