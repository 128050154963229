import axios from "axios";
import MarkdownPreview from "@uiw/react-markdown-preview";
import React, { useEffect, useState } from "react";
import { Card, Progress } from "antd";
import { MathJax, MathJaxContext } from "better-react-mathjax"; // Ensure you have installed this package
import "./DetailPage.css";
import "./App.css";

export const readApiThreads = (
  user_value_cookie,
  setLoading,
  setThreads,
  setActiveThread,
  setNewChat,
  activeThread
) => {
  setLoading(true);
  setNewChat(0);
  // console.log(user_value_cookie);
  const apiUrl = "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";
  const requestData = {
    operation: "read",
    username: user_value_cookie,
  };

  axios
    .post(apiUrl, requestData)
    .then((response) => {
      const fetchedChats = response.data?.body?.chats || {};
      const threadsArray = Object.keys(fetchedChats).map((key) => ({
        id: key,
        name: fetchedChats[key].chat_name,
        messages: Object.values(fetchedChats[key].messages),
      }));

      threadsArray.forEach((thread) => {
        const uniqueMessages = {};
        thread.messages.forEach((message) => {
          const messageId = message.message_id;
          if (
            !uniqueMessages[messageId] ||
            new Date(uniqueMessages[messageId].response.question_time) <
              new Date(message.response.question_time)
          ) {
            uniqueMessages[messageId] = message;
          }
        });
        thread.messages = Object.values(uniqueMessages);
      });

      threadsArray.sort((a, b) => {
        const lastMsgA =
          a.messages.length > 0
            ? new Date(a.messages[a.messages.length - 1].response.question_time)
            : new Date(0);
        const lastMsgB =
          b.messages.length > 0
            ? new Date(b.messages[b.messages.length - 1].response.question_time)
            : new Date(0);
        return lastMsgB - lastMsgA;
      });

      setThreads(threadsArray);
      if (threadsArray.length > 0 && !activeThread) {
        setActiveThread(threadsArray[0]);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      setLoading(false);
    });
};

export const writeApi = async (
  user_value_cookie,
  setLoading,
  setSkeletonLoading,
  setSkeletonLLMLoading,
  setNewChat,
  newMessage,
  activeThread,
  setActiveThread,
  newMessageTest,
  editedQuery = null
) => {
  setLoading(true);
  setSkeletonLoading(true);
  setNewChat(0);
  // console.log(editedQuery)

  const newMessageContent = {
    message_id: `m${new Date().getTime()}`,
    message: newMessage,
    type: "question",
    response: {
      llm_response: "",
      question_time: new Date().toISOString(),
      table_data: "",
    },
  };

  let updatedMessages;
  if (activeThread?.messages) {
    updatedMessages = [...activeThread.messages, newMessageContent];
  } else {
    updatedMessages = [newMessageContent];
  }

  setActiveThread({ ...activeThread, messages: updatedMessages });

  try {
    const max_tokens_llm = 100;
    const max_tokens_sql = 50;
    const max_tokens_questions = 150;
    const max_tokens_agent = 4;

    // Call API Gateway to get the agent name
    // console.log(newMessage);
    const AgentNameData = await fetchAgentNameUpdatedAdy(
      newMessage,
      max_tokens_agent
    );
    const agentName = AgentNameData.agent_name?.Router?.toLowerCase();

    // console.log(agentName);

    // Determine the model name based on agent's response

    const model_name = agentName?.toLowerCase().includes("sql")
      ? "SQLDocker"
      : "MistralDocker";

    const promises = [];

    if (agentName?.toLowerCase().includes("sql")) {
      promises.push(
        fetchRelatedQuestions(
          newMessage,
          "RelatedQuestionsSQL",
          max_tokens_questions
        )
      );
    } else {
      promises.push(
        fetchRelatedQuestions(
          newMessage,
          "RelatedQuestionsEnd",
          max_tokens_questions
        )
      );
    }

    if (model_name === "SQLDocker") {
      promises.push(
        fetchSummary(
          newMessage,
          model_name,
          max_tokens_llm,
          max_tokens_sql,
          editedQuery
        )
      );
    }

    if (agentName === "companyanalysis") {
      promises.push(
        fetchSummaryCompany(
          newMessage,
          agentName,
          max_tokens_llm,
          max_tokens_sql,
          AgentNameData
        )
      );
    } else {
      promises.push(Promise.resolve({ llm_response: "", table_data: "" }));
    }

    const results = await Promise.all(promises);
    // console.log(results);

    const relevantQuestionsData = results[0];
    // console.log(relevantQuestionsData);
    const llmResponseData = results[1] || { llm_response: "", table_data: "" };

    // console.log(llmResponseData);

    // Update response object
    newMessageContent.response.llm_response = llmResponseData.llm_response;
    newMessageContent.response.router = AgentNameData.agent_name?.Router;
    newMessageContent.response.subrouter = AgentNameData.agent_name?.SubRouter;
    newMessageContent.response.entity = AgentNameData.agent_name?.Entity;
    newMessageContent.response.metric = AgentNameData.agent_name?.Metric;
    newMessageContent.response.sql_code = editedQuery
      ? editedQuery
      : llmResponseData?.sql_code;
    newMessageContent.response.thinking = llmResponseData?.thinking;

    newMessageContent.response.relevant_questions =
      relevantQuestionsData?.related_questions;
    let symbols;
    try {
      symbols = llmResponseData?.table_data?.startsWith("Symbol@@@")
        ? llmResponseData?.table_data?.substring(10)
        : "";
      // console.log(symbols);

      newMessageContent.response.table_data = symbols;
    } catch (error) {
      newMessageContent.response.table_data = "";
    }

    updatedMessages = updatedMessages.map((msg) =>
      msg.message_id === newMessageContent.message_id ? newMessageContent : msg
    );
    setActiveThread({ ...activeThread, messages: updatedMessages });

    // console.log(llmResponseData);

    if (llmResponseData?.table_data?.startsWith("Symbol@@@")) {
      const symbols = llmResponseData?.table_data?.substring(10);
      const extracted_columns = llmResponseData?.extracted_columns;

      handleMistralResponseSQLData(
        user_value_cookie,
        llmResponseData,
        newMessage,
        max_tokens_llm,
        max_tokens_sql,
        newMessageContent,
        activeThread,
        setActiveThread,
        setSkeletonLLMLoading,
        updatedMessages,
        symbols,
        extracted_columns
      );
    }

    setSkeletonLoading(false);

    let side_chat_name;
    let requestData;
    side_chat_name = await SideChatSummary(newMessage, 8);

    if (activeThread.messages.length === 0) {
      requestData = {
        operation: "write",
        username: user_value_cookie,
        chat_id: activeThread.id,
        chat_name: side_chat_name?.side_chat_name || activeThread.name,
        messages: [newMessageContent],
      };
    } else {
      requestData = {
        operation: "write",
        username: user_value_cookie,
        chat_id: activeThread.id,
        chat_name: side_chat_name?.side_chat_name || activeThread.name,

        messages: [newMessageContent],
      };
    }

    // Write to API
    // console.log(newMessageContent);
    const apiUrl =
      "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";

    // console.log(requestData);

    await axios.post(apiUrl, requestData);

    if (llmResponseData?.table_data?.startsWith("Symbol@@@")) {
      await axios.post(apiUrl, requestData);
    }
  } catch (error) {
    console.error("Error:", error);
    setLoading(false);
    setSkeletonLoading(false);
  } finally {
    setLoading(false);
  }
};

const handleMistralResponseSQLData = async (
  user_value_cookie,
  llmResponseData,
  newMessage,
  max_tokens_llm,
  max_tokens_sql,
  newMessageContent,
  activeThread,
  setActiveThread,
  setSkeletonLLMLoading,
  updatedMessages,
  symbols,
  extracted_columns
) => {
  try {
    let max_tokens_llm = 500;
    setSkeletonLLMLoading(true);

    newMessageContent.response.llm_response = "";

    newMessageContent.response.table_data = symbols;
    newMessageContent.response.extracted_columns = extracted_columns;

    updatedMessages = updatedMessages.map((msg) =>
      msg.message_id === newMessageContent.message_id ? newMessageContent : msg
    );
    setActiveThread({ ...activeThread, messages: updatedMessages });

    // Write to API
    const apiUrl =
      "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";
    const requestData = {
      operation: "write",
      username: user_value_cookie,
      chat_id: activeThread.id,
      chat_name: activeThread.name,
      messages: [newMessageContent],
    };

    // console.log(requestData);

    await axios.post(apiUrl, requestData);

    setSkeletonLLMLoading(false);
  } catch (error) {
    console.error("Error:", error);
  }
};

const fetchSummaryCompany = async (
  userInput,
  agent_name,
  max_tokens_llm,
  max_tokens_sql,
  AgentNameData
) => {
  let prompt;
  let max_tokens;

  max_tokens = max_tokens_sql;
  // console.log(AgentNameData);
  // Call the API with model_name as "SQL" to fetch the SQL query
  // const sqlResponseData = await fetchSQLResponse(
  //   userInput,
  //   model_name,
  //   max_tokens
  // );

  // let sqlQuery = sqlResponseData.sql_query_llm;
  // // console.log(sqlQuery);
  // prompt = userInput + "@@@" + sqlQuery;
  // model_name = "SQLDataDocker";
  // // console.log(prompt);

  // try {
  //   const response = await fetch(
  //     "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //       body: JSON.stringify({
  //         text: prompt,
  //         model_name: model_name,
  //         max_tokens: max_tokens,
  //       }),
  //     }
  //   );

  //   if (!response.ok) {
  //     throw new Error("Failed to fetch summary");
  //   }

  //   const data = await response.json();
  //   console.log(data);

  return {
    llm_response: "",
    table_data: "Symbol@@@ " + AgentNameData.agent_name.Entity[0],
    extracted_columns: "columns@@@" + AgentNameData.agent_name.Metric,
  };
};

const fetchSummary = async (
  userInput,
  model_name,
  max_tokens_llm,
  max_tokens_sql,
  editedQuery
) => {
  let prompt;
  let max_tokens;

  max_tokens = max_tokens_sql;
  // Call the API with model_name as "SQL" to fetch the SQL query
  const sqlResponseData = await fetchSQLResponse(
    userInput,
    model_name,
    max_tokens
  );

  let sqlQuery = editedQuery ? editedQuery : sqlResponseData.sql_query_llm;

  prompt = userInput + "@@@" + sqlQuery;
  model_name = "SQLDataDocker";
  // console.log(prompt);

  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: prompt,
          model_name: model_name,
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();
    // console.log(data);

    return {
      llm_response: data.summary,
      table_data: data.summary,
      extracted_columns: data.extracted_columns,
      sql_code: sqlQuery,
      thinking: sqlResponseData?.thinking,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

const fetchRelatedQuestions = async (userInput, model_name, max_tokens) => {
  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: userInput,
          model_name: model_name,
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();

    return {
      related_questions: data.summary,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

export const fetchSymbolsTable = async (userInput, max_tokens) => {
  prompt = userInput;

  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: prompt,
          model_name: "extract_symbol_table_query",
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();
    // console.log(data);

    return {
      symbols_table: data.summary,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

export const fetchAgentNameUpdatedAdy = async (userInput, max_tokens) => {
  prompt = userInput;
  // console.log(prompt);

  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: prompt,
          model_name: "RouterResponse",
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();
    // console.log(data);

    return {
      agent_name: data.summary,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

const fetchSQLResponse = async (userInput, model_name, max_tokens) => {
  // console.log(userInput, model_name, max_tokens);
  // console.log(userInput)
  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: userInput,
          model_name: model_name,
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch SQL response");
    }

    const data = await response.json();
    // console.log(data);

    return {
      sql_query_llm:
        data.summary
          ?.split("```sql")[0]
          ?.split("```")[0]
          ?.split(";")[0]
          ?.replace("\n", " ")
          ?.split("!!!")[0]
          ?.trim() + ";",
      thinking: data.summary?.split("!!!")[1],
    };
  } catch (error) {
    console.error("Error fetching SQL response:", error.message);
    throw error;
  }
};

const SideChatSummary = async (userInput, max_tokens) => {
  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: userInput,
          model_name: "SideChat",
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();
    // console.log(data);

    return {
      side_chat_name: data.summary,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

export const trivias = [
  "The term 'blue chip' stocks originated from poker, where blue chips hold the highest value.",
  "Amazon's founder, Jeff Bezos, added $13 billion to his net worth in a single day on July 20, 2020, the largest single-day increase ever recorded.",
  "The first modern stock exchange was established in Amsterdam in 1602.",
  "Black Monday occurred on October 19, 1987, when stock markets around the world crashed.",
  "Warren Buffett made his first stock purchase at age 11, buying shares of Cities Service Preferred for $38 each.",
  "The longest bull market lasted from March 2009 to March 2020.",
  "The first stock ticker was invented by Edward A. Calahan in 1867, revolutionizing stock market communication.",
  "The 'Fear Index' or VIX measures market volatility and investor fear.",
  "The Dow Jones Industrial Average initially consisted of only 12 stocks when it was created in 1896.",
  "The term 'bull market' is believed to have originated from the way a bull attacks by thrusting its horns upward, symbolizing upward market trends.",
];

const steps = [
  { title: "Analyzing Question", percentage: 33 },
  { title: "Fetching Relevant Data", percentage: 66 },
  { title: "Formulating Answer", percentage: 100 },
];

export const ProgressSteps = ({ newMessage, streamActive, AgentNameData }) => {
  const [currentStepSkeleton, setCurrentStepSkeleton] = useState(-1);

  // Reset state when new message is received or streamActive is toggled
  useEffect(() => {
    setCurrentStepSkeleton(-1);
  }, [newMessage, streamActive]);

  useEffect(() => {
    // Step 1: Display the first card immediately
    setCurrentStepSkeleton(0);
  }, []); // Run on initial render

  useEffect(() => {
    let interval;

    // Start waiting for the second card once AgentNameData is available
    if (AgentNameData?.length > 0) {
      interval = setTimeout(() => {
        setCurrentStepSkeleton(1); // Step 2: Move to the second step after 3 seconds
      }, 1000); // 3-second delay
    }

    return () => clearTimeout(interval); // Cleanup timeout on unmount
  }, [AgentNameData]);

  useEffect(() => {
    // Step 3: Move to the third step when streamActive is true
    if (streamActive && currentStepSkeleton === 1) {
      setCurrentStepSkeleton(2);

      // Optional: Reset all steps to -1 after completion (if needed)
      const resetTimeout = setTimeout(() => {
        setCurrentStepSkeleton(-1);
      }, 3000); // Adjust the reset delay as needed

      return () => clearTimeout(resetTimeout); // Cleanup timeout on unmount
    }
  }, [streamActive, currentStepSkeleton]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "100%", // Prevents any overflow beyond the viewport width
        margin: "auto",
        overflow: "hidden", // Prevents children from causing horizontal scroll
        flexDirection: "row",
      }}
    >
      {steps.map(
        (step, index) =>
          currentStepSkeleton >= index && ( // Show only if the step is reached or surpassed
            // <div key={index} style={{ margin: "0 10px", flex: 1 }}>
            <div
              key={index}
              style={{
                flex: "1 1 0px",
                margin: "1%",
                maxWidth: "calc(100% - 20px)",
              }}
            >
              <Card
                style={{
                  borderRadius: "5%",
                  backgroundColor:
                    currentStepSkeleton === index
                      ? "#e0f7df"
                      : currentStepSkeleton > index
                      ? "#a4d4ae"
                      : "#f5f5f5", // Softer colors for green and grey
                  textAlign: "center",
                  padding: "2%",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
                  // height: "150px", // Consistent height
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <h4
                  style={{
                    margin: "0",
                    fontSize: "small",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {step.title}
                </h4>
                <Progress
                  percent={
                    currentStepSkeleton >= index
                      ? step.percentage
                      : currentStepSkeleton === index
                      ? Math.min(
                          step.percentage * ((Date.now() % 1000) / 1000),
                          step.percentage
                        )
                      : 0
                  } // Smooth transition for active step
                  status={currentStepSkeleton >= index ? "active" : "normal"}
                  strokeColor={
                    currentStepSkeleton >= index ? "#8bc34a" : undefined
                  } // Softer green for progress bar
                  style={{ marginTop: "10px" }}
                />
              </Card>
            </div>
          )
      )}
    </div>
  );
};

export const StreamComponent = ({
  question,
  streamActive,
  setStreamActive,
  activeThread,
  user_value_cookie,
  AgentNameData,
  symbols_sql,
  symbols_table,
  setLlmResponseData,
  setRelatedQuestionShow,
  InterruptWrite,
  setInterruptWrite,
  RouterData,
}) => {
  const [messages, setMessages] = useState("");
  const [questionAsked, setquestionAsked] = useState("");
  const [questionAskedUpdated, setquestionAskedUpdated] = useState("");
  const [activeThreadUpdated, setActiveThreadUpdated] = useState();

  let symbols_sql_extracted = symbols_sql?.table_data;
  let sql_code = symbols_sql?.sql_code;

  let extracted_columns_test = symbols_sql?.extracted_columns?.split("@@@")[1];

  useEffect(() => {
    // setIsLoading(true); // Start loading when the stream is active and question is set

    if (streamActive && question?.length > 0) {
      setLlmResponseData("SampleChat");
      setRelatedQuestionShow(false);
      setquestionAskedUpdated(question);
      setActiveThreadUpdated(activeThread);
      let answerType = AgentNameData;
      let url = `https://stockbuzz2.ngrok.app/stream?question=${encodeURIComponent(
        question
      )}&answer_type=${encodeURIComponent(answerType)}`;
      if (AgentNameData == "SQL") {
        url = `https://stockbuzz2.ngrok.app/stream?question=${encodeURIComponent(
          question
        )}&answer_type=${encodeURIComponent(
          answerType
        )}&symbols_sql_extracted=${encodeURIComponent(
          symbols_sql_extracted
        )}&sql_code=${encodeURIComponent(
          sql_code
        )}&extracted_columns=${encodeURIComponent(extracted_columns_test)}`;
      }

      if (AgentNameData == "CompanyAnalysis") {
        url = `https://stockbuzz2.ngrok.app/stream?question=${encodeURIComponent(
          question
        )}&answer_type=${encodeURIComponent(
          answerType
        )}&subrouter=${encodeURIComponent(
          RouterData?.SubRouter
        )}&symbols_sql_extracted=${encodeURIComponent(RouterData?.Entity)}`;
      }

      if (AgentNameData == "OutOfScope") {
        url = `https://stockbuzz2.ngrok.app/stream?question=${encodeURIComponent(
          question
        )}&answer_type=${encodeURIComponent(answerType)}`;
      }

      //  console.log(url)

      const eventSource = new EventSource(url);

      eventSource.onmessage = (event) => {
        const newMessage = event.data; // Replace <br /> with \n
        setMessages((prevMessages) => `${prevMessages}${newMessage}`);
      };

      eventSource.onerror = (error) => {
        setquestionAsked(question);
        // console.error("EventSource failed:", error);
        eventSource.close();
      };

      return () => {
        eventSource.close();

        // setStreamActive(false);
      };
    }
  }, [InterruptWrite]); // Reconnect based on question or streamActive change

  useEffect(() => {
    if (questionAsked !== "" || messages?.trimStart() !== "") {
      // Function to dynamically identify symbols and add hyperlinks
      const addHyperlinksToText = (text) => {
        // Match symbols in the format (SYMBOL)
        return text.replace(/\(([A-Z]+)\)/g, (match, symbol) => {
          return `
            <span 
              style="display: inline-flex; align-items: center;"
              class="dynamic-link"
              data-symbol="${symbol}"
            >
              <a 
                href="/${symbol}" 
                target="_blank" 
                rel="noopener noreferrer" 
                style="color: #0073e6; text-decoration: none; font-weight: bold;" 
                class="link-text"
              >
                ${symbol}
              </a>
              <i 
                class="arrow-icon"
                style="
                  margin-left: 4px; 
                  font-size: 14px; 
                  color: #0073e6; 
                  transition: transform 0.2s ease;"
              >➡️</i>
            </span>`;
        });
      };

      // Sanitize and hyperlink the messages
      const hyperlinkMessages = addHyperlinksToText(messages);

      const newMessageContent = {
        message_id: `m${new Date().getTime()}`,
        message: questionAskedUpdated,
        type: "question",
        response: {
          llm_response: hyperlinkMessages,
          question_time: new Date().toISOString(),
        },
      };

      setLlmResponseData(hyperlinkMessages);

      const apiUrl =
        "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";
      const requestData = {
        operation: "write",
        username: user_value_cookie,
        chat_id: activeThreadUpdated.id,
        messages: [newMessageContent],
      };

      // Send request to your API
      axios
        .post(apiUrl, requestData)
        .then((response) => {
          // console.log("Response from API:", response.data);
        })
        .catch((error) => {
          // console.error("Error sending data to API:", error);
        });

      setRelatedQuestionShow(true);
      setStreamActive(false);
    }
  }, [questionAsked, InterruptWrite]);

  // Sanitized Messages with hyperlinks
  // function fixMalformedHtml(html) {
  //   // Create a DOM parser to parse and fix the HTML
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(html, "text/html");

  //   // Serialize the fixed HTML back to a string
  //   return doc.body.innerHTML;
  // }

  // const sanitizedMessages = fixMalformedHtml(messages);

  const sanitizedMessages = messages
    ?.replace(/undefined>/g, "")
    ?.replace(/<a[^>]+href="[^"]*"[^>]*>(.*?)<\/a>/g, "$1")
    ?.trimStart();

  const formulaMatches = sanitizedMessages?.match(/\$\$[\s\S]*?\$\$/g) || [];
  const sanitizedFormulas = formulaMatches?.map((formula) =>
    formula?.replace(/^\$\$|\$\$$/g, "")
  );
  const nonFormulaContent = sanitizedMessages?.split(/\$\$[\s\S]*?\$\$/g) || [];

  const config = {
    loader: { load: ["input/tex", "output/chtml"] },
    tex: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
    chtml: {
      scale: 0.8, // Adjust based on your needs
      minScale: 0.5, // Minimum scale for small devices
    },
  };

  return (
    <MathJaxContext config={config}>
      <div
        className="markdown-preview"
        style={{ width: "100%", overflowX: "hidden" }}
      >
        <div className="content-container" style={{ wordWrap: "break-word" }}>
          {nonFormulaContent?.map((content, index) => (
            <div key={index}>
              {(() => {
                try {
                  // Render the non-formula content
                  return <MarkdownPreview source={content} />;
                } catch (error) {
                  console.error("Error rendering MarkdownPreview:", error);
                  return <div style={{ minHeight: "5%" }}></div>;
                }
              })()}

              {(() => {
                try {
                  // Render the formula if it exists
                  if (
                    sanitizedFormulas[index] &&
                    sanitizedFormulas[index].trim() !== ""
                  ) {
                    return (
                      <MathJax>{`$$${sanitizedFormulas[index]}$$`}</MathJax>
                    );
                  }
                  return null;
                } catch (error) {
                  console.error("Error rendering MathJax:", error);
                  return <div style={{ minHeight: "5%" }}></div>;
                }
              })()}
            </div>
          ))}
        </div>
      </div>
    </MathJaxContext>
  );
};
