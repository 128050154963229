import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import "./App.css";
import { useNavigate } from "react-router-dom";

const Discover1 = ({ setNewMessage }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const navigate = useNavigate(); // Create navigate function instance

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to handle card clicks
  const handleCardClick = (message) => {
    setNewMessage(message); // Update the message in MainChat
    navigate("/"); // Navigate to the home page or main dashboard, consistent with DiscoverPage
  };
  return (
    <div>
      <div
        style={{
          marginTop: isMobile ? "25%" : "10%",
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        {" "}
        <h2>SQL</h2>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="High Flyer Stocks "
              onClick={() =>
                handleCardClick("Companies trading near their 52-week high")
              }
              bordered={false}
              style={{
                backgroundColor: "#E0D4E7",
                height: "30vh",
              }}
              className="card-hover-effect"
            >
              Companies are trading near their 52-week high
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="GPU Manufactureres"
              onClick={() => handleCardClick("GPU Manufactureres")}
              bordered={false}
              style={{ backgroundColor: "#E0D4E7", height: "30vh" }}
              className="card-hover-effect"
            >
              Identify GPU manufacturers with 10% quarterly revenue growth and
              sort them by Market Capitalization.
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Space exploration"
              bordered={false}
              style={{ backgroundColor: "#E0D4E7", height: "30vh" }}
              className="card-hover-effect"
            >
              Top 5 Space exploration companies based on revenues
            </Card>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "10%", marginLeft: "5%", marginRight: "5%" }}>
        {" "}
        <h2>Single Company Analysis</h2>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Microsoft Company Analysis"
              bordered={false}
              style={{ backgroundColor: "#BDD2E4", height: "30vh" }}
              className="card-hover-effect"
            >
              Analyze the financials of Microsoft
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Amazon news today"
              bordered={false}
              style={{ backgroundColor: "#BDD2E4", height: "30vh" }}
              className="card-hover-effect"
            >
              What’s the news around Amazon today
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Nvidia latest product launches"
              bordered={false}
              style={{ backgroundColor: "#BDD2E4", height: "30vh" }}
              className="card-hover-effect"
            >
              What are the latest GPU launches by Nvidia?
            </Card>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "10%", marginLeft: "5%", marginRight: "5%" }}>
        {" "}
        <h2>Intrinsic Value</h2>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Costco - Buy or not?"
              bordered={false}
              style={{ backgroundColor: "#F8EDEB", height: "30vh" }}
              className="card-hover-effect"
            >
              Should I buy Costco today?
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Nvidia DCF"
              bordered={false}
              style={{ backgroundColor: "#F8EDEB", height: "30vh" }}
              className="card-hover-effect"
            >
              What is the fair value of Nvidia?
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Meta value"
              bordered={false}
              style={{ backgroundColor: "#F8EDEB", height: "30vh" }}
              className="card-hover-effect"
            >
              Is Meta fairly valued?
            </Card>
          </Col>
        </Row>
      </div>
      <div
        style={{
          marginTop: "10%",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "15%",
        }}
      >
        {" "}
        <h2>Multi-company Analysis</h2>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="AMD Vs Nvidia"
              bordered={false}
              style={{ backgroundColor: "#A9CDD7", height: "30vh" }}
              className="card-hover-effect"
            >
              Compare AMD vs. Nvidia in terms of profit margins.
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Amazon Vs Walmart"
              bordered={false}
              style={{ backgroundColor: "#A9CDD7", height: "30vh" }}
              className="card-hover-effect"
            >
              Compare Amazon vs. Walmart in terms of revenue growth and ROCE.
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Microsoft Vs Amazon"
              bordered={false}
              style={{ backgroundColor: "#A9CDD7", height: "30vh" }}
              className="card-hover-effect"
            >
              Compare Microsoft and Amazon on Cloud business
            </Card>
          </Col>
        </Row>
      </div>
      <div
        style={{
          marginTop: "10%",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "15%",
        }}
      >
        {" "}
        <h2>Detailed Company Analysis</h2>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Tesla"
              bordered={false}
              style={{ backgroundColor: "#DED6CE", height: "30vh" }}
              className="card-hover-effect"
            ></Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Apple"
              bordered={false}
              style={{ backgroundColor: "#DED6CE", height: "30vh" }}
              className="card-hover-effect"
            ></Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Costco"
              bordered={false}
              style={{ backgroundColor: "#DED6CE", height: "30vh" }}
              className="card-hover-effect"
            ></Card>
          </Col>
        </Row>
      </div>
      <div
        style={{
          marginTop: "10%",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "15%",
        }}
      >
        {" "}
        <h2>LLM</h2>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Cigar-butt Investing"
              bordered={false}
              style={{ backgroundColor: "#D8E2DC", height: "30vh" }}
              className="card-hover-effect"
            >
              What is Cigar butt investing?{" "}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="Buffet on Bitcoin"
              bordered={false}
              style={{ backgroundColor: "#D8E2DC", height: "30vh" }}
              className="card-hover-effect"
            >
              What are Warren Buffet views on Bitcoin?
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              title="ROCE"
              bordered={false}
              style={{ backgroundColor: "#D8E2DC", height: "30vh" }}
              className="card-hover-effect"
            >
              What is ROCE and how is it calculated
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Discover1;
