import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Plotly from "plotly.js-dist";

import {
  Card,
  Typography,
  Row,
  Col,
  Button,
  Spin,
  Table,
  Radio,
  Tabs,
  Select,
  Divider,
  List,
  Form,
  Tag,
  Popover,
  Collapse,
} from "antd";
import "./DetailPage.css";
import {
  FilePdfOutlined,
  DownOutlined,
  UpOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import * as d3 from "d3";
import { Helmet } from "react-helmet";
import { Scatter } from "@ant-design/plots";

import moment from "moment";

import GaugeComponent from "react-gauge-component";

import { Sparklines, SparklinesLine } from "react-sparklines";
import { Tiny } from "@ant-design/plots";
import axios from "axios";
import { ProCard, StatisticCard } from "@ant-design/pro-components";
import {
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer,
  Legend,
  ReferenceArea,
  ReferenceLine,
  ReferenceDot,
  Bar,
  BarChart,
  ScatterChart,
  Label,
} from "recharts";

import { useParams } from "react-router";

import { InfoCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

export const clean_columns_all = {
  date: "Date",
  symbol: "Symbol",
  reportedCurrency: "Reported Currency",
  cik: "CIK",
  fillingDate: "Filling Date",
  acceptedDate: "Accepted Date",
  calendarYear: "Calendar Year",
  period: "Period",
  revenue: "Revenue",
  costOfRevenue: "Cost of Revenue",
  grossProfit: "Gross Profit",
  grossProfitRatio: "Gross Profit Ratio",
  ResearchAndDevelopmentExpenses: "R&D Expenses",
  GeneralAndAdministrativeExpenses: "Admin. Expenses",
  SellingAndMarketingExpenses: "Selling & Market. Expenses",
  sellingGeneralAndAdministrativeExpenses: "Selling, Gen. and Admin Expenses",
  otherExpenses: "Other Expenses",
  operatingExpenses: "Operating Expenses",
  costAndExpenses: "Cost and Expenses",
  interestExpense: "Interest Expense",
  depreciationAndAmortization: "Depreciation and Amortization",
  EBITDA: "EBITDA",
  EBITDARatio: "EBITDA Ratio",
  operatingIncome: "Operating Income",
  operatingIncomeRatio: "Operating Income Ratio",
  totalOtherIncomeExpensesNet: "Total Other Income/Expenses Net",
  incomeBeforeTax: "Income Before Tax",
  incomeBeforeTaxRatio: "Income Before Tax Ratio",
  incomeTaxExpense: "Income Tax Expense",
  netIncome: "Net Income",
  netIncomeRatio: "Net Income Ratio",
  eps: "Earnings per Share (EPS)",
  EPSDiluted: "Earnings per Share (EPS, Diluted)",
  weightedAverageShsOut: "Weighted Average Shares Outstanding",
  weightedAverageShsOutDil: "Weighted Average Shares Outstanding (Diluted)",
  link: "Link",
  finalLink: "Final Link",
  interestincome: "Interest Income",
  deferredIncomeTax: "Deferred Income Tax",
  stockBasedCompensation: "Stock-Based Compensation",
  changeInWorkingCapital: "Change in Working Capital",
  accountsReceivables: "Accounts Receivables",
  inventory: "Inventory",
  accountsPayables: "Accounts Payables",
  otherWorkingCapital: "Other Working Capital",
  otherNonCashItems: "Other Non-Cash Items",
  netCashProvidedByOperatingActivites: "netCashProvidedByOperatingActivites",
  investmentsInPropertyPlantAndEquipment: "Investments in PPE",
  acquisitionsNet: "Acquisitions (Net)",
  purchasesOfInvestments: "Purchases of Investments",
  salesMaturitiesOfInvestments: "Sales/Maturities of Investments",
  otherInvestingActivites: "Other Investing Activities",
  netCashUsedForInvestingActivites: "Cash from Investing Activity",
  debtRepayment: "Debt Repayment",
  commonStockIssued: "Common Stock Issued",
  commonStockRepurchased: "Common Stock Repurchased",
  dividendsPaid: "Dividends Paid",
  otherFinancingActivites: "Other Financing Activities",
  netCashUsedProvidedByFinancingActivities: "Cash from Financing Activity",
  effectOfForexChangesOnCash: "Effect of Forex Changes on Cash",
  netChangeInCash: "Net Change in Cash",
  cashAtEndOfPeriod: "Cash at End of Period",
  cashAtBeginningOfPeriod: "Cash at Beginning of Period",
  operatingCashFlow: "Cash from Operating Activity",
  capitalExpenditure: "Capital Expenditure",
  freeCashFlow: "Free Cash Flow",
  cashAndCashEquivalents: "Cash and Cash Equivalents",
  shortTermInvestments: "Short-Term Investments",
  cashAndShortTermInvestments: "Cash and Short-Term Investments",
  netReceivables: "Net Receivables",
  otherCurrentAssets: "Other Current Assets",
  totalCurrentAssets: "Total Current Assets",
  propertyPlantEquipmentNet: "PPE(Net)",
  goodwill: "Goodwill",
  intangibleAssets: "Intangible Assets",
  otherAssets: "Other Assets",
  totalAssets: "Total Assets",
  accountPayables: "Account Payables",
  shortTermDebt: "Short-Term Debt",
  otherCurrentLiabilities: "Other Current Liabilities",
  totalCurrentLiabilities: "Total Current Liabilities",
  longTermDebt: "Long-Term Debt",
  otherLiabilities: "Other Liabilities",
  totalLiabilities: "Total Liabilities",
  commonStock: "Common Stock",
  retainedEarnings: "Retained Earnings",
  totalStockholdersEquity: "Total Stockholders' Equity",
  totalLiabilitiesAndStockholdersEquity:
    "Total Liabilities and Stockholders' Equity",

  currentRatio: "Current Ratio",
  quickRatio: "Quick Ratio",
  cashRatio: "Cash Ratio",
  daysOfSalesOutstanding: "Days of Sales Outstanding",
  daysOfInventoryOutstanding: "Days of Inventory Outstanding",
  operatingCycle: "Operating Cycle",
  daysOfPayablesOutstanding: "Days of Payables Outstanding",
  cashConversionCycle: "Cash Conversion Cycle",
  grossProfitMargin: "Gross Profit Margin",
  operatingProfitMargin: "Operating Profit Margin",
  pretaxProfitMargin: "Pretax Profit Margin",
  netProfitMargin: "Net Profit Margin",
  effectiveTaxRate: "Effective Tax Rate",
  returnOnAssets: "Return on Assets",
  returnOnEquity: "Return on Equity",
  returnOnCapitalEmployed: "Return on Capital Employed",
  netIncomePerEBT: "Net Income per EBT",
  ebtPerEbit: "EBT per EBIT",
  ebitPerRevenue: "EBIT per Revenue",
  debtRatio: "Debt Ratio",
  debtEquityRatio: "Debt/Equity Ratio",
  longTermDebtToCapitalization: "Long-Term Debt to Capitalization",
  totalDebtToCapitalization: "Total Debt to Capitalization",
  interestCoverage: "Interest Coverage",
  cashFlowToDebtRatio: "Cash Flow to Debt Ratio",
  companyEquityMultiplier: "Company Equity Multiplier",
  receivablesTurnover: "Receivables Turnover",
  payablesTurnover: "Payables Turnover",
  inventoryTurnover: "Inventory Turnover",
  fixedAssetTurnover: "Fixed Asset Turnover",
  assetTurnover: "Asset Turnover",
  operatingCashFlowPerShare: "Operating Cash Flow per Share",
  freeCashFlowPerShare: "Free Cash Flow per Share",
  cashPerShare: "Cash per Share",
  payoutRatio: "Payout Ratio",
  operatingCashFlowSalesRatio: "Operating Cash Flow/Sales Ratio",
  freeCashFlowOperatingCashFlowRatio:
    "Free Cash Flow/Operating Cash Flow Ratio",
  cashFlowCoverageRatios: "Cash Flow Coverage Ratios",
  shortTermCoverageRatios: "Short-Term Coverage Ratios",
  capitalExpenditureCoverageRatio: "Capital Expenditure Coverage Ratio",
  dividendPaidAndCapexCoverageRatio: "Dividend Paid and Capex Coverage Ratio",
  dividendPayoutRatio: "Dividend Payout Ratio",
  priceBookValueRatio: "Price/Book Value Ratio",
  priceToBookRatio: "Price-to-Book Ratio",
  priceToSalesRatio: "Price-to-Sales Ratio",
  priceEarningsRatio: "Price/Earnings Ratio",
  priceToFreeCashFlowsRatio: "Price-to-Free Cash Flows Ratio",
  priceToOperatingCashFlowsRatio: "Price-to-Operating Cash Flows Ratio",
  priceCashFlowRatio: "Price/Cash Flow Ratio",
  priceEarningsToGrowthRatio: "Price/Earnings to Growth Ratio",
  priceSalesRatio: "Price/Sales Ratio",
  dividendYield: "Dividend Yield",
  enterpriseValueMultiple: "Enterprise Value Multiple",
  priceFairValue: "Price/Fair Value",
};

const AnalystGaugeChart = ({ symbol }) => {
  const [loading, setLoading] = useState(true);
  const [analystData, setAnalystData] = useState({
    strongBuy: 0,
    buy: 0,
    hold: 0,
    sell: 0,
    strongSell: 0,
    totalAnalysts: 0,
  });

  const fetchAnalystData = async () => {
    const apiUrl =
      "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy"; // Replace with your API Gateway URL

    try {
      const response = await axios.post(apiUrl, {
        queryStringParameters: {
          endpoint: "analyst-stock-recommendations", // Lambda endpoint for analyst data
          symbols: symbol, // Pass the stock symbol dynamically
        },
      });

      if (response.status === 200) {
        const data = JSON.parse(response.data.body); // Parse the JSON response from the API Gateway
        const latest = data[0];
        if (latest) {
          setAnalystData({
            strongBuy: latest.analystRatingsStrongBuy || 0,
            buy: latest.analystRatingsBuy || 0,
            hold: latest.analystRatingsHold || 0,
            sell: latest.analystRatingsSell || 0,
            strongSell: latest.analystRatingsStrongSell || 0,
            totalAnalysts:
              (latest.analystRatingsStrongBuy || 0) +
              (latest.analystRatingsBuy || 0) +
              (latest.analystRatingsHold || 0) +
              (latest.analystRatingsSell || 0) +
              (latest.analystRatingsStrongSell || 0),
          });
        }
      } else {
        console.error("Error fetching analyst data:", response.data);
      }
    } catch (error) {
      console.error("Failed to fetch analyst data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalystData();
  }, []);

  const { strongBuy, buy, hold, sell, strongSell, totalAnalysts } = analystData;

  const totalRecommendations = strongBuy + buy + hold + sell + strongSell;

  // Calculate percentage position
  const percent = totalRecommendations
    ? (strongBuy * 1 + buy * 0.75 + hold * 0.5 + sell * 0.25 + strongSell * 0) /
      totalRecommendations
    : 0.5;

  const subArcs = [
    { limit: 20, color: "#FF5F6D", tooltip: { text: "Strong Sell" } },
    { limit: 40, color: "#FFA07A", tooltip: { text: "Sell" } },
    { limit: 60, color: "#FFC371", tooltip: { text: "Hold" } },
    { limit: 80, color: "#A0D911", tooltip: { text: "Buy" } },
    { color: "#52C41A", tooltip: { text: "Strong Buy" } },
  ];

  const recommendations = [
    { label: "Strong Sell", value: strongSell, color: "#FF5F6D" },
    { label: "Sell", value: sell, color: "#FFA07A" },
    { label: "Hold", value: hold, color: "#FFC371" },
    { label: "Buy", value: buy, color: "#A0D911" },
    { label: "Strong Buy", value: strongBuy, color: "#52C41A" },
  ];

  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h3
        style={{
          marginBottom: "10px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Analyst Recommendations
      </h3>
      {/* <p
        style={{
          marginBottom: "20px",
          fontSize: "14px",
          color: "#555",
        }}
      >
        Based on {totalAnalysts} analyst ratings for {companyName} in the last
        30 days
      </p> */}

      {loading ? (
        <Spin />
      ) : (
        <div style={{ width: "100%", height: "200px", position: "relative" }}>
          <GaugeComponent
            type="semicircle"
            value={percent * 100}
            minValue={0}
            maxValue={100}
            arc={{
              width: 0.2,
              padding: 0.005,
              cornerRadius: 1,
              subArcs,
            }}
            pointer={{
              color: "#464A4F",
              length: 0.8,
              width: 15,
            }}
            labels={{
              valueLabel: {
                formatTextValue: (value) => `${value.toFixed(2)}%`,
              },
              tickLabels: {
                type: "outer",
                ticks: [
                  { value: 0, label: "Strong Sell" },
                  { value: 25, label: "Sell" },
                  { value: 50, label: "Hold" },
                  { value: 75, label: "Buy" },
                  { value: 100, label: "Strong Buy" },
                ],
              },
            }}
          />
        </div>
      )}

      <List
        size="small"
        dataSource={recommendations.reverse()} // Reverse the order
        renderItem={(item) => (
          <List.Item style={{ padding: "8px 0" }}>
            <Text
              strong
              style={{
                fontSize: "14px",
                color: item.color, // Add color for visual clarity
              }}
            >
              {item.label}:
            </Text>
            <Text
              style={{
                marginLeft: "8px",
                fontSize: "14px",
                color: "#555",
              }}
            >
              {item.value}
            </Text>
          </List.Item>
        )}
      />
    </div>
  );
};

export const DemoScatter = ({ symbol }) => {
  const chartRef = useRef();
  const highlightSymbol = symbol; // Symbol to highlight
  const BUFFER_RATIO = 1.2; // Add 10% buffer for chart bounds

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Added error state

  // Define your color palette
  const colors = {
    highlight: "#0071bc", // Deep blue for highlighting
    primary: "#00a651", // Green for positive growth
    secondary: "#f26522", // Orange for negative indicators
    background: "#f7f7f7", // Soft grey background for cleanliness
    text: "#333333", // Dark grey for text for better readability
    gridline: "#cccccc", // Light grey for gridlines
  };

  const fetchData = async (symbol) => {
    try {
      setLoading(true);

      setError(null);
      const response = await fetch(
        "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            text: [symbol],
            model_name: "FetchStockPeers",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();

      const peersData = result || [];
      // console.log(peersData);

      // Transform data into the required format
      const formattedData = peersData?.summary?.map((item) => {
        const revenueGrowth = (
          ((item.revenue_quarterly_billion -
            item.revenue_4_quarters_back_quarterly_billion) /
            Math.abs(item.revenue_4_quarters_back_quarterly_billion)) *
          100
        ).toFixed(1); // Calculate revenue growth percentage properly

        const profitGrowth = (
          ((item.netincome_quarterly_billion -
            item.netincome_4_quarters_back_quarterly_billion) /
            Math.abs(item.netincome_4_quarters_back_quarterly_billion)) *
          100
        ).toFixed(1); // Calculate profit growth percentage properly

        return {
          symbol: item.symbol,
          name: item.companyname || item.symbol,
          revenueGrowth: +revenueGrowth,
          profitGrowth: +profitGrowth,
          marketCap: +item.market_cap_billion.toFixed(1), // Format market cap to 1 decimal
          image: `https://images.financialmodelingprep.com/symbol/${item.symbol}.png`,
          revenue4QuartersBack:
            +item.revenue_4_quarters_back_quarterly_billion.toFixed(1), // Revenue 4 quarters back
          revenueLatest: +item.revenue_quarterly_billion.toFixed(1), // Latest revenue
          profit4QuartersBack:
            +item.netincome_4_quarters_back_quarterly_billion.toFixed(1), // Profit 4 quarters back
          profitLatest: +item.netincome_quarterly_billion.toFixed(1), // Latest profit
        };
      });

      setData(formattedData);
    } catch (error) {
      console.error(
        "Error fetching peers data. Using sample data instead:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(symbol);
  }, [symbol]);

  // console.log(data);

  useEffect(() => {
    const margin = { top: 60, right: 50, bottom: 50, left: 50 };

    const container = chartRef.current;
    const width = container.offsetWidth - margin.left - margin.right;
    const height = container.offsetHeight - margin.top - margin.bottom;

    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", container.offsetWidth)
      .attr("height", container.offsetHeight);

    const chartGroup = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Calculate mean and standard deviation
    const calcStats = (key) => {
      if (!data || !Array.isArray(data) || data.length === 0) {
        console.error("Data is not properly initialized or is empty.");
        return { mean: 0, stddev: 0 }; // Provide default values
      }

      const values = data
        .map((d) => d[key])
        .filter((value) => value !== undefined && value !== null);

      if (values.length === 0) {
        console.error(`No valid values found for key: ${key}`);
        return { mean: 0, stddev: 0 }; // Provide default values
      }

      const mean = d3.mean(values);
      const stddev = d3.deviation(values);
      return { mean, stddev };
    };

    const revenueStats = calcStats("revenueGrowth");
    const profitStats = calcStats("profitGrowth");

    // Function to determine bounds by excluding extreme outliers
    const calculateBounds = (values) => {
      // Validate the input array
      if (!Array?.isArray(values) || values?.length < 3) {
        console.error(
          "Invalid or insufficient values provided for bounds calculation."
        );
        return [null, null]; // Return null bounds if the array is invalid
      }

      // Filter out undefined or null values
      const validValues = values?.filter(
        (value) => value !== undefined && value !== null
      );

      if (validValues?.length < 3) {
        console.error("Not enough valid values for bounds calculation.");
        return [null, null]; // Return null bounds if there are fewer than 3 valid values
      }

      // Sort values in ascending order
      const sortedValues = [...validValues]?.sort((a, b) => a - b);

      // Exclude top and bottom outliers (second smallest and second largest)
      const lowerBound = sortedValues[1]; // Second smallest
      const upperBound = sortedValues[sortedValues.length - 2]; // Second largest

      return [lowerBound, upperBound];
    };

    // Calculate bounds dynamically
    const revenueBounds = calculateBounds(data?.map((d) => d.revenueGrowth));
    const profitBounds = calculateBounds(data?.map((d) => d.profitGrowth));

    // Filter non-outliers independently for each axis
    const nonOutlierDataX = data?.filter(
      (d) =>
        d.revenueGrowth >= revenueBounds[0] &&
        d.revenueGrowth <= revenueBounds[1]
    );

    const nonOutlierDataY = data?.filter(
      (d) =>
        d.profitGrowth >= profitBounds[0] && d.profitGrowth <= profitBounds[1]
    );

    // Recalculate axis ranges independently
    const dynamicMaxX =
      BUFFER_RATIO *
      Math.max(...nonOutlierDataX?.map((d) => Math.abs(d.revenueGrowth)));
    const dynamicMaxY =
      BUFFER_RATIO *
      Math.max(...nonOutlierDataY?.map((d) => Math.abs(d.profitGrowth)));

    // Define clamped data for rendering
    const clampedData = data?.map((d) => ({
      ...d,
      clampedRevenue: Math.min(
        Math.max(d.revenueGrowth, -dynamicMaxX),
        dynamicMaxX
      ),
      clampedProfit: Math.min(
        Math.max(d.profitGrowth, -dynamicMaxY),
        dynamicMaxY
      ),
      isOutlierX:
        d.revenueGrowth < revenueBounds[0] ||
        d.revenueGrowth > revenueBounds[1],
      isOutlierY:
        d.profitGrowth < profitBounds[0] || d.profitGrowth > profitBounds[1],
    }));
    // console.log(dynamicMaxX);

    const xScale = d3
      .scaleLinear()
      .domain([-dynamicMaxX, dynamicMaxX])
      .range([0, width]);
    const yScale = d3
      .scaleLinear()
      .domain([-dynamicMaxY, dynamicMaxY])
      .range([height, 0]);

    // Add quadrant backgrounds
    chartGroup
      .append("rect")
      .attr("x", xScale(0))
      .attr("y", yScale(dynamicMaxY))
      .attr("width", xScale(dynamicMaxX) - xScale(0))
      .attr("height", yScale(0) - yScale(dynamicMaxY))
      .attr("fill", "rgba(144, 238, 144, 0.3)");

    chartGroup
      .append("rect")
      .attr("x", xScale(-dynamicMaxX))
      .attr("y", yScale(0))
      .attr("width", xScale(0) - xScale(-dynamicMaxX))
      .attr("height", yScale(-dynamicMaxY) - yScale(0))
      .attr("fill", "rgba(255, 182, 193, 0.3)");

    // Add axes
    chartGroup
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale).ticks(5))
      .selectAll("text")
      .style("font-size", "12px");

    chartGroup
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + 40)
      .attr("fill", "#000")
      .style("font-size", "14px")
      .style("text-anchor", "middle")
      .text("Revenue Growth (%)");

    chartGroup
      .append("g")
      .call(d3.axisLeft(yScale).ticks(5))
      .selectAll("text")
      .style("font-size", "12px");

    chartGroup
      .append("text")
      .attr("x", -height / 2)
      .attr("y", -40)
      .attr("fill", "#000")
      .style("font-size", "14px")
      .style("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Profit Growth (%)");

    // Add gridlines
    chartGroup
      .append("g")
      .selectAll("line.horizontal")
      .data(yScale.ticks(5))
      .enter()
      .append("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4,4");

    chartGroup
      .append("g")
      .selectAll("line.vertical")
      .data(xScale.ticks(5))
      .enter()
      .append("line")
      .attr("x1", (d) => xScale(d))
      .attr("x2", (d) => xScale(d))
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4,4");

    // Add points
    const tooltip = d3
      .select(chartRef.current)
      .append("div")
      .style("position", "absolute")
      .style("background", "#fff")
      .style("border-radius", "5px")
      .style("pointer-events", "none")
      .style("opacity", 0);

    const points = chartGroup
      .selectAll("g.point")
      .data(clampedData)
      .enter()
      .append("a")
      .attr("xlink:href", (d) => `/${d.symbol}`)
      .attr("target", "_blank")
      .append("g")
      .attr(
        "transform",
        (d) =>
          `translate(${xScale(d.clampedRevenue)},${yScale(d.clampedProfit)})`
      )
      .on("mouseover", function (event, d) {
        // Show tooltip
        const containerBounds = chartRef.current.getBoundingClientRect();
        const tooltipX = event.clientX - containerBounds.left + 10;
        const tooltipY = event.clientY - containerBounds.top - 28;

        tooltip
          .style("opacity", 1)
          .html(
            `
            <div style="background: #ffffff; border: transparent; border-radius: 8px; padding: 12px; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); font-family: Arial, sans-serif;">
              <strong style="font-size: 16px; color: #333;">${d.name}</strong>
              <hr style="border: none; border-top: 1px solid #ddd; margin: 8px 0;" />
              <div style="font-size: 14px; color: #555;">
                <p>
                  <strong>Revenue Growth:</strong> ${d.revenueGrowth}%
                  <br>
                  <span style="font-size: 12px; color: #999;">
                    (${d.revenue4QuartersBack}B to ${d.revenueLatest}B)
                  </span>
                </p>
                <p>
                  <strong>Profit Growth:</strong> ${d.profitGrowth}%
                  <br>
                  <span style="font-size: 12px; color: #999;">
                    (${d.profit4QuartersBack}B to ${d.profitLatest}B)
                  </span>
                </p>
                <p>
                  <strong>Market Cap:</strong> $${d.marketCap}B
                </p>
              </div>
            </div>
            `
          )

          .style("left", `${tooltipX}px`)
          .style("top", `${tooltipY}px`);
      })

      .on("mouseout", function (event, d) {
        tooltip.style("opacity", 0); // Hide tooltip
      });

    // points
    //   .append("circle")
    //   .attr("class", "outer-circle")
    //   .attr("fill-opacity", 0.7) // Adjust opacity here
    //   .attr("stroke", "#fff")
    //   .attr("stroke-width", 2)
    //   .attr("r", (d) => (d.symbol === highlightSymbol ? 40 : 26)) // Outer circle size
    // .attr(
    //   "fill",
    //   (d) =>
    //     d.symbol === highlightSymbol
    //       ? "#91caff" // Highlighted background
    //       : "#8c8c8c" // Pastel yellow for others
    // );

    // Assuming colors.primary is set to a green shade
    points
      .append("circle")
      .attr("class", "outer-circle")
      .attr("fill-opacity", (d) => (d.symbol === highlightSymbol ? 0.9 : 0.7)) // Adjust opacity based on highlight
      .attr("stroke", "#fff")
      .attr("stroke-width", 2)
      .attr("r", (d) => (d.symbol === highlightSymbol ? 40 : 26)) // Adjust size based on highlight
      .attr("fill", (d) =>
        d.symbol === highlightSymbol ? colors.highlight : colors.primary
      ); // Highlight color vs primary green color

    // points
    //   .append("image")
    //   .attr("x", (d) => (d.symbol === highlightSymbol ? -20 : -10))
    //   .attr("y", (d) => (d.symbol === highlightSymbol ? -20 : -10))
    //   .attr("width", (d) => (d.symbol === highlightSymbol ? 40 : 30))
    //   .attr("height", (d) => (d.symbol === highlightSymbol ? 40 : 30))
    //   .attr("href", (d) => d.image)
    //   .attr("clip-path", "circle(50%)");

    points
      .append("image")
      .attr(
        "href",
        (d) => `https://images.financialmodelingprep.com/symbol/${d.symbol}.png`
      )
      .attr("x", -15)
      .attr("y", -15)
      .attr("width", 30)
      .attr("height", 30)
      .attr("opacity", 0.8) // Increase logo opacity
      .attr("stroke", "#fff") // White border for contrast
      .attr("stroke-width", 1)
      .attr("clip-path", "circle(50%)");

    points
      .append("text")
      .attr("x", 0) // Centered below the image
      .attr("y", (d) => (d.symbol === highlightSymbol ? 50 : 40)) // Adjust based on highlight
      .attr("text-anchor", "middle") // Center the text
      .attr("font-size", "small") // Small font
      .attr("fill", "#555555") // Text color
      .style("font-family", "Arial, sans-serif") // Consistent font style
      .style("text-shadow", "1px 1px 2px rgba(0,0,0,0.5)") // Optional: add shadow for better legibility
      .text((d) => d.name.split(" ")[0]); // Display only the first word of the company name
  }, [data]);

  const content = (
    <div>
      <p>
        <strong>Revenue Growth:</strong> Percentage change in Revenues in latest
        Quarter vs corresponding Revenues last year, e.g Q1'24 vs Q1'23
      </p>
      <p>
        <strong>Profit Growth:</strong> Percentage change in Net Income in
        latest Quarter vs corresponding Revenues last year, e.g Q1'24 vs Q1'23
      </p>
      <p>
        <strong>Peers:</strong> Companies operating the same industry based on
        latest SEC filings
      </p>
    </div>
  );

  return (
    <ProCard
      style={{ width: "100%", borderColor: "transparent" }}
      title="Peer Comparison"
      extra={
        <Popover content={content} title="Definitions">
          <Button
            style={{ border: "transparent" }}
            icon={<InfoCircleOutlined style={{ color: "grey" }} />}
          />
        </Popover>
      }
    >
      <div
        ref={chartRef}
        style={{
          width: "100%",
          height: "35vw",
          padding: "2%",
          backgroundColor: "white", // applying gradient background
        }}
      />
    </ProCard>
  );
};

const CompanyInfoCard = ({ symbol }) => {
  const [companyInfo, setCompanyInfo] = useState({});
  const [loading, setLoading] = useState(true);

  // Fetch company data from API
  const fetchCompanyInfo = async () => {
    const apiUrl =
      "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";

    try {
      const response = await axios.post(apiUrl, {
        queryStringParameters: {
          endpoint: "profile", // Specify the endpoint you are querying
          symbols: symbol, // Pass the symbol dynamically
        },
      });

      if (response.status === 200) {
        // Parse the body field to extract data
        const parsedBody = JSON.parse(response.data.body);
        setCompanyInfo(parsedBody[0] || {}); // Set the first company info to the state
      } else {
        console.error("Error fetching data:", response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching company info:", error);
      setLoading(false);
    }
  };

  // UseEffect to fetch and print the data
  useEffect(() => {
    const getCompanyInfo = async () => {
      const data = await fetchCompanyInfo();
      console.log("Fetched Data:", data); // Print the returned data here if needed
    };

    getCompanyInfo();
  }, [symbol]);

  // UseEffect to fetch and print the data
  useEffect(() => {
    const getCompanyInfo = async () => {
      const data = await fetchCompanyInfo();
      console.log("Fetched Data Returned by fetchCompanyInfo:", data); // Print the returned data here
    };

    getCompanyInfo();
  }, [symbol]);

  console.log(companyInfo);

  const stats = [
    {
      label: "Market Cap",
      value: (companyInfo.mktCap / 1000000000)?.toFixed(1),
      prefix: "$",
      suffix: "Bn.",
    },
    { label: "Current Price", value: companyInfo.price, prefix: "$" },
    { label: "52W: High-Low ", value: `${companyInfo.range}`, prefix: "$" },
    {
      label: "Intrinsic Value(DCF)",
      value: companyInfo.dcf?.toFixed(1),
      prefix: "$",
    },
    { label: "Book Value", value: companyInfo.bookValue },
    { label: "Dividend Yield", value: `${companyInfo.lastDiv || 0}%` },
    { label: "ROE", value: "-" },
    { label: "Face Value", value: "-" },
    { label: "Price to Sales", value: "-" },
    { label: "ROCE Prev Yr", value: "-" },
    { label: "Promoter Holding", value: "-" },
    { label: "Sales Growth", value: "-" },
  ];

  return (
    <Card
      style={{
        // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        // padding: "1px",
        // borderRadius: "12px",
        backgroundColor: "#ffffff",
        border: "none",
      }}
    >
      {/* <Title level={4}>
        {loading
          ? "Loading..."
          : companyInfo.companyName + "(" + symbol + ")" || "Company Info"}
      </Title> */}
      <Title level={4}>
        {loading ? (
          "Loading..."
        ) : (
          <>
            {companyInfo.companyName || "Company Info"}
            <div style={{ color: "grey", fontSize: "small" }}>
              Ticker: {symbol}
            </div>
          </>
        )}
      </Title>

      {/* <Row gutter={[8, 8]}>
        {stats.map((stat, index) => (
          <Col span={8} key={index}>
            <Card
              bordered={false}
              style={{
                backgroundColor: "#f3f4f6",
                borderRadius: "8px",
                textAlign: "center",
                padding: "0px",
                height: "100%",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  color: "#6c757d",
                  marginBottom: "2px",
                  fontWeight: 500,
                }}
              >
                {stat.label}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#212529",
                }}
              >
                {stat.prefix || ""}
                {stat.value || "-"}
                {stat.suffix || ""}
              </div>
            </Card>
          </Col>
        ))}
      </Row> */}
    </Card>
  );
};

const DemoTinyLinePrice = ({ data }) => {
  // Transform the data structure to match the expected format
  const transformedData = data.map((entry) => ({
    x: new Date(entry.date).toLocaleString("en-US", {
      month: "short",
      year: "2-digit",
    }), // Format the date to "Month'Year"
    y: entry.price, // Use price for the y-axis
  }));

  const config = {
    data: transformedData,
    xField: "x",
    yField: "y",
    height: 60,
    autoFit: true,
    smooth: true,

    theme: {
      styleSheet: {
        brandColor: "#068C38", // Specify the color for the line chart
      },
    },
    lineStyle: {
      stroke: "#068C38",
      lineWidth: 4,
    },
  };

  return <Tiny.Line {...config} />;
};

const DemoTinyColumnRevenue = ({ data, color = "#957DAD", isMobile }) => {
  // Prepare the data with `x` and `y` fields
  // console.log(data);
  const formattedData = data?.map((entry) => ({
    x: entry.name?.substring(0, 4), // Extract year for `x`
    y: entry?.revenue, // Use revenue for `y`
  }));

  console.log(formattedData);
  const yearDictionary = data?.reduce((dict, entry, index) => {
    const year = entry?.name?.toLocaleString().substring(0, 4);

    const formattedDate = year;

    dict[index] = formattedDate;
    return dict;
  }, {});

  const config = {
    data: formattedData,
    xField: "x",
    yField: "y",
    height: 60,

    autoFit: true,
    style: {
      fill: color, // Apply custom color
      fillOpacity: 0.7,
    },
    tooltip: false,

    columnStyle: {
      stroke: "transparent",
    },
    theme: {
      styleSheet: {
        brandColor: color,
      },
    },
  };

  return (
    <div>
      <Tiny.Column {...config} />
    </div>
  );
};

const DemoTinyColumnProfit = ({ data }) => {
  // Transform the data structure to match the expected format
  const transformedData = data.map((entry) => ({
    x: entry.name.substring(0, 4), // Extract the year from the date
    y: entry.netIncome, // Use netIncome for the y-axis
  }));

  const config = {
    data: transformedData,
    xField: "x",
    yField: "y",
    height: 60,

    autoFit: true,

    columnStyle: {
      fill: "#068C38", // Default green color for positive values
      stroke: "#068C38",
    },
  };

  return <Tiny.Column {...config} />;
};

export const DemoTinyAreaPE = ({ data }) => {
  // Transform the data structure to match the expected format
  const transformedData = data.map((entry) => ({
    x: entry.name.substring(0, 4), // Extract year from the name field
    y: entry.priceEarningsRatio, // Use priceEarningsRatio for the y-axis
  }));

  const config = {
    data: transformedData,
    xField: "x",
    yField: "y",
    height: 60,

    autoFit: true,
    smooth: true,
    areaStyle: {
      fill: "l(270) 0:#068C38 1:#fff", // Gradient fill for the area
    },
    line: {
      style: {
        stroke: "#068C38",
        lineWidth: 2,
      },
    },
  };

  return <Tiny.Area {...config} />;
};

export function CuteCharts({ stock_id }) {
  const [incomeStatementData, setIncomeStatementData] = useState([]);
  const [ratiosData, setRatiosData] = useState([]);
  const [priceData, setPriceData] = useState([]);

  const [CAGRData, setCAGRData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  let query_stock = stock_id;

  // console.log(query_stock);

  const fetchIncomeStatementData = async (query_stock) => {
    try {
      const apiUrl =
        "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";

      const response = await axios.post(apiUrl, {
        queryStringParameters: {
          endpoint: "income-statement", // Specify the endpoint
          symbols: query_stock, // Pass the stock symbol dynamically
          params: "limit=10", // Additional query params (if needed)
        },
      });

      if (response.status === 200) {
        const data = JSON.parse(response.data.body);

        const incomeStatementData = data.map((record) => ({
          date: record.date,
          revenue: record.revenue,
          netIncome: record.netIncome,
        }));

        setIncomeStatementData(incomeStatementData.reverse());
        setDataFetched(true); // Mark data as fetched
      } else {
        console.error("Error fetching data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching income statement data:", error);
    }
  };

  const fetchRatiosData = async (query_stock) => {
    try {
      const apiUrl =
        "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";

      const response = await axios.post(apiUrl, {
        queryStringParameters: {
          endpoint: "ratios", // Specify the endpoint
          symbols: query_stock, // Pass the stock symbol dynamically
          params: "limit=10", // Additional query params (if needed)
        },
      });

      if (response.status === 200) {
        const data = JSON.parse(response.data.body);

        const ratiosData = data.map((record) => ({
          date: record.date,
          priceEarningsRatio: record.priceEarningsRatio,
        }));

        setRatiosData(ratiosData.reverse());
        setDataFetched(true); // Mark data as fetched
      } else {
        console.error("Error fetching data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching ratios data:", error);
    }
  };

  const fetchPriceData = async (query_stock) => {
    try {
      const apiUrl =
        "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";

      const response = await axios.post(apiUrl, {
        queryStringParameters: {
          endpoint: "historical-price-full", // Specify the endpoint
          symbols: query_stock, // Pass the stock symbol dynamically
          params: "serietype=line", // Additional query params (if needed)
        },
      });

      if (response.status === 200) {
        const data = JSON.parse(response.data.body);

        const today = new Date();
        const tenYearsAgo = new Date();
        tenYearsAgo.setFullYear(today.getFullYear() - 10);

        const priceData = data.historical
          .filter((record) => {
            const recordDate = new Date(record.date);
            return (
              recordDate >= tenYearsAgo &&
              recordDate <= today &&
              record.date.endsWith("-01")
            );
          })
          .map((record) => ({
            date: record.date,
            price: record.close,
          }));

        setPriceData(priceData.reverse());
        setDataFetched(true); // Mark data as fetched
      } else {
        console.error("Error fetching data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching price data:", error);
    }
  };

  useEffect(() => {
    if (!dataFetched) {
      fetchIncomeStatementData(query_stock);
      fetchRatiosData(query_stock);
      fetchPriceData(query_stock);
    }
  }, [dataFetched]);

  const info = incomeStatementData?.map((incomeStatement, index) => {
    const matchingRatio = ratiosData?.find(
      (ratio) => ratio.date === incomeStatement.date
    );

    return {
      key: index?.toString(),
      date: incomeStatement.date,
      revenue: incomeStatement.revenue,
      netIncome: incomeStatement.netIncome,
      priceEarningsRatio: matchingRatio
        ? matchingRatio.priceEarningsRatio
        : null,
    };
  });

  // console.log(info);

  const data = info?.map((item, index) => ({
    key: index?.toString(),
    name: item.date,
    // price: item.price,
    netIncome: item.netIncome,
    revenue: item.revenue,
    priceEarningsRatio: item.priceEarningsRatio,
  }));

  // Calculate CAGR for the given data
  function calculateCAGR(data, years) {
    const initialValue = data[0];
    const finalValue = data[data.length - 1];
    return ((finalValue / initialValue) ** (1 / years) - 1) * 100;
  }

  // console.log(incomeStatementData);
  // Calculate CAGR for revenue growth data
  const revenue_growth_data = [
    {
      metric: "10 Years",
      value: calculateCAGR(
        incomeStatementData.map((item) => item.revenue),
        10
      )
        ? calculateCAGR(
            incomeStatementData.map((item) => item.revenue),
            10
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "5 Years",
      value:
        calculateCAGR(
          incomeStatementData.slice(-6).map((item) => item.revenue),
          5
        ).toFixed(1) + "%",
    },
    {
      metric: "3 Years",
      value:
        calculateCAGR(
          incomeStatementData.slice(-4).map((item) => item.revenue),
          3
        ).toFixed(1) + "%",
    },
    {
      metric: "TTM",
      value:
        calculateCAGR(
          incomeStatementData.slice(-2).map((item) => item.revenue),
          1
        ).toFixed(1) + "%",
    },
  ];

  // Calculate CAGR for price/earnings ratio data
  const pe_ratio_growth_data = [
    {
      metric: "10 Years",
      value: calculateCAGR(
        ratiosData.map((item) => item.priceEarningsRatio),
        9
      )
        ? calculateCAGR(
            ratiosData.map((item) => item.priceEarningsRatio),
            9
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "5 Years",
      value: calculateCAGR(
        ratiosData.map((item) => item.priceEarningsRatio),
        5
      )
        ? calculateCAGR(
            ratiosData.map((item) => item.priceEarningsRatio),
            5
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "3 Years",
      value:
        calculateCAGR(
          ratiosData.slice(-4).map((item) => item.priceEarningsRatio),
          3
        ).toFixed(1) + "%",
    },
    {
      metric: "TTM",
      value:
        calculateCAGR(
          ratiosData.slice(-2).map((item) => item.priceEarningsRatio),
          1
        ).toFixed(1) + "%",
    },
  ];

  // Calculate CAGR for net income data
  const net_income_growth_data = [
    {
      metric: "10 Years",
      value: calculateCAGR(
        incomeStatementData.map((item) => item.netIncome),
        9
      )
        ? calculateCAGR(
            incomeStatementData.map((item) => item.netIncome),
            9
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "5 Years",
      value: calculateCAGR(
        incomeStatementData.map((item) => item.netIncome),
        5
      )
        ? calculateCAGR(
            incomeStatementData.map((item) => item.netIncome),
            5
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "3 Years",
      value:
        calculateCAGR(
          incomeStatementData.slice(-4).map((item) => item.netIncome),
          3
        ).toFixed(1) + "%",
    },
    {
      metric: "TTM",
      value:
        calculateCAGR(
          incomeStatementData.slice(-2).map((item) => item.netIncome),
          1
        ).toFixed(1) + "%",
    },
  ];

  // Calculate CAGR for stock growth data
  // console.log(priceData);
  const stock_growth_data = [
    {
      metric: "10 Years",
      value:
        calculateCAGR(
          priceData.map((item) => item.price),
          10
        ).toFixed(1) + "%",
    },
    {
      metric: "5 Years",
      value:
        calculateCAGR(
          priceData.slice(-6).map((item) => item.price),
          5
        ).toFixed(1) + "%",
    },
    {
      metric: "3 Years",
      value:
        calculateCAGR(
          priceData.slice(-4).map((item) => item.price),
          3
        ).toFixed(1) + "%",
    },
    {
      metric: "TTM",
      value:
        calculateCAGR(
          priceData.slice(-2).map((item) => item.price),
          1
        ).toFixed(1) + "%",
    },
  ];

  const listItemStyle = {
    marginTop: "-8%", // Adjust the top margin between the card title and the list items

    marginBottom: "-8%", // Adjust the margin between list items as per your preference
    border: "none", // Remove borders between rows
  };
  const valueStyle = {
    fontWeight: "bold", // Make the item.value bold
  };

  return (
    <div>
      <div>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={6}>
            <StatisticCard title="Compound Rev. Growth" tip="xyz">
              <DemoTinyColumnRevenue data={data} />
              <Card style={{ marginTop: "3%" }}>
                <List
                  dataSource={revenue_growth_data}
                  renderItem={(item) => (
                    <List.Item style={listItemStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: 1 }}>{item.metric}</span>
                        <span style={valueStyle}>{item.value}</span>
                      </div>
                    </List.Item>
                  )}
                  pagination={false}
                  bordered={false}
                />
              </Card>
            </StatisticCard>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatisticCard title="Compound Price Growth" tip="xyz">
              <DemoTinyLinePrice data={priceData} />
              <Card style={{ marginTop: "3%" }}>
                <List
                  dataSource={stock_growth_data}
                  renderItem={(item) => (
                    <List.Item style={listItemStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: 1 }}>{item.metric}</span>
                        <span style={valueStyle}>{item.value}</span>
                      </div>
                    </List.Item>
                  )}
                  pagination={false}
                  bordered={false}
                />
              </Card>
            </StatisticCard>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatisticCard title="Compound Profit Growth" tip="xyz">
              <DemoTinyColumnProfit data={data} />
              <Card style={{ marginTop: "3%" }}>
                <List
                  dataSource={net_income_growth_data}
                  renderItem={(item) => (
                    <List.Item style={listItemStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: 1 }}>{item.metric}</span>
                        <span style={valueStyle}>{item.value}</span>
                      </div>
                    </List.Item>
                  )}
                  pagination={false}
                  bordered={false}
                />
              </Card>
            </StatisticCard>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatisticCard title="Compound PE Growth" tip="xyz">
              <DemoTinyAreaPE data={data} />
              <Card style={{ marginTop: "3%" }}>
                <List
                  dataSource={pe_ratio_growth_data}
                  renderItem={(item) => (
                    <List.Item style={listItemStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: 1 }}>{item.metric}</span>
                        <span style={valueStyle}>{item.value}</span>
                      </div>
                    </List.Item>
                  )}
                  pagination={false}
                  bordered={false}
                />
              </Card>
            </StatisticCard>
          </Col>
        </Row>
      </div>
      <div></div>
    </div>
  );
}

const IncomeStatementTable = ({ symbol }) => {
  const [info, setInfo] = useState([]);
  const [timePeriod, setTimePeriod] = useState("quarter");
  const [QtrTable, setQtrTable] = useState(true);
  const [AnnualTable, setAnnualTable] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    const fetchData = async (queryStock) => {
      try {
        const apiUrl =
          "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy";

        // Determine the parameters to pass to the Lambda function
        const params = QtrTable
          ? "period=quarter&limit=8"
          : "period=annual&limit=8";

        // Make the POST request to the API Gateway
        const response = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "income-statement", // Specify the endpoint
            symbols: queryStock, // Pass the symbol dynamically
            params, // Pass the additional parameters
          },
        });

        // Check if the response is successful
        if (response.status === 200) {
          const data = JSON.parse(response.data.body); // Parse the body of the response

          // Format the data
          const formattedData = data.map((record) => ({
            ...record,
            displayPeriod: QtrTable
              ? `${record.period}'${record.calendarYear?.toString().slice(-2)}` // For quarterly: "Q3'24"
              : record.calendarYear, // For annual: "2024"
          }));

          setInfo(formattedData); // Store the formatted data
        } else {
          console.error("Error fetching data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching income statement data:", error);
      }
    };

    fetchData(symbol); // Call the function with the stock symbol
  }, [QtrTable, AnnualTable]); // Re-run when QtrTable or AnnualTable changes

  const handleExpand = (key) => {
    if (expandedRowKeys.includes(key)) {
      setExpandedRowKeys(expandedRowKeys.filter((k) => k !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  const generateColumns = () => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for dynamic headers

    return [
      {
        title: "Attribute",
        dataIndex: "name",
        key: "name",
        width: 300,
      },
      ...periods.map((period) => ({
        title: period, // Dynamically set the column title
        dataIndex: period,
        key: period,
        align: "right",
        render: (value) => (value ? `${(value / 1e9).toFixed(2)}` : ""), // Format as billions with "B"
      })),
      {
        title: "Trend",
        key: "sparkline",
        render: (_, record) => {
          const sparklineData = periods.map((period) =>
            parseFloat(record[period] || 0)
          );
          return (
            <Sparklines data={sparklineData.reverse()}>
              <SparklinesLine
                style={{ stroke: "blue", strokeWidth: 2, fill: "none" }}
              />
            </Sparklines>
          );
        },
      },
    ];
  };

  const generateRows = (data) => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for row mapping

    const mapMetrics = (item) => {
      const row = {
        key: item.name,
        name: clean_columns_all[item.name] || item.name, // Use mapping for display names
        bold: ["grossProfit", "operatingIncome", "netIncome"].includes(
          item.name
        ), // Highlight specific rows
      };

      periods.forEach((period) => {
        const matchingRecord = info.find(
          (record) => record.displayPeriod === period
        );
        row[period] = matchingRecord ? matchingRecord[item.name] : null;
      });

      return row;
    };

    return data.map((item) => ({
      ...mapMetrics(item),
      children: item.children ? generateRows(item.children) : null,
    }));
  };

  const columns = generateColumns();
  const treeData = [
    { name: "revenue" },
    { name: "costOfRevenue" },
    { name: "grossProfit" },
    {
      name: "operatingExpenses",
      children: [
        { name: "sellingGeneralAndAdministrativeExpenses" },
        { name: "ResearchAndDevelopmentExpenses" },
        { name: "otherExpenses" },
      ],
    },
    { name: "operatingIncome" },
    { name: "interestExpense" },
    { name: "incomeTaxExpense" },
    { name: "netIncome" },
    { name: "eps" },
  ];
  const rowData = generateRows(treeData);

  const [activeButton, setActiveButton] = useState("quarter"); // Default to 'quarterly'

  const handleButtonClick = (type) => {
    setActiveButton(type);
    if (type === "annual") {
      AnnualStatement();
    } else {
      QtrStatement();
    }
  };

  const AnnualStatement = () => {
    setAnnualTable(true);
    setQtrTable(false);
  };

  const QtrStatement = () => {
    setAnnualTable(false);
    setQtrTable(true);
  };

  return (
    <ProCard
      className="CompanyInfo_Card-shadow"
      style={{ width: "100%", borderColor: "transparent" }}
      title="Income Statement"
      extra={
        <Button.Group>
          <div style={{ display: "flex", gap: "1px" }}>
            <Button
              className={`Ratios-button ${
                activeButton === "annual" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("annual")}
            >
              Annual
            </Button>
            <Button
              className={`Ratios-button ${
                activeButton === "quarter" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("quarter")}
            >
              Quarter
            </Button>
          </div>
        </Button.Group>
      }
    >
      <div className="sample-grid-container">
        <Table
          columns={columns}
          dataSource={rowData}
          pagination={false}
          expandable={{
            rowExpandable: (record) => record.children?.length > 0,
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded, record) => handleExpand(record.key),
          }}
          rowClassName={(record) => (record.bold ? "bold-row" : "normal-row")} // Highlight rows conditionally
          bordered={false}
        />
      </div>
    </ProCard>
  );
};

const CashFlowTable = ({ symbol }) => {
  const [info, setInfo] = useState([]);
  const [timePeriod, setTimePeriod] = useState("quarter");
  const [QtrTable, setQtrTable] = useState(true);
  const [AnnualTable, setAnnualTable] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [activeButton, setActiveButton] = useState("quarter"); // Default to 'quarterly'
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl =
          "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy"; // Replace with your API Gateway URL

        // Make a POST request to your API Gateway
        const response = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "cash-flow-statement", // Specify the endpoint for cash flow statement
            symbols: symbol, // Pass the stock symbol dynamically
            params: `period=${QtrTable ? "quarter" : "annual"}&limit=8`, // Set period and limit dynamically
          },
        });

        if (response.status === 200) {
          // Parse the response body
          const data = JSON.parse(response.data.body);

          const formattedData = data.map((record) => ({
            ...record,
            displayPeriod: QtrTable
              ? `${record.period}'${record.calendarYear?.toString().slice(-2)}` // For quarterly: "Q3'24"
              : record.calendarYear?.toString(), // For annual: "2024"
          }));

          setInfo(formattedData); // Store formatted data in state
        } else {
          console.error("Error fetching data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching cash flow data:", error);
      }
    };

    fetchData(); // Trigger the fetch
  }, [QtrTable, AnnualTable, symbol]);

  const handleButtonClick = (type) => {
    setActiveButton(type);
    if (type === "annual") {
      setAnnualTable(true);
      setQtrTable(false);
    } else {
      setAnnualTable(false);
      setQtrTable(true);
    }
  };

  const generateColumns = () => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for dynamic headers

    return [
      {
        title: "Attribute",
        dataIndex: "name",
        key: "name",
        width: 300,
      },
      ...periods.map((period) => ({
        title: period,
        dataIndex: period,
        key: period,
        align: "right",
        render: (value) => (value ? `${(value / 1e9).toFixed(2)}` : ""), // Format as billions with "B"
      })),
    ];
  };

  const generateRows = (data) => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for row mapping

    const mapMetrics = (item) => {
      const row = {
        key: item.name,
        name: clean_columns_all[item.name] || item.name, // Use mapping for display names
        bold: ["netChangeInCash", "freeCashFlow"].includes(item.name), // Highlight specific rows
      };

      periods.forEach((period) => {
        const matchingRecord = info.find(
          (record) => record.displayPeriod === period
        );
        row[period] = matchingRecord ? matchingRecord[item.name] : null;
      });

      return row;
    };

    return data.map((item) => ({
      ...mapMetrics(item),
      children: item.children ? generateRows(item.children) : null,
    }));
  };

  const columns = generateColumns();
  const rowData = generateRows([
    {
      name: "operatingCashFlow",
      children: [
        { name: "changeInWorkingCapital" },
        { name: "netIncome" },
        { name: "depreciationAndAmortization" },
        { name: "deferredIncomeTax" },
        { name: "otherNonCashItems" },
      ],
    },
    {
      name: "netCashUsedForInvestingActivites",
      children: [
        { name: "investmentsInPropertyPlantAndEquipment" },
        { name: "acquisitionsNet" },
        { name: "purchasesOfInvestments" },
        { name: "salesMaturitiesOfInvestments" },
      ],
    },
    { name: "netCashUsedProvidedByFinancingActivities" },
    { name: "netChangeInCash" },
    { name: "freeCashFlow" },
  ]);

  return (
    <ProCard
      className="CompanyInfo_Card-shadow"
      style={{ width: "100%", borderColor: "transparent" }}
      title="Cash Flow Statement"
      extra={
        <Button.Group>
          <div style={{ display: "flex", gap: "1px" }}>
            <Button
              className={`Ratios-button ${
                activeButton === "annual" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("annual")}
            >
              Annual
            </Button>
            <Button
              className={`Ratios-button ${
                activeButton === "quarter" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("quarter")}
            >
              Quarter
            </Button>
          </div>
        </Button.Group>
      }
    >
      <Table
        columns={columns}
        dataSource={rowData}
        pagination={false}
        expandable={{
          rowExpandable: (record) => record.children?.length > 0,
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedRowKeys([...expandedRowKeys, record.key]);
            } else {
              setExpandedRowKeys(
                expandedRowKeys.filter((key) => key !== record.key)
              );
            }
          },
        }}
        rowClassName={(record) => (record.bold ? "bold-row" : "normal-row")} // Highlight rows conditionally
        bordered={false}
      />
    </ProCard>
  );
};

const BalanceSheetTable = ({ symbol }) => {
  const [info, setInfo] = useState([]);
  const [timePeriod, setTimePeriod] = useState("quarter");
  const [QtrTable, setQtrTable] = useState(true);
  const [AnnualTable, setAnnualTable] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [activeButton, setActiveButton] = useState("quarter"); // Default to 'quarterly'

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl =
        "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy"; // Replace with your API Gateway URL

      try {
        const response = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "balance-sheet-statement", // Lambda endpoint for balance sheet data
            symbols: symbol, // Pass the stock symbol dynamically
            params: `period=${QtrTable ? "quarter" : "annual"}&limit=8`, // Add query parameters for period and limit
          },
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data.body); // Parse the response body
          const formattedData = data.map((record) => ({
            ...record,
            displayPeriod: QtrTable
              ? `${record.period}'${record.calendarYear?.toString().slice(-2)}`
              : record.calendarYear?.toString(),
          }));

          setInfo(formattedData); // Store formatted data in state
        } else {
          console.error("Error fetching balance sheet data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching balance sheet data:", error);
      }
    };

    fetchData();
  }, [QtrTable, AnnualTable]); // Dependencies for re-fetching data

  const handleButtonClick = (type) => {
    setActiveButton(type);
    if (type === "annual") {
      setAnnualTable(true);
      setQtrTable(false);
    } else {
      setAnnualTable(false);
      setQtrTable(true);
    }
  };

  const generateColumns = () => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for dynamic headers

    return [
      {
        title: "Attribute",
        dataIndex: "name",
        key: "name",
        width: 300,
      },
      ...periods.map((period) => ({
        title: period,
        dataIndex: period,
        key: period,
        align: "right",
        render: (value) => (value ? `${(value / 1e9).toFixed(2)}` : ""), // Format as billions with "B"
      })),
    ];
  };

  const generateRows = (data) => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for row mapping

    const mapMetrics = (item) => {
      const row = {
        key: item.name,
        name: clean_columns_all[item.name] || item.name, // Use mapping for display names
        bold: [
          "totalAssets",
          "totalLiabilities",
          "totalStockholdersEquity",
        ].includes(item.name), // Highlight specific rows
      };

      periods.forEach((period) => {
        const matchingRecord = info.find(
          (record) => record.displayPeriod === period
        );
        row[period] = matchingRecord ? matchingRecord[item.name] : null;
      });

      return row;
    };

    return data.map((item) => ({
      ...mapMetrics(item),
      children: item.children ? generateRows(item.children) : null,
    }));
  };

  const columns = generateColumns();
  const rowData = generateRows([
    {
      name: "totalAssets",
      children: [
        {
          name: "totalCurrentAssets",
          children: [
            {
              name: "cashAndShortTermInvestments",
              children: [
                { name: "cashAndCashEquivalents" },
                { name: "shortTermInvestments" },
              ],
            },
            { name: "netReceivables" },
            { name: "inventory" },
          ],
        },
        { name: "propertyPlantEquipmentNet" },
        { name: "goodwill" },
        { name: "intangibleAssets" },
        { name: "otherAssets" },
      ],
    },
    {
      name: "totalLiabilities",
      children: [
        {
          name: "totalCurrentLiabilities",
          children: [
            { name: "accountPayables" },
            { name: "otherCurrentLiabilities" },
            { name: "longTermDebt" },
          ],
        },
      ],
    },
    {
      name: "totalStockholdersEquity",
      children: [{ name: "commonStock" }, { name: "retainedEarnings" }],
    },
  ]);

  return (
    <ProCard
      className="CompanyInfo_Card-shadow"
      style={{ width: "100%", borderColor: "transparent" }}
      title="Balance Sheet"
      extra={
        <Button.Group>
          <div style={{ display: "flex", gap: "1px" }}>
            <Button
              className={`Ratios-button ${
                activeButton === "annual" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("annual")}
            >
              Annual
            </Button>
            <Button
              className={`Ratios-button ${
                activeButton === "quarter" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("quarter")}
            >
              Quarter
            </Button>
          </div>
        </Button.Group>
      }
    >
      <Table
        columns={columns}
        dataSource={rowData}
        pagination={false}
        expandable={{
          rowExpandable: (record) => record.children?.length > 0,
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedRowKeys([...expandedRowKeys, record.key]);
            } else {
              setExpandedRowKeys(
                expandedRowKeys.filter((key) => key !== record.key)
              );
            }
          },
        }}
        rowClassName={(record) => (record.bold ? "bold-row" : "normal-row")} // Highlight rows conditionally
        bordered={false}
      />
    </ProCard>
  );
};

const StockPriceChart = ({ symbol }) => {
  const [chartData, setChartData] = useState([]);
  const [latestDate, setLatestDate] = useState("");
  const [latestPrice, setLatestPrice] = useState(0);
  const [priceTargets, setPriceTargets] = useState({});

  const fetchData = async () => {
    const apiUrl =
      "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy"; // Replace with your API Gateway URL

    try {
      // Fetch historical price data
      const historicalResponse = await axios.post(apiUrl, {
        queryStringParameters: {
          endpoint: "historical-price-full",
          symbols: symbol,
          params: "serietype=line", // Additional query parameter
        },
      });

      const historicalData = JSON.parse(historicalResponse.data.body)
        .historical.slice(0, 365)
        .reverse()
        .map((item) => ({
          date: item.date,
          currentPrice: item.close,
        }));

      const latestPrice =
        historicalData[historicalData.length - 1]?.currentPrice || 0;
      const latestDataDate =
        historicalData[historicalData.length - 1]?.date || "";

      setLatestDate(latestDataDate);
      setLatestPrice(latestPrice);
      setChartData(historicalData);

      // Fetch price target data
      const targetResponse = await axios.post(apiUrl, {
        queryStringParameters: {
          endpoint: "price-target-consensus",
          symbols: symbol,
        },
      });

      const priceTargets = JSON.parse(targetResponse.data.body)[0] || {};
      setPriceTargets(priceTargets);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const maxYValue = Math.max(
    ...chartData.map((d) => d.currentPrice),
    priceTargets.targetHigh || 0
  );

  // Custom Tooltip Content
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.95)", // slightly transparent white
            padding: "5px 5px", // reduced padding for compactness
            border: "1px solid #ddd", // light gray border
            borderRadius: "8px", // rounded corners
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.4)", // subtle shadow for depth
            fontSize: "12px", // smaller text for a more compact feel
            color: "#666", // dark gray text color for better readability
          }}
        >
          <p style={{ color: "grey", textAlign: "left" }}>{`Date: ${label}`}</p>
          <p style={{ color: "#3f8600" }}>{`${
            payload[0].name
          }: $${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ProCard
      title="Price Chart with Analyst Estimates"
      style={{
        marginTop: "5%",
      }}
    >
      {/* <h2 style={{ marginBottom: "16px", textAlign: "center" }}>
          Price Chart with Analyst Estimates
        </h2> */}
      <div style={{ margin: "5%" }}>
        {chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartData}
              margin={{ top: 20, right: 40, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(date) => date.split("-").slice(1).join("/")}
                angle={-15}
                textAnchor="end"
                interval="preserveEnd"
              />
              <YAxis
                domain={[0, maxYValue * 1.04]} // Scale to 1.2x max price
                label={{
                  value: "Price",
                  angle: -90,
                  position: "insideLeft",
                }}
                tickFormatter={(price) => `$${price.toFixed(2)}`}
                width={100} // Increase width if labels are longer
              />
              {/* <Tooltip
                formatter={(value, name) => [`$${value.toFixed(2)}`, name]}
                labelFormatter={(label) => `Date: ${label}`}
                position={{ y: 50 }} // Adjust position to avoid blocking data points
              /> */}
              <Tooltip
                content={<CustomTooltip />}
                position={{ y: 50 }}
                cursor={{ strokeDasharray: "3 3" }}
              />

              <Legend
                verticalAlign="top"
                align="right"
                layout="vertical"
                iconSize={10}
                wrapperStyle={{
                  paddingLeft: "20px",
                  fontSize: "small",
                  fontWeight: "bold",
                  top: -50, // Position at top to avoid overlap with chart
                  right: 0,
                  border: "1px solid #d9d9d9", // Optional styling
                  borderRadius: "5px",
                  padding: "5px",
                  backgroundColor: "#f9f9f9", // Light background for clarity
                }}
              />

              <Line
                type="monotone"
                dataKey="currentPrice"
                name="Current Price"
                stroke="#8884d8"
                dot={false}
                strokeWidth={2}
              />

              {/* Add Markers for Analyst Targets */}
              <ReferenceDot
                x={latestDate}
                y={priceTargets.targetHigh}
                r={8}
                fill="green"
                label={{
                  value: `★ Max Target:$${priceTargets.targetHigh}`,
                  position: "right",
                  fill: "green",
                  fontSize: 12,
                  offset: 10, // Offset label from the dot
                  style: { fontSize: 12, fill: "#333" }, // Smaller font size
                }}
              />
              <ReferenceDot
                x={latestDate}
                y={priceTargets.targetMedian}
                r={8}
                fill="green"
                label={{
                  value: `★ Median Target:$${priceTargets.targetMedian}`,
                  position: "right",
                  fill: "#82ca9d",
                  offset: 10, // Adjust offset to move the label away from the edge
                  style: { fontSize: 12, fill: "#333" }, // Ensure text is legible against any background
                }}
              />
              <ReferenceDot
                x={latestDate}
                y={priceTargets.targetLow}
                r={8}
                fill="green"
                label={{
                  value: `★ Min Target:$${priceTargets.targetLow}`,
                  position: "right",
                  fill: "#green",
                  fontSize: 12,
                  offset: 10, // Adjust offset to move the label away from the edge
                  style: { fontSize: 12, fill: "#333" },
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <p>Loading chart...</p>
        )}
      </div>
    </ProCard>
  );
};

const ratioBuckets = {
  valuation: [
    { name: "Price to Earnings Ratio", field: "priceEarningsRatio" },
    { name: "Price to Sales Ratio", field: "priceToSalesRatio" },
    { name: "Price to Cash Flow Ratio", field: "priceCashFlowRatio" },
    { name: "Price to Book Ratio", field: "priceToBookRatio" },
    { name: "Enterprise Value", field: "enterpriseValueMultiple" },
  ],
  profitability: [
    { name: "Return on Assets (%)", field: "returnOnAssets" },
    { name: "Return on Equity (%)", field: "returnOnEquity" },
    { name: "Gross Profit Margin (%)", field: "grossProfitMargin" },
    { name: "Operating Profit Margin (%)", field: "operatingProfitMargin" },
    { name: "Net Profit Margin (%)", field: "netProfitMargin" },
  ],
  liquidity: [
    { name: "Quick Ratio", field: "quickRatio" },
    { name: "Current Ratio", field: "currentRatio" },
    { name: "Inventory Turnover", field: "inventoryTurnover" },
    { name: "Asset Turnover", field: "assetTurnover" },
  ],
  solvency: [
    { name: "Debt to Equity Ratio", field: "debtEquityRatio" },
    {
      name: "Long-Term Debt to Capitalization",
      field: "longTermDebtToCapitalization",
    },
  ],
};

const Ratios_Card = ({ symbol }) => {
  const [data, setData] = useState([]);
  const [timePeriod, setTimePeriod] = useState("quarter");
  const [activeBucket, setActiveBucket] = useState("valuation");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl =
          "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy"; // Replace with your API Gateway URL

        // Make a POST request to your API Gateway
        const response = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "ratios", // Specify the endpoint for ratios
            symbols: symbol, // Pass the stock symbol dynamically
            params: `period=${
              timePeriod === "annual" ? "annual" : "quarter"
            }&limit=8`, // Set period and limit dynamically
          },
        });

        if (response.status === 200) {
          // Parse the response body
          const data = JSON.parse(response.data.body);

          // Format the response data
          const formattedData = data.map((record) => ({
            ...record,
            displayPeriod:
              timePeriod === "quarter"
                ? `${record.period}'${record.date.split("-")[0].slice(-2)}` // For quarterly: "Q3'24"
                : record.date.split("-")[0], // For annual: "2024"
          }));

          setData(formattedData); // Store formatted data in state
        } else {
          console.error("Error fetching data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching ratios data:", error);
      }
    };

    fetchData(); // Trigger the fetch
  }, [timePeriod, symbol]);

  const handleButtonClick = (type) => {
    setTimePeriod(type);
  };

  const generateColumns = () => {
    const periods = data.map((row) => row.displayPeriod);
    return [
      {
        title: "Metric",
        dataIndex: "metric",
        key: "metric",
        width: 300,
      },
      ...periods.map((period) => ({
        title: period,
        dataIndex: period,
        key: period,
        align: "right",
        render: (value) => (value ? `${parseFloat(value).toFixed(2)}` : "-"),
      })),
    ];
  };

  const generateRows = () => {
    const periods = data.map((row) => row.displayPeriod);
    const metrics = ratioBuckets[activeBucket];

    return metrics.map((metric) => {
      const row = { metric: metric.name, key: metric.field };

      periods.forEach((period, index) => {
        const record = data[index];
        row[period] = record ? record[metric.field] : null;
      });

      return row;
    });
  };

  const columns = generateColumns();
  const rows = generateRows();

  return (
    <ProCard
      className="Ratios_Card-shadow"
      style={{ width: "100%", borderColor: "transparent" }}
      title="Ratios"
      extra={
        <Button.Group>
          <Button
            className={`Ratios-button ${
              timePeriod === "annual" ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick("annual")}
          >
            Annual
          </Button>
          <Button
            className={`Ratios-button ${
              timePeriod === "quarter" ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick("quarter")}
          >
            Quarterly
          </Button>
        </Button.Group>
      }
    >
      <div style={{ marginBottom: "1%", display: "flex", gap: "6px" }}>
        {Object.keys(ratioBuckets).map((bucket) => (
          <Button
            key={bucket}
            type={activeBucket === bucket ? "primary" : "default"}
            size="small"
            onClick={() => setActiveBucket(bucket)}
          >
            {bucket.charAt(0).toUpperCase() + bucket.slice(1)}
          </Button>
        ))}
      </div>
      <Table
        columns={columns}
        dataSource={rows}
        pagination={false}
        expandable={{
          expandedRowKeys,
          onExpand: (expanded, record) => {
            setExpandedRowKeys(expanded ? [record.key] : []);
          },
        }}
        rowClassName={(record) =>
          ["enterpriseValueMultiple"].includes(record.metric) ? "bold-row" : ""
        }
        bordered={false}
      />
    </ProCard>
  );
};

const useEarningTranscripts = (stockId) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const transcripts = [];

        for (let i = 0; i < 8; i++) {
          const quarter =
            (currentYear * 4 + Math.floor(new Date().getMonth() / 3) - i) % 4 ||
            4;
          const year = Math.floor(
            (currentYear * 4 + Math.floor(new Date().getMonth() / 3) - i) / 4
          );
          transcripts.push({ quarter, year });
        }

        setData(transcripts);
      } catch (error) {
        console.error("Error fetching earning call metadata:", error);
      }
    };

    fetchData();
  }, [stockId]);

  return data;
};

const useFilingsData = (stockId, type) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl =
          "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy"; // Replace with your API Gateway URL

        // Make a POST request to your API Gateway
        const response = await axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "sec_filings", // Specify the endpoint for SEC filings
            symbols: stockId, // Pass the stock symbol dynamically
            params: `page=0`, // Set any additional query params dynamically
          },
        });

        if (response.status === 200) {
          // Parse the response body
          const filings = JSON.parse(response.data.body);

          // Filter and limit the data
          const filteredData = filings
            .filter((item) => item.type === type)
            .slice(0, 8); // Limit to the latest 8 filings

          setData(filteredData); // Update state with filtered data
        } else {
          console.error("Error fetching data:", response.data);
        }
      } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
      }
    };

    fetchData(); // Trigger the fetch
  }, [stockId, type]);

  return data;
};

const Documents = ({ symbol }) => {
  const stockId = symbol;
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const earningCalls = useEarningTranscripts(stockId);
  const k10Filings = useFilingsData(stockId, "10-K");
  const q10Filings = useFilingsData(stockId, "10-Q");
  const announcements = useFilingsData(stockId, "8-K");

  const toggleExpanded = () => setExpanded((prev) => !prev);

  const renderDocumentList = (
    documents,
    dateFormatter,
    isTranscript = false
  ) => {
    const visibleDocuments = expanded ? documents : documents.slice(0, 4);

    return visibleDocuments?.map((item, index) => (
      <List.Item key={index}>
        <div style={{ fontSize: "small", color: "gray" }}>
          {dateFormatter(item)}
        </div>
        {isTranscript ? (
          <Button
            onClick={() =>
              navigate(
                `/transcript?stockId=${stockId}&quarter=${item.quarter}&year=${item.year}`
              )
            }
            style={{ marginLeft: "10px", border: "none" }}
          >
            <EyeOutlined
              style={{
                fontSize: "large",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  `/transcript?stockId=${item.symbol}&quarter=${item.quarter}&year=${item.year}`
                )
              }
            />
          </Button>
        ) : (
          <Button
            href={item.finalLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "10px", border: "none" }}
          >
            <FilePdfOutlined
              style={{ fontSize: "large", color: "blueviolet" }}
            />
          </Button>
        )}
      </List.Item>
    ));
  };

  return (
    <div style={{ margin: "2px" }}>
      <Card>
        <ProCard title="SEC Filings" style={{ borderColor: "transparent" }}>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12} md={6}>
              <ProCard bordered title="Earning Call Transcripts">
                <List>
                  {renderDocumentList(
                    earningCalls,
                    (item) => `Q${item.quarter} ${item.year}`,
                    true // Flag to identify earning transcripts
                  )}
                </List>
              </ProCard>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <ProCard bordered title="10-K (Annual Filings)">
                <List>
                  {renderDocumentList(k10Filings, (item) =>
                    moment(item.fillingDate).format("MMM YYYY")
                  )}
                </List>
              </ProCard>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <ProCard bordered title="10-Q (Quarterly Filings)">
                <List>
                  {renderDocumentList(q10Filings, (item) =>
                    moment(item.fillingDate).format("MMM YYYY")
                  )}
                </List>
              </ProCard>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <ProCard bordered title="Others(8-K,SC-13G/A, 4)">
                <List>
                  {renderDocumentList(
                    announcements,
                    (item) =>
                      `${item.type} - ${moment(item.fillingDate).format(
                        "MMM D, YYYY"
                      )}`
                  )}
                </List>
              </ProCard>
            </Col>
          </Row>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Button
              style={{
                backgroundColor: "#E8F5E9",
                border: "none",
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
              onClick={toggleExpanded}
              icon={expanded ? <UpOutlined /> : <DownOutlined />}
            >
              {expanded ? "Show Less" : "Show More"}
            </Button>
          </div>
        </ProCard>
      </Card>
    </div>
  );
};

const Estimates_Card = ({ symbol }) => {
  const [info, setInfo] = useState([]);
  const [actuals, setActuals] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [activeButton, setActiveButton] = useState("Revenue");
  const [isQuarterly, setIsQuarterly] = useState(true);

  useEffect(() => {
    fetchData();
  }, [isQuarterly, activeButton]);

  const fetchData = async () => {
    const apiUrl =
      "https://e6lie7myp2.execute-api.us-east-1.amazonaws.com/test_deploy"; // Replace with your API Gateway URL
    const period = isQuarterly ? "quarter" : "annual";

    try {
      const [estimatesResponse, actualsResponse] = await Promise.all([
        axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "analyst-estimates", // Endpoint for analyst estimates
            symbols: symbol,
            params: `period=${period}&limit=30`, // Additional query params
          },
        }),
        axios.post(apiUrl, {
          queryStringParameters: {
            endpoint: "income-statement", // Endpoint for income statements
            symbols: symbol,
            params: `period=${period}&limit=12`, // Additional query params
          },
        }),
      ]);

      if (estimatesResponse.status === 200 && actualsResponse.status === 200) {
        // Parse response bodies
        const estimatesData = JSON.parse(estimatesResponse.data.body);
        const actualsData = JSON.parse(actualsResponse.data.body);

        // Process estimates data
        const today = new Date();
        const sortedEstimates = estimatesData.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        const closestIndex = sortedEstimates.findIndex(
          (item) => new Date(item.date) >= today
        );

        const filteredEstimates = sortedEstimates.slice(
          Math.max(0, closestIndex - 8),
          Math.min(sortedEstimates.length, closestIndex + 4)
        );

        setInfo(filteredEstimates); // Update state with filtered estimates
        setActuals(actualsData); // Update state with actuals data
      } else {
        console.error("Error fetching data from API Gateway");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (info.length && actuals.length) {
      const formattedData = info?.map((estimate) => {
        const estimateDate = new Date(estimate.date);

        const matchedActual = actuals.find((item) => {
          const actualDate = new Date(item.date);
          const differenceInDays = Math.abs(
            (actualDate - estimateDate) / (1000 * 60 * 60 * 24)
          );
          return differenceInDays <= 3; // Match if within ±3 days
        });

        const period = isQuarterly
          ? `Q${Math.ceil(
              Number(estimate.date.substring(5, 7)) / 3
            )}'${estimate.date.substring(2, 4)}`
          : estimate.date.substring(0, 4);

        const highLowActual = {
          Revenue: {
            Avg: estimate.estimatedRevenueAvg / 1e9,
            actualValue: matchedActual?.revenue / 1e9 || null,
          },
          Earnings: {
            Avg: estimate.estimatedNetIncomeAvg / 1e9,
            actualValue: matchedActual?.netIncome / 1e9 || null,
          },
          EPS: {
            Avg: estimate.estimatedEpsAvg,
            actualValue: matchedActual?.eps || null,
          },
        };

        const { Avg, actualValue } = highLowActual[activeButton];

        return {
          period,
          Avg: Avg ? Avg.toFixed(1) : null,
          Actual: actualValue ? actualValue.toFixed(1) : null,
        };
      });

      // Sort in ascending order of period
      setChartData(
        formattedData?.sort((a, b) => {
          const dateA = new Date(
            a.period.replace("Q", "-01-").replace("'", "-")
          );
          const dateB = new Date(
            b.period.replace("Q", "-01-").replace("'", "-")
          );
          return dateA - dateB;
        })
      );
    }
  }, [info, actuals, activeButton, isQuarterly]);

  // Dynamically calculate the chart height
  const chartHeight = 300;

  const maxYValue = Math.max(
    ...chartData?.map((item) => Math.max(item.Avg || 0, item.Actual || 0))
  );

  const minYValue = Math.min(
    ...chartData?.map((item) => Math.min(item.Avg || 0, item.Actual || 0))
  );

  return (
    <ProCard
      title="Estimates vs Actuals"
      extra={
        <Button.Group>
          <Button
            type={isQuarterly ? "primary" : "default"}
            onClick={() => setIsQuarterly(true)}
          >
            Quarterly
          </Button>
          <Button
            type={!isQuarterly ? "primary" : "default"}
            onClick={() => setIsQuarterly(false)}
          >
            Annual
          </Button>
        </Button.Group>
      }
      style={{
        marginBottom: "20px",
        padding: "20px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        {["Revenue", "Earnings", "EPS"].map((button) => (
          <Button
            key={button}
            type={activeButton === button ? "primary" : "default"}
            onClick={() => setActiveButton(button)}
            style={{
              marginRight: "8px",
              fontWeight: activeButton === button ? "bold" : "normal",
            }}
          >
            {button}
          </Button>
        ))}
      </div>
      {chartData.length > 0 && (
        <ResponsiveContainer width="95%" height={chartHeight}>
          <BarChart data={chartData}>
            <XAxis dataKey="period" />
            <YAxis domain={[maxYValue * 1.1, maxYValue * 1.1]} />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar
              dataKey="Avg"
              fill="#A9A9A9"
              name={`Forecasted ${activeButton}`}
            />
            <Bar
              dataKey="Actual"
              fill="#0096FF"
              name={`Actual ${activeButton}`}
            />
            {/* Highlight future dates */}
            <ReferenceArea
              x1={
                chartData.find((item) => !item.Actual)?.period ||
                chartData[0]?.period
              }
              x2={chartData[chartData.length - 1]?.period}
              strokeOpacity={0.6}
              fill="#f0f8ff" // Light blue pastel
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </ProCard>
  );
};

const CompanyPage = () => {
  const { symbol } = useParams();
  const [loading, setLoading] = useState(true); // Manage loading state

  useEffect(() => {
    // Simulate data fetching
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after data is fetched
    }, 400); // Adjust the duration as per your actual data loading time

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  if (loading) {
    // Display spinner while loading
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ marginTop: "2%" }}>
      <Helmet>
        <title>{`${symbol} Stock Analysis | Actionable Insights & Forecasts with AI`}</title>
        <meta
          name="description"
          content={`Get AI-driven stock analysis for ${symbol}. Check latest financial data, earnings, and expert predictions`}
        />
        <meta
          property="og:title"
          content={`${symbol} Stock Overview - Stockbuzz.ai`}
        />
        <meta
          property="og:description"
          content={`Get AI-driven stock analysis for ${symbol}. Check financial data, earnings, and key market insights.`}
        />
        <meta
          property="og:image"
          content={`https://images.financialmodelingprep.com/${symbol}.png`}
        />
        <meta
          property="og:url"
          content={`https://images.financialmodelingprep.com/${symbol}.png`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${symbol} Stock Performance`} />
        <meta
          name="twitter:description"
          content={`Comprehensive insights for ${symbol} powered by Stockbuzz.ai`}
        />
        <meta
          name="twitter:image"
          content={`https://images.financialmodelingprep.com/${symbol}.png`}
        />
        <link
          rel="canonical"
          href={`https://www.stockbuzz.ai/company/${symbol}`}
        />
      </Helmet>
      {/* <PeerComparison /> */}
      <CompanyInfoCard symbol={symbol} />
      <IncomeStatementTable symbol={symbol} />
      <CuteCharts stock_id={symbol} />
      <BalanceSheetTable symbol={symbol} />
      <CashFlowTable symbol={symbol} />
      <Ratios_Card symbol={symbol} />
      <StockPriceChart symbol={symbol} />
      <DemoScatter symbol={symbol} />
      <Estimates_Card symbol={symbol} />
      <Documents symbol={symbol} />
      <div>
        <Row gutter={24}>
          {/* Left Column: Company Overview */}
          <Col span={12}>{/* <CompanyInfoCard symbol={symbol} /> */}</Col>
          <Col span={12}>
            {/* <DemoScatter /> */}
            {/* <AnalystGaugeChart symbol={symbol} /> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanyPage;
