import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Input, Button, Card, Tag, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";

const { Title, Text, Paragraph } = Typography;
const { Search } = Input;

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagCounts, setTagCounts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          "https://public-api.wordpress.com/wp/v2/sites/19aditiyadav.wordpress.com/posts?_embed"
        );
        const data = await response.json();

        // Process blogs
        const uniqueBlogs = data.map((post) => ({
          id: post.id,
          title: post.title.rendered.replace(/&nbsp;/g, " "),
          slug: post.slug,
          content: post.content.rendered.replace(/&nbsp;/g, " "),
          excerpt:
            post.excerpt.rendered
              .replace(/<\/?[^>]+(>|$)/g, "")
              .substring(0, 200) + "...",
          date: new Date(post.date).toDateString(),
          tags:
            post.class_list
              ?.filter((cls) => cls.startsWith("tag-"))
              .map((tag) => tag.replace("tag-", "")) || [],
        }));

        setBlogs(uniqueBlogs);
        setFilteredBlogs(uniqueBlogs);

        // Count tag occurrences
        const tagCountMap = {};
        uniqueBlogs.forEach((blog) => {
          blog.tags.forEach((tag) => {
            tagCountMap[tag] = (tagCountMap[tag] || 0) + 1;
          });
        });
        setTagCounts(tagCountMap);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    let filtered = blogs;

    if (searchTerm) {
      filtered = filtered.filter(
        (blog) =>
          blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          blog.tags.some((tag) =>
            tag.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
    }

    if (selectedTags.length > 0) {
      filtered = filtered.filter((blog) =>
        selectedTags.every((tag) => blog.tags.includes(tag))
      );
    }

    setFilteredBlogs(filtered);
  }, [searchTerm, selectedTags, blogs]);

  const handleTagClick = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  const clearTags = () => {
    setSelectedTags([]);
  };

  return (
    <div
      style={{
        padding: "5% 10%",
        backgroundColor: "#f9f9f9",
        minHeight: "100vh",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Title level={2} style={{ color: "#333" }}>
          Master the Markets: Explore Our Expert Investment Blogs{" "}
        </Title>
        <Search
          placeholder="Search blogs by title or tags"
          allowClear
          enterButton="Search"
          size="large"
          style={{
            maxWidth: "600px",
            width: "100%",
            borderRadius: "20px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        <Title level={4}>Popular Tags</Title>
        {Object.entries(tagCounts)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 6)
          .map(([tag, count]) => (
            <Tag
              key={tag}
              color={selectedTags.includes(tag) ? "geekblue" : "default"}
              onClick={() => handleTagClick(tag)}
              style={{
                margin: "5px",
                fontSize: "16px",
                cursor: "pointer",
                borderRadius: "15px",
                padding: "8px 15px",
              }}
            >
              {tag} ({count})
            </Tag>
          ))}
        {selectedTags.length > 0 && (
          <Button
            onClick={clearTags}
            type="primary"
            danger
            style={{ marginLeft: "10px" }}
          >
            Clear Filters
          </Button>
        )}
      </div>

      {loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row justify="center" gutter={[0, 40]} style={{ marginTop: "20px" }}>
          <Col xs={24} md={18}>
            {filteredBlogs.length === 0 ? (
              <Title level={4} style={{ textAlign: "center", color: "#888" }}>
                No blogs found. Try a different search or tag.
              </Title>
            ) : (
              filteredBlogs.map((blog) => (
                <Card
                  key={blog.id}
                  hoverable
                  style={{
                    marginBottom: "20px",
                    borderRadius: "10px",
                    padding: "25px",
                    boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => navigate(`/blogs/${blog.slug}`)}
                >
                  <Title level={3}>
                    <span dangerouslySetInnerHTML={{ __html: blog.title }} />
                  </Title>
                  <Text
                    type="secondary"
                    style={{ fontSize: "14px", color: "#888" }}
                  >
                    {blog.date}
                  </Text>
                  <Paragraph
                    style={{
                      fontSize: "16px",
                      color: "#555",
                      lineHeight: "1.7",
                    }}
                    ellipsis={{ rows: 3, expandable: true }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: blog.excerpt }} />
                  </Paragraph>
                  <div style={{ marginTop: "10px" }}>
                    {blog.tags.map((tag) => (
                      <Tag
                        key={tag}
                        color="blue"
                        style={{ marginRight: "5px" }}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </div>
                </Card>
              ))
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BlogList;
